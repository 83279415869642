export const productDetailsActionTypes = {
  PRODUCT_DETAILS_FETCH_REQUEST: "PRODUCT_DETAILS_FETCH_REQUEST",
  PRODUCT_DETAILS_FETCH_SUCCESS: "PRODUCT_DETAILS_FETCH_SUCCESS",
  PRODUCT_DETAILS_FETCH_FAILURE: "PRODUCT_DETAILS_FETCH_FAILURE",
}

export const fetchProductDetailsRequest = payload => ({
  type: productDetailsActionTypes.PRODUCT_DETAILS_FETCH_REQUEST,
  payload,
})

export const fetchProductDetailsSuccess = payload => ({
  type: productDetailsActionTypes.PRODUCT_DETAILS_FETCH_SUCCESS,
  payload,
})

export const fetchProductDetailsFailure = payload => ({
  type: productDetailsActionTypes.PRODUCT_DETAILS_FETCH_FAILURE,
  payload,
})
