import {
  locationToPosition as _locationToPosition,
  positionToLocation as _positionToLocation,
} from "./positionUtils"
import { sortObj as _sortObj } from "./objectUtils"

export const sortObj = obj => {
  return _sortObj(obj)
}

export const sortArray = (array, sortOrder = [], sortKey) => {
  const sortedAtoZ = sortObj(array)

  const sorter = (a, b) => {
    if (!sortOrder.includes(sortKey ? a[sortKey] : a) && !sortOrder.includes(b))
      return 0
    if (!sortOrder.includes(sortKey ? a[sortKey] : a)) return 1
    if (!sortOrder.includes(sortKey ? b[sortKey] : b)) return -1
    if (
      sortOrder.indexOf(sortKey ? a[sortKey] : a) <
      sortOrder.indexOf(sortKey ? b[sortKey] : b)
    )
      return -1
    if (
      sortOrder.indexOf(sortKey ? a[sortKey] : a) >
      sortOrder.indexOf(sortKey ? b[sortKey] : b)
    )
      return 1
    return 0
  }

  return sortedAtoZ.sort(sorter)
}

export const normalizePathname = pathname => {
  if (!pathname || typeof pathname !== "string") return "/"
  var pathnameArray = pathname.split("/")
  pathnameArray = pathnameArray.filter(item => {
    return item !== ""
  })
  var result = pathnameArray.join("/")
  result = "/" + result + "/"
  return result
}

export const normalizeSearch = search => {
  return ""

  /* >>>>>>>>>>>>>>>>>>>>>>>>>>>> */
  /*
  if (!search || typeof search !== "string") return ""
  const params = new URLSearchParams(search ? search : "")
  if (typeof params.keys !== "function") return ""
  for (var key of params.keys()) {
    var value = params.get(key)
    var filteredValue = value && /^[0-9a-z\\=\\&]+/.exec(value)[0]
    if (!filteredValue) params.delete(key)
    else params.set(key, filteredValue)
  }
  return params.toString().length > 0 ? "?" + params.toString() : ""
  */
}

export const locationToUrl = location => {
  if (!location) return "/"
  return normalizePathname(location.pathname) + normalizeSearch(location.search)
}

export const locationToPosition = location => {
  return _locationToPosition(location)
}

export const positionToLocation = position => {
  return _positionToLocation(position)
}

export const changeUrlParam = (location, name, value) => {
  if (!name) return location

  const url = new URL(location.href ? location.href : "http://localhost/")

  const params = new URLSearchParams(location.search)
  if (value) params.set(name, value)
  else params.delete(name)

  const search = params.toString().length > 0 ? "?" + params.toString() : ""

  let result = {
    href: url.origin + location.pathname + search,
    pathname: location.pathname,
    search: search,
  }

  return result
}

export const getUrlParam = (location, name) => {
  const params = new URLSearchParams(location.search)
  return params.get(name)
}

export const getPathnameString = location => {
  var pathname = location.pathname ? location.pathname : "/"
  var search = location.search ? location.search : ""
  return pathname.concat(search)
}

export const getUrlForFilter = (
  location,
  activeFilters,
  panelId = undefined,
  updatedValue = undefined,
  position
) => {
  var activeFiltersUpdated = JSON.parse(JSON.stringify(activeFilters))
  if (position && typeof position[panelId] === "undefined")
    activeFiltersUpdated[panelId] = ""

  const result = Object.entries(activeFiltersUpdated).reduce(
    (acc, filter, index) => {
      const prefix = index !== 0 ? "&" : "?"
      if (filter[0] === panelId)
        return acc + prefix + `${filter[0]}=${updatedValue}`
      if (Array.isArray(filter[1]))
        return (
          acc +
          prefix +
          filter[1]
            .map(value => {
              return `${filter[0]}=${value}`
            })
            .join("&")
        )
      return acc + prefix + `${filter[0]}=${filter[1]}`
    },
    location.pathname
  )
  return result
}

export const getUrlForNewFilter = (
  location,
  activeFilters,
  panelId,
  updatedValue
) => {
  const updatedActiveFilters = { ...activeFilters, [panelId]: updatedValue }

  return getUrlForFilter(location, updatedActiveFilters)
}

export const getUrlForRemovedFilter = (location, activeFilters, panelId) => {
  const updatedActiveFilters = Object.keys(activeFilters).reduce(
    (acc, filter) => {
      if (filter === panelId) return acc

      return {
        ...acc,
        [filter]: activeFilters[filter],
      }
    },
    {}
  )
  return getUrlForFilter(
    location,
    updatedActiveFilters,
    panelId,
    null,
    locationToPosition(location)
  )
}

export const getUrlForMoreOptionsPopup = (pathname, search, productId) => {
  return search === ""
    ? pathname + `?p=${productId}`
    : pathname + search + `&p=${productId}`
}

export const serializePosition = position => {
  const positionString =
    typeof position !== "string" ? JSON.stringify(position) : position
  return Buffer.from(positionString).toString("base64")
}

export const convertHeapProductCount = heaps =>
  heaps.reduce((acc, heap) => {
    return acc + parseInt(heap.count.slice(0, heap.count.length - 1)) * 1000
  }, 0)

export const formatProductCount = count =>
  new Intl.NumberFormat("en-GB", {
    notation: "compact",
    compactDisplay: "short",
  }).format(count)

export const getRemoveFilterLinks = (activeFilters, location) =>
  Object.keys(activeFilters).reduce(
    (acc, filter) => ({
      ...acc,
      [filter]: getUrlForRemovedFilter(location, activeFilters, filter),
    }),
    {}
  )

export const availableFiltersToAdd = (filters, position) => ({
  type: "text",
  options: filters
    .filter(panelId => !Object.keys(position).includes(panelId))
    .map(key => ({
      caption: key,
      id: key,
      value: key,
    })),
})
