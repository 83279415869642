import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { SafeLink, Icon, icons } from "../index"
import { safeLinkActions } from "../../utils/constants"
import { connect } from "react-redux"
import { defaultLocale, labels } from "../../utils/labelConstants"

import * as safeLinkStyles from "../safeLink.module.css"
import * as buttonStyles from "../../styles/buttonStyles.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

const RemovePanelButton = ({ pathname, panelId, locale }) => (
  <SafeLink
    to={pathname}
    action={safeLinkActions.REMOVE_PANEL}
    actionPayload={{ panelId }}
    className={safeLinkStyles.noUnderline}
  >
    <div className={classNames(buttonStyles.removePanelButton)}>
      <Icon icon={icons.recycleBin} />
      <h4 className={classNames(labelStyles.categoryUp)}>
        {labels[locale].BTN_REMOVE}
      </h4>
    </div>
  </SafeLink>
)

RemovePanelButton.propTypes = {
  pathname: PropTypes.string,
  panelId: PropTypes.string,
}

const mapStateToProps = store => ({
  locale: store.history.locale ? store.history.locale : defaultLocale,
})

export const RemovePanelButtonRedux =
  connect(mapStateToProps)(RemovePanelButton)
