import React, { useState, useRef } from "react"
import { ColorFiltribute } from "./colorFiltribute"
import * as styles from "./filtribute.module.css"
import { useDispatch } from "react-redux"
import useHistoryState from "../../../hooks/useHistoryState"
import {
  SafeLinkObj,
  safeLinkActions,
  historyWrapper,
  saveHistory,
} from "../.."
import {
  positionsToRemove,
  multicolorIndex,
  mustContain,
  mustNotContain,
  ignore,
} from "../../../utils/constants"
import classNames from "classnames"
import * as multicolorStyles from "./multicolorFiltribute.module.css"
import { Icon } from "../../icon"
import { icons, iconsColors } from "../../icon.utils"
import ReactSlider from "react-slider" // https://zillow.github.io/react-slider/

export const MulticolorFiltribute = ({
  panelKey,
  position,
  filterPanelObj,
}) => {
  let background
  if (
    filterPanelObj &&
    filterPanelObj.panelData &&
    filterPanelObj.panelData[panelKey] &&
    filterPanelObj.panelData[panelKey].panelData &&
    filterPanelObj.panelData[panelKey].panelData.buttonBackground
  ) {
    background = filterPanelObj.panelData[panelKey].panelData.buttonBackground
  }

  let filterIndex = 1
  if (position[panelKey]) {
    if (multicolorIndex[position[panelKey][0]] != null) {
      filterIndex = multicolorIndex[position[panelKey][0]]
    }
  }
  const [sliderValue, _setSliderValue] = useState(filterIndex)
  const sliderValueRef = useRef(filterIndex)
  const setSliderValue = newValue => {
    if (newValue !== sliderValueRef.current) {
      sliderValueRef.current = _setSliderValue(newValue)
    }
  }
  const dispatch = useDispatch()
  const historyState = useHistoryState()

  const handleDeltaClick = filtribute => {
    const safeLinkObj = new SafeLinkObj(
      safeLinkActions.CHOOSE_VALUE,
      { panelId: panelKey },
      historyState,
      historyWrapper.location
    )
    const newState = safeLinkObj.newState
    const newUrl = filtribute.getLinkUrl(position)
    historyWrapper.pushState(newState, "", newUrl)
    // dispatch(pushHistory())
    dispatch(saveHistory(historyWrapper.location, historyWrapper.state))
  }

  const renderThumb = props => {
    const mustContain = props["aria-valuenow"] === 0
    const mustNotContain = props["aria-valuenow"] === 2
    return (
      <span
        {...props}
        className={classNames(
          props.className,
          { [multicolorStyles.mustContain]: mustContain },
          { [multicolorStyles.mustNotContain]: mustNotContain }
        )}
      >
        {mustContain ? (
          <Icon icon={icons.check} color={iconsColors.WHITE} />
        ) : null}
        {mustNotContain ? (
          <Icon icon={icons.close} color={iconsColors.WHITE} />
        ) : null}
      </span>
    )
  }

  const handleAfterChange = sliderValue => {
    if (
      filterPanelObj.panelData[panelKey].filtributes &&
      filterPanelObj.panelData[panelKey].filtributes[sliderValue] != null
    ) {
      let mustContainFiltribute
      let mustNotContainFiltribute
      let ignoreFiltribute
      filterPanelObj.panelData[panelKey].filtributes.map(filtribute => {
        let delta = []
        if (filtribute && filtribute._delta && filtribute._delta[panelKey])
          delta = filtribute._delta[panelKey]
        delta.map(deltaItem => {
          if (deltaItem.name === mustContain[panelKey])
            mustContainFiltribute = filtribute
          if (deltaItem.name === mustNotContain[panelKey])
            mustNotContainFiltribute = filtribute
          if (deltaItem.name === ignore[panelKey]) ignoreFiltribute = filtribute
          return false
        })
        return false
      })

      if (
        sliderValue === multicolorIndex[mustContain[panelKey]] &&
        mustContainFiltribute
      ) {
        handleDeltaClick(mustContainFiltribute)
      }
      if (
        sliderValue === multicolorIndex[mustNotContain[panelKey]] &&
        mustNotContainFiltribute
      ) {
        handleDeltaClick(mustNotContainFiltribute)
      }
      if (
        sliderValue === multicolorIndex[ignore[panelKey]] &&
        ignoreFiltribute
      ) {
        handleDeltaClick(ignoreFiltribute)
      }
    }
  }

  const isSelected = filtribute => {
    let result = false
    if (position[panelKey]) {
      if (position[panelKey].indexOf(filtribute.id) > -1) {
        result = true
      }
    } else if (positionsToRemove[panelKey] === filtribute.id) {
      result = true
    }
    return result
  }

  const options = filterPanelObj.panelData[panelKey].filtributes.map(
    (filtribute, idx) => {
      return (
        <div
          key={filtribute.id}
          onClick={() => {
            handleDeltaClick(filtribute)
          }}
          className={classNames(multicolorStyles.multicolorSwitch, {
            [multicolorStyles.first]: idx === 0,
            [multicolorStyles.last]: idx === 2,
          })}
        >
          <div className={multicolorStyles.switchBar} />
          {isSelected(filtribute) ? (
            <div
              className={classNames(multicolorStyles.switchIndicator, {
                [multicolorStyles.mustContain]: idx === 0,
                [multicolorStyles.mustNotContain]: idx === 2,
              })}
            >
              {idx === 0 ? (
                <Icon icon={icons.check} color={iconsColors.WHITE} />
              ) : null}
              {idx === 2 ? (
                <Icon icon={icons.close} color={iconsColors.WHITE} />
              ) : null}
            </div>
          ) : null}
          <div className={multicolorStyles.switchCount}>{filtribute.count}</div>
        </div>
      )
    }
  )

  let colorFiltributeUrl = ""
  if (
    filterPanelObj &&
    filterPanelObj.panelData[panelKey] &&
    filterPanelObj.panelData[panelKey].panelData &&
    filterPanelObj.panelData[panelKey].panelData.buttonImg
  ) {
    colorFiltributeUrl =
      filterPanelObj.panelData[panelKey].panelData.buttonImg.url
  }

  return (
    <div key={panelKey}>
      <div
        key={panelKey}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ColorFiltribute url={colorFiltributeUrl} background={background} />
        <div className={styles.filtributeDetails}>
          <h4 style={{ fontSize: "var(--font-size-main)", textAlign: "start" }}>
            {filterPanelObj.panelData[panelKey].panelData.caption}
          </h4>
        </div>
        <div
          style={{
            width: "130px",
            paddingLeft: "10px",
            paddingRight: "10px",
            position: "relative",
          }}
        >
          <ReactSlider
            className={multicolorStyles.multicolorSlider}
            trackClassName={multicolorStyles.multicolorSliderTrack}
            thumbClassName={multicolorStyles.multicolorSliderThumb}
            thumbActiveClassName={multicolorStyles.multicolorSliderThumbActive}
            min={0}
            minDistance={1}
            max={2}
            value={sliderValue}
            onChange={setSliderValue}
            onAfterChange={handleAfterChange}
            renderThumb={renderThumb}
          />
          {filterPanelObj.panelData[panelKey].filtributes.map(
            (filtribute, idx) => (
              <div
                key={`${filtribute.id}-${idx}`}
                style={{
                  position: "absolute",
                  textAlign: "center",
                  left: `${idx * 50}px`,
                  top: "40px",
                  width: "50px",
                }}
              >
                {filtribute.count}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}
