import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Icon, icons, SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"

import * as buttonStyles from "../../styles/buttonStyles.module.css"

export const ChangeProductButton = ({ direction, to }) => {
  return (
    <SafeLink to={to} action={safeLinkActions.MORE_OPTIONS}>
      <div
        className={classNames(
          {
            [buttonStyles.carouselButtonForward]:
              direction === directions.FORWARD,
          },
          { [buttonStyles.carouselButtonBack]: direction === directions.BACK }
        )}
      >
        <Icon
          icon={
            direction === directions.FORWARD
              ? icons.arrowForward
              : icons.arrowBack
          }
        />
      </div>
    </SafeLink>
  )
}

export const directions = {
  FORWARD: "forward",
  BACK: "back",
}

ChangeProductButton.propTypes = {
  direction: PropTypes.string.isRequired,
}
