import { filterPanelsActionTypes } from "./filterPanelsByPositionActions"
import { serializePosition } from "../../utils/positionUtils"
import { panelNames } from "../../utils/constants"

export const initialState = {}

export const initialStateForFetchRequest = {
  data: null,
  isLoading: true,
  error: null,
  timestamp: 0,
}

export const filterPanelsByPositionReducer = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case filterPanelsActionTypes.FILTER_PANELS_FETCH_REQUEST:
      return {
        ...state,
        [serializePosition(action.payload.position)]: {
          ...initialStateForFetchRequest,
          ...state[serializePosition(action.payload.position)],
          timestamp: Date.now(),
        },
      }

    case filterPanelsActionTypes.FILTER_PANELS_FETCH_SUCCESS:
      if (!action.payload.__position) return state

      var result = { ...state }

      if (
        state &&
        state[action.payload.__position] &&
        state[action.payload.__position].deploymentVersion &&
        state[action.payload.__position].deploymentVersion ===
          action.payload.__deploymentVersion
      ) {
        const keys = Object.keys(action.payload).filter(
          key => !key.startsWith("_")
        )
        var newStateData = JSON.parse(JSON.stringify(action.payload))
        keys.map(key => {
          if (Object.keys(action.payload[key]).length === 0) {
            if (
              state[action.payload.__position] &&
              state[action.payload.__position].data &&
              state[action.payload.__position].data[key]
            )
              newStateData[key] = JSON.parse(
                JSON.stringify(state[action.payload.__position].data[key])
              )
          }
          return false
        })
        result[action.payload.__position] = {
          ...state[action.payload.__position],
        }
        result[action.payload.__position].data = newStateData
      } else {
        result[action.payload.__position] = {}
        result[action.payload.__position].data = action.payload
      }

      result[action.payload.__position].version = action.payload.__version
      result[action.payload.__position].deploymentVersion =
        action.payload.__deploymentVersion
      result[action.payload.__position].captionsVersion =
        action.payload.__captionsVersion
      result[action.payload.__position].timestamp = Date.now()
      result[action.payload.__position].isLoading = false
      result[action.payload.__position].error = null

      return result

    case filterPanelsActionTypes.FILTER_PANELS_FETCH_FAILURE:
      return {
        ...state,
        [action.payload.__position]: {
          ...state[action.payload.__position],
          isLoading: false,
          timestamp: Date.now(),
          error: action.payload.error.message,
        },
      }

    default:
      return state
  }
}
