import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { SafeLink, Icon } from "../index"
import { safeLinkActions } from "../../utils/constants"

import * as styles from "./safeButton.module.css"

import * as safeLinkStyles from "../safeLink.module.css"

export const SafeButton = ({
  to,
  presentation,
  icon,
  payload,
  underline = false,
  grow = true,
  children,
}) => {
  var action = null
  var content = null

  switch (presentation) {
    case presentations.ADD_PANEL:
      action = safeLinkActions.ADD_PANEL
      content = children
      break
    case presentations.GALLERY_ARROW:
      action = safeLinkActions.MORE_OPTIONS
      content = <Icon icon={icon} size={18} />
      break
    default:
      break
  }

  return (
    <SafeLink
      to={to}
      action={action}
      className={classNames(
        { [safeLinkStyles.grow]: grow },
        { [safeLinkStyles.noUnderline]: !underline }
      )}
      actionPayload={payload}
    >
      <div data-testid="safe-button" className={styles[presentation]}>
        {content}
      </div>
    </SafeLink>
  )
}

SafeButton.propTypes = {
  presentation: PropTypes.string.isRequired,
  icon: PropTypes.string,
}

export const presentations = {
  GALLERY_ARROW: "galleryArrow",
  ADD_PANEL: "addPanel",
}
