import React from "react"

import * as styles from "./basicComponent.module.css"

export const Grid = ({ children, itemWidth }) => {
  var style = {}
  if (typeof itemWidth !== "undefined") {
    style.width = itemWidth * Math.floor(310 / itemWidth) + "px"
  }

  return (
    <div data-testid="grid-component" className={styles.grid} style={style}>
      {children}
    </div>
  )
}
