import { heapViewActionTypes } from "./heapViewByPositionActions"
import { serializePosition } from "../../utils/positionUtils"
import { groupNames } from "../../utils/constants"
import {
  mockData,
  mockData1,
  mockData2,
  flatMock,
} from "../../utils/_tempMocks"

export const initialState = {}

export const initialStateForFetchRequest = {
  productCount: null,
  isLoading: false,
  loadingMore: false,
  error: null,
  timestamp: 0,
}

export const initialStateForSortedFetchRequest = {
  heaps: [],
  products: [],
}

export const heapViewByPositionReducer = (
  state = initialState,
  action = {}
) => {
  let timestamp = Date.now()

  let currentState = JSON.parse(JSON.stringify(state))
  if (action.payload && action.payload.position) {
    const positionKey = serializePosition(action.payload.position)
    if (typeof currentState[positionKey] === "undefined") {
      currentState[positionKey] = JSON.parse(
        JSON.stringify(initialStateForFetchRequest)
      )
    }
    if (action.payload.grouping && action.payload.sorting) {
      const groupingKey = action.payload.grouping
      const sortingKey = action.payload.sorting
      if (typeof currentState[positionKey][groupingKey] === "undefined") {
        currentState[positionKey][groupingKey] = {}
      }
      if (
        typeof currentState[positionKey][groupingKey][sortingKey] ===
        "undefined"
      ) {
        currentState[positionKey][groupingKey][sortingKey] = JSON.parse(
          JSON.stringify(initialStateForSortedFetchRequest)
        )
      }
      currentState[positionKey][groupingKey][sortingKey].timestamp = timestamp
    }
  }

  switch (action.type) {
    case heapViewActionTypes.HEAP_VIEW_FETCH_REQUEST:
      return {
        ...state,
        [serializePosition(action.payload.position)]: {
          ...currentState[serializePosition(action.payload.position)],
          timestamp: timestamp,
          isLoading: true,
          loadingMore: false,
        },
      }

    case heapViewActionTypes.HEAP_VIEW_REFETCH_REQUEST:
      return {
        ...state,
        [serializePosition(action.payload.position)]: {
          ...currentState[serializePosition(action.payload.position)],
          isLoading: false,
          loadingMore: true,
        },
      }

    case heapViewActionTypes.HEAP_VIEW_FETCH_SUCCESS:
      if (!action.payload.__position) return state

      let payload = JSON.parse(JSON.stringify(action.payload))
      if (action.payload.status && action.payload.status === "mock") {
        let responseMock = mockData
        if (payload.__grouping === groupNames.NONE) {
          responseMock = flatMock
        } else if (payload.__grouping === groupNames.SHAPE) {
          if (payload.__pageIndex === 1) responseMock = mockData1
          if (payload.__pageIndex === 2) responseMock = mockData2
        }
        payload = { ...payload, ...responseMock }
      }
      let heaps = payload.heaps
      let products = payload.products

      if (state[payload.__position]) {
        currentState = JSON.parse(JSON.stringify(state[payload.__position]))
        if (
          state[payload.__position][payload.__grouping] &&
          state[payload.__position][payload.__grouping][payload.__sorting]
        ) {
          if (payload.__pageIndex !== 0) {
            heaps = currentState[payload.__grouping][payload.__sorting].heaps
            products = currentState[payload.__grouping][
              payload.__sorting
            ].products.concat(payload.products)
          }
        }
      }

      return {
        ...state,
        [payload.__position]: {
          ...currentState,
          [payload.__grouping]: {
            ...currentState[payload.__grouping],
            [payload.__sorting]: {
              timestamp: timestamp,
              pageIndex: payload.page_no,
              pageCount: payload.no_of_pages,
              heaps: heaps,
              products: products,
            },
          },
          version: payload.__version,
          deploymentVersion: payload.__deploymentVersion,
          timestamp: timestamp,
          isLoading: false,
          loadingMore: false,
          productCount: payload.total ? payload.total : null,
          error: null,
        },
      }

    case heapViewActionTypes.HEAP_VIEW_FETCH_FAILURE:
      return {
        ...state,
        [action.payload.__position]: {
          ...state[action.payload.__position],
          isLoading: false,
          loadingMore: false,
          error: action.payload.error.message,
        },
      }

    case heapViewActionTypes.PRODUCT_DETAILS_FETCH_REQUEST:
      return {
        ...state,
      }

    case heapViewActionTypes.RODUCT_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
      }

    case heapViewActionTypes.RODUCT_DETAILS_FETCH_FAILURE:
      return {
        ...state,
      }

    case heapViewActionTypes.HEAP_VIEW_PRODUCT_COUNT:
      return {
        ...state,
        productsToDisplayCount: action.payload,
      }

    case heapViewActionTypes.HEAP_VIEW_SELECT_COLOR:
      const productToChange = JSON.parse(
        JSON.stringify(
          state[serializePosition(action.payload.position)][
            action.payload.grouping
          ][action.payload.sorting].products[action.payload.productIndex]
        )
      )
      const colorOptions = productToChange.visuals.map(colorOption => ({
        ...colorOption,
        selected: false,
      }))
      colorOptions[action.payload.colorIndex].selected = true
      productToChange.visuals = colorOptions
      const productsToChange = JSON.parse(
        JSON.stringify(
          state[serializePosition(action.payload.position)][
            action.payload.grouping
          ][action.payload.sorting].products
        )
      )
      productsToChange[action.payload.productIndex] = productToChange

      return {
        ...state,
        [serializePosition(action.payload.position)]: {
          ...state[serializePosition(action.payload.position)],
          [action.payload.grouping]: {
            ...state[serializePosition(action.payload.position)][
              action.payload.grouping
            ],
            [action.payload.sorting]: {
              ...state[serializePosition(action.payload.position)][
                action.payload.grouping
              ][action.payload.sorting],
              products: productsToChange,
            },
          },
        },
      }

    default:
      return state
  }
}
