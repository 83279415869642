import { hasCoordinateVisualization } from "./coordinateVisualization"
import { panelKeys } from "../../../utils"
import { panelCaptions } from "../../../utils"
import { indicatorSubTypes } from "../../../utils"

const hasSubType = (data, panelKey) => {
  let result = false
  if (hasCoordinateVisualization(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    if (coordinateVisualization.type && coordinateVisualization.sub_type) {
      result = coordinateVisualization.sub_type != null
    }
  }
  return result
}

const getSubType = (data, panelKey) => {
  let result
  if (hasSubType(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    result = coordinateVisualization.sub_type
  }
  return result
}

export const getPanelName = (data, panelKey) => {
  let result =
    panelKey !== panelKeys.SHAPE_FILTER
      ? panelCaptions[panelKey]
      : panelCaptions["CATEGORY_SUB_TYPE"]
  const subType = getSubType(data, panelKey)
  if (subType === indicatorSubTypes.SHAPE) {
    result = panelCaptions["SHAPE_SUB_TYPE"]
  }
  return result
}
