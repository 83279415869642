import { useRef, useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { locationToPosition } from "../utils"
import { serializePosition } from "../utils/positionUtils"
import { groupNameByIndex, sortingNameByIndex } from "../utils"
import { ResultsObj } from "../utils"
import { shouldFetchHeapView, shouldRefetchHeapView } from "../utils"
import useVersioning from "./useVersioning"
import { fetchHeapViewRequest, refetchHeapViewRequest } from "../store"
import { StaticDataContext } from "../templates"
import useSorting from "./useSorting"
import useGrouping from "./useGrouping"

const useProducts = ({ location }) => {
  const { sortingIndex } = useSorting()
  const { groupingIndex } = useGrouping()
  const { filterPanelVersion, deploymentVersion } = useVersioning()
  const staticData = useContext(StaticDataContext)
  const oldHeapViewRef = useRef()
  const dispatch = useDispatch()
  const position = locationToPosition(location)
  const serializedPosition = serializePosition(position)
  const heapViewByPosition = useSelector(store =>
    store.heapViewByPosition && store.heapViewByPosition[serializedPosition]
      ? store.heapViewByPosition[serializedPosition]
      : undefined
  )

  // refs for loadMore
  const positionRef = useRef()
  positionRef.current = position
  const sortingIndexRef = useRef()
  sortingIndexRef.current = sortingIndex
  const groupingIndexRef = useRef()
  groupingIndexRef.current = groupingIndex
  const deploymentVersionRef = useRef()
  deploymentVersionRef.current = deploymentVersion
  const filterPanelVersionRef = useRef()
  filterPanelVersionRef.current = filterPanelVersion

  const staticHeapViewByPosition = {
    [groupNameByIndex(groupingIndex)]: {
      [sortingNameByIndex(sortingIndex)]: {
        products: staticData.products,
        heaps: staticData.heaps,
      },
    },
  }

  let heapView
  if (heapViewByPosition) {
    heapView = new ResultsObj(
      heapViewByPosition,
      groupNameByIndex(groupingIndex),
      sortingNameByIndex(sortingIndex)
    )
    oldHeapViewRef.current = heapView
  } else {
    if (oldHeapViewRef.current) {
      heapView = oldHeapViewRef.current
      heapView.loading = true
    } else if (staticHeapViewByPosition) {
      heapView = new ResultsObj(staticHeapViewByPosition)
    }
  }

  const producePage = () => {
    if (!position) return false
    if (Object.keys(position).length < 1) return false
    if (shouldFetchHeapView(heapViewByPosition, deploymentVersion, position)) {
      dispatch(
        fetchHeapViewRequest({
          position: position,
          grouping: groupNameByIndex(groupingIndex),
          sorting: sortingNameByIndex(sortingIndex),
          deploymentVersion: deploymentVersion,
          filterPanelVersion: filterPanelVersion,
        })
      )
    } else if (
      shouldRefetchHeapView(
        heapViewByPosition,
        groupNameByIndex(groupingIndex),
        sortingNameByIndex(sortingIndex)
      )
    ) {
      dispatch(
        refetchHeapViewRequest({
          position: position,
          grouping: groupNameByIndex(groupingIndex),
          sorting: sortingNameByIndex(sortingIndex),
          deploymentVersion: deploymentVersion,
          filterPanelVersion: filterPanelVersion,
        })
      )
    }
  }

  const loadMoreProducts = () => {
    if (oldHeapViewRef.current.loadingMore) return false
    if (oldHeapViewRef.current.pageIndexToFetch < 0) return false
    if (!positionRef.current) return false
    if (Object.keys(positionRef.current).length < 1) return false
    dispatch(
      refetchHeapViewRequest({
        position: positionRef.current,
        pageIndex: oldHeapViewRef.current.pageIndexToFetch,
        grouping: groupNameByIndex(groupingIndexRef.current),
        sorting: sortingNameByIndex(sortingIndexRef.current),
        deploymentVersion: deploymentVersionRef.current,
        filterPanelVersion: filterPanelVersionRef.current,
      })
    )
  }

  useEffect(() => {
    producePage()
  }, [serializedPosition])

  useEffect(() => {
    producePage()
  }, [groupingIndex])

  useEffect(() => {
    producePage()
  }, [sortingIndex])

  useEffect(() => {
    if (parseInt(deploymentVersion) >= 0) {
      producePage()
    }
  }, [deploymentVersion])

  return {
    heapView,
    heapViewByPosition,
    loadingHeapView: heapView.loading,
    loadMoreProducts,
  }
}

export default useProducts
