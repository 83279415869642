import { useSelector, useDispatch } from "react-redux"
import {
  SafeLinkObj,
  safeLinkActions,
  getPathnameString,
  locationDeleteParam,
  historyWrapper,
} from "../utils"
import { pushHistory } from "../store"

const useDialog = () => {
  const dispatch = useDispatch()
  const openDialog = useSelector(store =>
    store.history && store.history.state ? store.history.state.openDialog : null
  )

  const closeDialog = () => {
    const safeLinkObj = new SafeLinkObj(
      safeLinkActions.CLOSE_DIALOG,
      { panelId: historyWrapper.state.openPanel },
      historyWrapper.state,
      historyWrapper.location
    )
    const newState = safeLinkObj.newState
    const closeUrl = getPathnameString(
      locationDeleteParam(historyWrapper.location, "p")
    )
    historyWrapper.pushState(newState, "", closeUrl)
    dispatch(pushHistory())
  }

  return { openDialog, isDialogOpen: openDialog != null, closeDialog }
}

export default useDialog
