import React from "react"
import classNames from "classnames"

import * as styles from "./newSearchButton.module.css"
import * as buttonStyles from "../button.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

export const StartProcessingButton = ({ handleClick }) => {
  return (
    <div
      className={classNames(
        buttonStyles.buttonCommonStyles,
        buttonStyles.importantTheme
      )}
      onClick={handleClick}
    >
      Start Processing
    </div>
  )
}
