import React, { forwardRef } from "react"
import classNames from "classnames"
import { Icon, icons, iconsColors } from "./index"
import { labels } from "../utils/labelConstants"
import useLocale from "../hooks/useLocale"

import * as styles from "./textSearchField.module.css"

export const TextSearchField = forwardRef(
  ({ active, searchText, handleSearchTextChange, landing = false }, ref) => {
    var locale = useLocale()
    return (
      <div
        ref={ref}
        className={classNames(styles.textSearchContainer, {
          [styles.active]: active,
        })}
      >
        <input
          type="text"
          placeholder={labels[locale].SEARCH_QUERY_PLACEHOLDER}
          onChange={event => {
            handleSearchTextChange(event.currentTarget.value)
          }}
          value={searchText}
          className={classNames(styles.searchInput, {
            [styles.active]: active,
            [styles.landing]: landing,
          })}
        />
        <div
          className={classNames(styles.searchIcon, { [styles.active]: active })}
        >
          <Icon icon={icons.search} color={iconsColors.GREY_LIGHT} />
        </div>
      </div>
    )
  }
)
