import React from "react"

function BrandIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m29.426 11.28 6.247 6.246-1.041 7.288-16.138 16.139L6 28.458l16.138-16.137 7.288-1.042Zm-2.408 12.654c2.22 0 4.018-1.937 4.018-4.327 0-2.39-1.799-4.328-4.018-4.328S23 17.217 23 19.607c0 2.39 1.799 4.327 4.018 4.327Z"
        fill="#FDB046"
      />
      <path
        d="M29.81 12.78c.696-2.39 3.254-5.905 6.934-4.43 3.566 1.43 2.225 6.742-2.434 8.93 0 0-1.81.937-3.31.72-1.69-.244-2-1.5-2-1.5"
        stroke="#F98F3B"
        strokeLinecap="round"
      />
      <path
        d="M24.776 23.2c-.6 2.172-.768 4.757-.226 7.817.259 1.463-.077 3.125-.867 4.746l-5.189 5.19L6 28.458l16.138-16.137 7.288-1.042 2.818 2.818c-.89.49-1.895 1.145-2.884 1.993a3.81 3.81 0 0 0-2.342-.81C24.8 15.28 23 17.216 23 19.606c0 1.496.705 2.815 1.777 3.592Z"
        fill="#FACC55"
      />
      <path
        d="M26.354 11.718c-3.147 2.967-7.073 8.867-3.415 17.74.939 2.277.397 5.437-1.227 8.276l-3.21 3.21-.046-.03L6 28.458 22.138 12.32l4.216-.602Z"
        fill="#F98F3B"
      />
    </svg>
  )
}

export { BrandIcon }
