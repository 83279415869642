export const photoSearchActionTypes = {
  PHOTO_SEARCH_FETCH_REQUEST: "PHOTO_SEARCH_FETCH_REQUEST",
  PHOTO_SEARCH_FETCH_SUCCESS: "PHOTO_SEARCH_FETCH_SUCCESS",
  PHOTO_SEARCH_FETCH_FAILURE: "PHOTO_SEARCH_FETCH_FAILURE",
  PHOTO_SEARCH_POST_REQUEST: "PHOTO_SEARCH_POST_REQUEST",
  PHOTO_SEARCH_POST_SUCCESS: "PHOTO_SEARCH_POST_SUCCESS",
  PHOTO_SEARCH_POST_FAILURE: "PHOTO_SEARCH_POST_FAILURE",
}

export const fetchPhotoSearchRequest = payload => ({
  type: photoSearchActionTypes.PHOTO_SEARCH_FETCH_REQUEST,
  payload,
})

export const fetchPhotoSearchSuccess = payload => ({
  type: photoSearchActionTypes.PHOTO_SEARCH_FETCH_SUCCESS,
  payload,
})

export const fetchPhotoSearchFailure = payload => ({
  type: photoSearchActionTypes.PHOTO_SEARCH_FETCH_FAILURE,
  payload,
})

export const postPhotoSearchRequest = payload => ({
  type: photoSearchActionTypes.PHOTO_SEARCH_POST_REQUEST,
  payload,
})

export const postPhotoSearchSuccess = payload => ({
  type: photoSearchActionTypes.PHOTO_SEARCH_POST_SUCCESS,
  payload,
})

export const postPhotoSearchFailure = payload => ({
  type: photoSearchActionTypes.PHOTO_SEARCH_POST_FAILURE,
  payload,
})
