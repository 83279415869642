import { hasCoordinateVisualization } from "./coordinateVisualization"
import { panelKeys } from "../../../utils"
import { panelNames } from "../../../utils/constants"
import { isSyntheticPanel, getSubPanelKeys } from "./mergePanels"
import { getPriceCaption } from "./priceCaption"

const getCoordinateCaption = (data, panelKey) => {
  let result
  if (
    data &&
    data.__coordinate_caption__ &&
    data.__coordinate_caption__[panelKey] != null
  ) {
    result = data.__coordinate_caption__[panelKey]
  }
  return result
}

const shouldUseCoordinateCaption = (panelKey, currentCaption) => {
  if (panelKey === panelKeys.SIZE_FILTER && currentCaption) {
    return false
  }
  if (panelKey === panelKeys.VENDOR_FILTER && currentCaption) {
    return false
  }
  if (panelKey === panelKeys.BRAND_FILTER && currentCaption) {
    return false
  }
  return true
}

export const getCaption = item => {
  let result
  if (item.texts) {
    result = item.texts.join(", ")
  }
  if (
    item.type &&
    (item.type === "text" || item.type === "numerical") &&
    item.value
  ) {
    result = item.value
  }
  return result
}

export const getPanelCaption = (data, panelKey) => {
  let result
  if (data && data[panelKey]) {
    if (data[panelKey].name && panelNames[data[panelKey].name]) {
      result = panelNames[data[panelKey].name]
    }
    if (data[panelKey].names) {
      result = data[panelKey].name
    }
  }
  if (hasCoordinateVisualization(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    result = getCaption(coordinateVisualization) || result
  }
  const coordinateCaption = getCoordinateCaption(data, panelKey)
  if (coordinateCaption && shouldUseCoordinateCaption(panelKey, result)) {
    result = coordinateCaption
  }
  if (data && data[panelKey] && isSyntheticPanel(panelKey)) {
    let captions = []
    getSubPanelKeys(panelKey).map(subPanelKey => {
      captions.push(
        getPanelCaption({ ...data, ...data[panelKey] }, subPanelKey)
      )
      return false
    })
    if (panelKey === panelKeys.PRICE_FILTER) {
      result = getPriceCaption(captions)
    }
  }

  return result
}
