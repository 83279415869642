import React from "react"
import PropTypes from "prop-types"

import * as styles from "./landingHeapPreview.module.css"

export const LandingHeapPreview = ({ caption, imgData }) => (
  <>
    {imgData.map((card, cardIndex) => {
      return (
        <div
          key={cardIndex}
          className={styles.cardWithImage}
          style={{
            top: card.top + "px",
            left: card.left + "px",
            transform: "rotate(" + card.angle + "deg)",
          }}
        >
          <img src={card.url} alt={`${caption}-${cardIndex}`} />
        </div>
      )
    })}
  </>
)

LandingHeapPreview.propTypes = {
  imgData: PropTypes.arrayOf(
    PropTypes.exact({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      angle: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}
