import React from "react"
import classNames from "classnames"
import { Icon } from "../../icon"
import { icons, iconsColors } from "../../icon.utils"
import * as styles from "./multicolorIndicator.module.css"

export const MulticolorIndicator = ({ selection }) => {
  let mustContainBubble = null
  let mustNotContainBubble = null
  if (selection && selection.button) {
    if (Array.isArray(selection.button.mustContain)) {
      mustContainBubble = selection.button.mustContain.map(item => {
        return (
          <div
            key={item.id}
            style={{
              flexGrow: "1",
              background: item.color,
            }}
          />
        )
      })
    }
    if (Array.isArray(selection.button.mustNotContain)) {
      mustNotContainBubble = selection.button.mustNotContain.map(item => {
        return (
          <div
            key={item.id}
            style={{
              flexGrow: "1",
              background: item.color,
            }}
          />
        )
      })
    }
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        className={classNames(styles.mustContainBubble, {
          [styles.halfBubble]: mustNotContainBubble != null,
        })}
      >
        {mustContainBubble}
      </div>
      <div
        className={classNames(styles.mustNotContainBubble, {
          [styles.halfBubble]: mustContainBubble != null,
        })}
      >
        {mustNotContainBubble}
      </div>
      {mustContainBubble ? (
        <div className={classNames(styles.ruleMarker, styles.mustContain)}>
          <Icon icon={icons.check} color={iconsColors.WHITE} size={12} />
        </div>
      ) : null}
      {mustNotContainBubble ? (
        <div
          className={classNames(styles.ruleMarker, styles.mustNotContain, {
            [styles.half]: mustContainBubble != null,
          })}
        >
          <Icon icon={icons.close} color={iconsColors.WHITE} size={10} />
        </div>
      ) : null}
    </div>
  )
}
