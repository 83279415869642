import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as createReduxStore,
} from "redux"
import { historyWrapper } from "../components/index"
import { combineEpics, createEpicMiddleware } from "redux-observable"
import { catchError } from "rxjs/operators"

import {
  axiosWrapper,
  loadStore,
  saveStore,
  storeReducers,
  heapViewByPositionReducer,
  filterPanelsByPositionReducer,
  historyReducer,
  photoSearchReducer,
  productDetailsReducer,
  getHeapViewByPositionEpic,
  getProductDetailsEpic,
  getFilterPanelsByPositionEpic,
  getPhotoSearchEpic,
  postPhotoSearchEpic,
} from "../components/index"

const tokenItem = "gasefiToken"

const rootReducer = combineReducers({
  [storeReducers.HEAP_VIEW_BY_POSITION]: heapViewByPositionReducer,
  [storeReducers.FILTER_PANELS_BY_POSITION]: filterPanelsByPositionReducer,
  [storeReducers.HISTORY]: historyReducer,
  [storeReducers.PHOTO_SEARCH]: photoSearchReducer,
  [storeReducers.PRODUCT_DETAILS]: productDetailsReducer,
})

const heapViewEpic = (action$, store$, dependencies) =>
  combineEpics(getHeapViewByPositionEpic)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error("heap view epic", error)
      return source
    })
  )

const productDetailsEpic = (action$, store$, dependencies) =>
  combineEpics(getProductDetailsEpic)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error("product details epic", error)
      return source
    })
  )

const filterPanelsEpic = (action$, store$, dependencies) =>
  combineEpics(getFilterPanelsByPositionEpic)(
    action$,
    store$,
    dependencies
  ).pipe(
    catchError((error, source) => {
      console.error("filter panels epic", error)
      return source
    })
  )

const photoSearchEpic = (action$, store$, dependencies) =>
  combineEpics(getPhotoSearchEpic, postPhotoSearchEpic)(
    action$,
    store$,
    dependencies
  ).pipe(
    catchError((error, source) => {
      console.error("photo search epic", error)
      return source
    })
  )

const axiosClient = new axiosWrapper(() => {
  // Auth Error callback
  localStorage.removeItem(tokenItem)
  // axiosClient.authenticate()
  // historyWrapper.pushState({ openPanel: null, level: 0, key: String(Date.now()) }, "")
  // window.location.reload()
})

const authToken =
  typeof localStorage !== "undefined" ? localStorage.getItem(tokenItem) : null
if (authToken) axiosClient.addToken(authToken)

const createStore = () => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      axiosClient,
    },
  })

  const middlewares = [epicMiddleware]

  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose

  const enhancer = composeEnhancers(applyMiddleware(...middlewares))

  const persistedState =
    typeof window === "object" && window.localStorage ? loadStore() : undefined

  const store = createReduxStore(rootReducer, persistedState, enhancer)

  store.subscribe(() => {
    if (typeof window === "object" && window.localStorage)
      saveStore(store.getState())
  })

  epicMiddleware.run(heapViewEpic)
  epicMiddleware.run(filterPanelsEpic)
  epicMiddleware.run(photoSearchEpic)
  epicMiddleware.run(productDetailsEpic)

  return store
}

export default createStore
