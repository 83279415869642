export const categoryToUrl = {
  '["fa.cat.shoe"]': "/footwear/",
  '["fa.cat.shoe","fa.cat.shoe.w"]': "/footwear/women/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.w.pumps"]': "/footwear/women/pumps/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.w.sandals"]':
    "/footwear/women/sandals/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.w.shoes"]': "/footwear/women/shoes/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.w.boots"]': "/footwear/women/boots/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.w.mules"]': "/footwear/women/mules/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.shoe.w.sneakers"]':
    "/footwear/women/sneakers/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.w.flipflops_slippers"]':
    "/footwear/women/slides/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.shoe.w.hiking"]':
    "/footwear/women/hiking/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.shoe.w.sport"]':
    "/footwear/women/sport/",
  '["fa.cat.shoe","fa.cat.shoe.w","fa.cat.w.slippers"]':
    "/footwear/women/slippers/",
}

export const urlToPosition = {
  "/footwear/": { shape: ["fa.cat.shoe"] },
  "/footwear/women/": { shape: ["fa.cat.shoe", "fa.cat.shoe.w"] },
  "/footwear/women/pumps/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.pumps"],
  },
  "/footwear/women/sandals/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.sandals"],
  },
  "/footwear/women/shoes/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.shoes"],
  },
  "/footwear/women/boots/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.boots"],
  },
  "/footwear/women/mules/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.mules"],
  },
  "/footwear/women/sneakers/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.shoe.w.sneakers"],
  },
  "/footwear/women/slides/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.flipflops_slippers"],
  },
  "/footwear/women/hiking/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.shoe.w.hiking"],
  },
  "/footwear/women/sport/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.shoe.w.sport"],
  },
  "/footwear/women/slippers/": {
    shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.slippers"],
  },
}
