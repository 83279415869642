import React from "react"
import classNames from "classnames"

import { Icon, icons, iconsColors } from "../index.js"

import * as styles from "./basicComponent.module.css"

export const Checkbox = ({ checked }) => {
  return (
    <div
      data-testid="checkbox"
      className={classNames(styles.checkbox, { [styles.checked]: checked })}
    >
      {checked && (
        <Icon icon={icons.check} size={20} color={iconsColors.WHITE} />
      )}
    </div>
  )
}
