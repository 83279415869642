export const buttonThemes = {
  SUBMIT: "submitTheme",
  IMPORTANT: "submitTheme",
  ALERT: "alertTheme",
  STANDARD: "standardTheme",
  CANCEL: "cancelTheme",
  TRANSPARENT: "transparentTheme",
  SHOW_RESULTS: "showResults",
  TURQUOISE: "turquoiseTheme",
}

export const buttonBackground = {
  GREY: "#f2f2f2",
  BLUE: "#29abe2",
  DARK_GREY: "#aaa",
  TRANSPARENT: "transparent",
}

export const buttonColor = {
  BLACK: "#000",
  WHITE: "#fff",
  STANDARD: "inherit",
}

export const buttonBorder = {
  NONE: "unset",
  RED: "1px solid #eb5757",
}

export const buttonFontSize = {
  XXS: "8px",
  XS: "10px",
  SM: "12px",
  MAIN: "14px",
  L: "16px",
  XL: "18px",
  XXL: "20px",
}

export const buttonFontWeight = {
  THIN: "100",
  NORMAL: "400",
  BOLD: "700",
}

export const buttonOutline = {
  NONE: "unset",
  RED: "1px solid red",
}

export const buttonHeight = {
  SMALL: "32px",
  NORMAL: "40px",
  BIG: "44px",
  AUTO: "auto",
}

export const buttonWidth = {
  SMALL: "32px",
  NORMAL: "40px",
  BIG: "44px",
  AUTO: "auto",
}
