import React, { useEffect } from "react"
import useLocale from "../../hooks/useLocale"
import classNames from "classnames"

import {
  UploadButton,
  labels,
  CameraIcon,
  readImageFromFile,
  handleFiles,
  handleHtml,
  Image,
} from "../index"

import * as styles from "./dropArea.module.css"
import * as previewStyles from "./uploadPreview.module.css"

export const DropArea = ({ uploadedImage, setUploadedImage }) => {
  const locale = useLocale()

  useEffect(() => {
    document.addEventListener("dragenter", preventDefault, false)
    document.addEventListener("dragleave", preventDefault, false)
    document.addEventListener("dragover", preventDefault, false)
    document.addEventListener("drop", handleDrop, false)
    document.addEventListener("paste", handlePaste)

    return () => {
      document.removeEventListener("dragenter", preventDefault)
      document.removeEventListener("dragleave", preventDefault)
      document.removeEventListener("dragover", preventDefault)
      document.removeEventListener("drop", handleDrop)
      document.removeEventListener("paste", handlePaste)
    }
  }, [])

  const preventDefault = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handlePaste = e => {
    var item = e.clipboardData.items[0]

    if (item.type.indexOf("image") === 0) {
      readImageFromFile(item.getAsFile(), setUploadedImage)
    } else {
      if (item.type && item.type === "text/plain") {
        item.getAsString(result => {
          var src = result && /^http[s]?:[^”"'|{}\\\[\]<>\s]+/.exec(result)
          if (src[0]) setUploadedImage(src[0])
        })
      } else {
        handleDrop(e)
      }
    }
  }

  const handleDrop = e => {
    preventDefault(e)

    var data = null
    if (e.dataTransfer) data = e.dataTransfer
    else if (e.clipboardData) data = e.clipboardData

    if (!data || !data.files) return false
    var files = data.files

    if (files.length) {
      handleFiles(files, setUploadedImage)
    } else {
      handleHtml(data, setUploadedImage)
    }
  }

  return (
    <div
      data-testid="drop-area"
      className={classNames(styles.dropArea, {
        [styles.empty]: !uploadedImage,
      })}
    >
      {uploadedImage ? (
        <>
          <div
            className={previewStyles.imageContainer}
            style={{ height: "100%" }}
          >
            <Image
              src={uploadedImage}
              alt="Uploaded"
              contain
              blurredBackground
            />
          </div>
        </>
      ) : (
        <>
          <CameraIcon />
          <div className={styles.messageContainer}>
            <p>{labels[locale].UPLOAD_PASTE}</p>
            <p>{labels[locale].UPLOAD_OR}</p>
            <p>{labels[locale].UPLOAD_DRAG_AND_DROP}</p>
            <p>{labels[locale].UPLOAD_OR}</p>
            <UploadButton
              handleFileUpload={files => {
                handleFiles(files, setUploadedImage)
              }}
            >
              Upload
            </UploadButton>
          </div>
        </>
      )}
    </div>
  )
}
/*
          <img src={uploadedImage} alt="Uploaded image" />
          <div className={styles.buttonInPreview}>

          </div>
*/

/*
  <UploadButton
    handleFileUpload={files => {
      handleFiles(files)
    }}
  >
    Upload
  </UploadButton>
*/
