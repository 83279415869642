import React from "react"
import PropTypes from "prop-types"
import {Logo, Logotype, SafeLink} from "../index"
import {safeLinkActions} from "../../utils/constants"

import * as styles from "./homeButton.module.css"

export const HomeButton = ({pathname}) => (
  <SafeLink to={pathname} action={safeLinkActions.HOME}>
    <div className={styles.headerLogoContainer}>
      <div className={styles.headerLogo}>
        <Logo/>
      </div>
      <div className={styles.headerLogotype}>
        <Logotype/>
      </div>
    </div>
  </SafeLink>
)

HomeButton.propTypes = {
  pathname: PropTypes.string,
}
