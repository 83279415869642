import React from "react"
import classNames from "classnames"

import * as buttonStyles from "../button.module.css"

export const UploadButton = ({
  handleFileUpload,
  footerButton,
  important,
  children,
}) => {
  const fakeInput = document.createElement("input")
  fakeInput.type = "file"
  fakeInput.accept = "image/*"
  fakeInput.multiple = true
  fakeInput.addEventListener("change", () => {
    handleFileUpload(fakeInput.files)
  })

  const handleClick = () => {
    fakeInput.click()
  }

  return (
    <div
      className={classNames(
        buttonStyles.buttonCommonStyles,
        { [buttonStyles.importantTheme]: important },
        { [buttonStyles.standardTheme]: !important },
        { [buttonStyles.mobileFooterButton]: footerButton }
      )}
      onClick={handleClick}
    >
      {children ? children : "Upload"}
    </div>
  )
}
