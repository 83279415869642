import { useSelector, useDispatch } from "react-redux"
import { setGrouping } from "../store/history/historyActions"

const useGrouping = () => {
  const dispatch = useDispatch()
  const groupingIndex = useSelector(store =>
    store.history.grouping != null ? store.history.grouping : 1
  )

  const setGroupingIndex = index => {
    dispatch(setGrouping(index))
  }

  return { groupingIndex, setGroupingIndex }
}

export default useGrouping
