import { getPanelData } from "./getPanelData"
import { hasCoordinateVisualization } from "./coordinateVisualization"
import { emptyImage } from "../../../utils"

const hasCurrentChoiceVisualizationAsset = panelData => {
  return (
    panelData &&
    panelData.current_choice &&
    panelData.current_choice.visualization &&
    panelData.current_choice.visualization.type === "asset"
  )
}

const hasCurrentChoiceVisualizationAssets = panelData => {
  return (
    panelData &&
    panelData.current_choice &&
    panelData.current_choice.visualization &&
    panelData.current_choice.visualization.type === "assets"
  )
}

export const getButtonImgExists = (data, panelKey) => {
  const panelData = getPanelData(data, panelKey)
  let result =
    hasCurrentChoiceVisualizationAsset(panelData) ||
    hasCurrentChoiceVisualizationAssets(panelData)

  if (hasCoordinateVisualization(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    if (coordinateVisualization.url) {
      result = coordinateVisualization.url != null
    }
    if (coordinateVisualization.urls) {
      result = coordinateVisualization.urls[0] != null
    }
  }

  return result
}

export const getButtonImg = (data, panelKey) => {
  const panelData = getPanelData(data, panelKey)
  let result = emptyImage
  if (hasCurrentChoiceVisualizationAsset(panelData)) {
    result = panelData.current_choice.visualization.url
  }
  if (hasCurrentChoiceVisualizationAssets(panelData)) {
    result = panelData.current_choice.visualization.urls[0]
  }
  if (hasCoordinateVisualization(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    if (coordinateVisualization.url) {
      result = coordinateVisualization.url
    }
    if (coordinateVisualization.urls) {
      result = coordinateVisualization.urls[0]
    }
  }
  return result
}

export const getButtonImgs = (data, panelKey) => {
  const panelData = getPanelData(data, panelKey)
  let result
  if (hasCurrentChoiceVisualizationAssets(panelData)) {
    result = panelData.current_choice.visualization.urls[0]
  }
  if (hasCoordinateVisualization(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    if (coordinateVisualization.urls) {
      result = coordinateVisualization.urls
    }
  }
  return result
}
