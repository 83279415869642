import React from "react"
import classNames from "classnames"

import * as styles from "./filterLabel.module.css"

export const FilterLabel = ({ name, caption, hideOnMobile = false }) => {
  return (
    <div
      className={classNames(styles.filterLabel, {
        [styles.hideOnMobile]: hideOnMobile,
      })}
    >
      {name && !caption && <div className={styles.filterValue}>{name}</div>}
      {((!name && caption) || name === caption) && (
        <div className={styles.filterValue}>{caption}</div>
      )}
      {name && caption && name !== caption && (
        <>
          <div className={styles.filterName}>{name}</div>
          <div className={styles.filterValue}>{caption}</div>
        </>
      )}
    </div>
  )
}
