// extracted by mini-css-extract-plugin
export var cDoubleFooter = "dialog-module--cDoubleFooter--hzuu3";
export var cNoPadding = "dialog-module--cNoPadding--+2UCQ";
export var dialog = "dialog-module--dialog--dZycr";
export var dialogBody = "dialog-module--dialogBody--OMuK9";
export var dialogHead = "dialog-module--dialogHead--DeQmK";
export var dialogOverlay = "dialog-module--dialogOverlay--V6M+v";
export var icon = "dialog-module--icon--0U6SM";
export var mobileDialogFooter = "dialog-module--mobileDialogFooter--qKc6U";
export var mobileDialogHead = "dialog-module--mobileDialogHead--vPVJB";
export var mobileHeaderButton = "dialog-module--mobileHeaderButton--MdN1l";
export var radioButtons = "dialog-module--radio-buttons--xwDzC";