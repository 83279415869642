export const filterPanelsActionTypes = {
  FILTER_PANELS_FETCH_REQUEST: "FILTER_PANELS_FETCH_REQUEST",
  FILTER_PANELS_FETCH_SUCCESS: "FILTER_PANELS_FETCH_SUCCESS",
  FILTER_PANELS_FETCH_FAILURE: "FILTER_PANELS_FETCH_FAILURE",
}

export const fetchFilterPanelsRequest = payload => ({
  type: filterPanelsActionTypes.FILTER_PANELS_FETCH_REQUEST,
  payload,
})

export const fetchFilterPanelsSuccess = payload => ({
  type: filterPanelsActionTypes.FILTER_PANELS_FETCH_SUCCESS,
  payload,
})

export const fetchFilterPanelsFailure = payload => ({
  type: filterPanelsActionTypes.FILTER_PANELS_FETCH_FAILURE,
  payload,
})
