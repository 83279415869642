import { ofType } from "redux-observable"
import {
  productDetailsActionTypes,
  fetchProductDetailsRequest,
  fetchProductDetailsSuccess,
  fetchProductDetailsFailure,
} from "./index"
import {
  catchError,
  delay,
  map,
  retryWhen,
  scan,
  switchMap,
} from "rxjs/operators"
import { from, of } from "rxjs"

export const getProductDetailsEpic = (action$, store$, { axiosClient }) =>
  action$.pipe(
    ofType(productDetailsActionTypes.PRODUCT_DETAILS_FETCH_REQUEST),
    switchMap(action => {
      const url =
        action.payload && action.payload.url ? action.payload.url : "#"
      const requestProductId =
        action.payload && action.payload.productId
          ? action.payload.productId
          : ""

      return from(
        axiosClient.send({
          method: "get",
          url: url,
        })
      ).pipe(
        retryWhen(errors => {
          return errors.pipe(
            scan((acc, err) => {
              if (acc >= 3) {
                throw new Error("Network error")
              }

              return acc + 1
            }, 0),
            delay(250)
          )
        }),
        map(response =>
          fetchProductDetailsSuccess({
            [requestProductId]: { ...response },
          })
        ),
        catchError(error => {
          console.error("Fetch timeout", { error })

          return of(
            fetchProductDetailsFailure({
              productId: requestProductId,
              error,
            })
          )
        })
      )
    })
  )
