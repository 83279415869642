import { initialState } from "../store/history/historyReducer"

export class historyWrapper {
  static verifyState() {
    if (!(typeof window === "object" && window.history && window.history.state))
      return initialState.state
    var newState = JSON.parse(JSON.stringify(initialState.state))

    newState.key =
      typeof window.history.state.key !== "undefined"
        ? window.history.state.key
        : initialState.state.key
    newState.openPanel =
      typeof window.history.state.openPanel !== "undefined"
        ? window.history.state.openPanel
        : null
    newState.openDialog =
      typeof window.history.state.openDialog !== "undefined"
        ? window.history.state.openDialog
        : null
    newState.openDropdown =
      typeof window.history.state.openDropdown !== "undefined"
        ? window.history.state.openDropdown
        : null
    newState.origin =
      typeof window.history.state.origin !== "undefined"
        ? window.history.state.origin
        : null
    newState.level =
      typeof window.history.state.level !== "undefined"
        ? window.history.state.level
        : 0
    newState.distance =
      typeof window.history.state.distance !== "undefined"
        ? window.history.state.distance
        : 0

    if (JSON.stringify(newState) !== JSON.stringify(window.history.state)) {
      historyWrapper.replaceState(newState)
    }

    return newState
  }

  static get state() {
    if (!(typeof window === "object" && window.history && window.history.state))
      return initialState.state
    return window.history.state
  }

  static get location() {
    if (!(typeof window === "object" && window.history && window.history.state))
      return {}

    const currentLocation = {
      href: window.location.href,
      pathname: window.location.pathname,
      search: window.location.search,
    }
    return currentLocation
  }

  static pushState(state, title = "", url) {
    window.history.pushState(state, title, url)
  }

  static replaceState(state, title = "", url) {
    window.history.replaceState(state, title, url)
  }

  static go(steps) {
    window.history.go(steps)
  }
}
