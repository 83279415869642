import {
  arrayOrderAtoZ,
  arrayIncludeStringsStartingWith,
} from "../../../utils/arrayUtils"
import { panelOrder } from "../../../utils/constants"

export const getOrderedPanelNames = data => {
  const panelsToOrder = arrayIncludeStringsStartingWith(data, panelOrder)
  var orderedPanels = []
  panelOrder.map(nameFromOrderList => {
    const array = arrayOrderAtoZ(
      arrayIncludeStringsStartingWith(panelsToOrder, [nameFromOrderList])
    )
    array.map(panelName => {
      orderedPanels.push(panelName)
      return false
    })
    return false
  })
  return orderedPanels
}
