import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { initialState } from "../store/history/historyReducer"
import { decreaseLevel, saveHistory } from "../store/history/historyActions"
import { historyWrapper } from "../utils/historyWrapper"
import {
  hrefToPosition,
  positionToLocation,
  getPathnameString,
} from "../utils/positionUtils"
import { panelKeys, dialogNames } from "../utils/constants"
import { SafeLinkObj } from "./index"

import * as styles from "./safeLink.module.css"

export const SafeLink = ({
  to,
  replace,
  className,
  action,
  children,
  actionPayload,
  historyFromRedux,
  dispatch,
  style,
}) => {
  const safeLinkObj = new SafeLinkObj(
    action,
    actionPayload,
    historyFromRedux.state,
    historyWrapper.location,
    to
  )
  const originPosition = hrefToPosition(historyFromRedux.state.origin)

  const handleClick = (event, action, to) => {
    return
  }

  return (
    <>
      <Link
        to={safeLinkObj.to}
        state={safeLinkObj.newState}
        replace={false}
        className={classNames(styles.safeLink, className)}
        onClick={event => {
          handleClick(event, action, to)
        }}
        style={style}
      >
        {children}
      </Link>
    </>
  )
}

const mapStateToProps = store => ({
  historyFromRedux: store.history,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export const SafeLinkRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SafeLink)
