import React from "react"

export const SelectingValueIcon = () => {
  return (
    <div
      style={{
        width: "48px",
        height: "48px",
        borderRadius: "24px",
        backgroundColor: "var(--clr-grey-l2)",
        letterSpacing: "-1px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          color: "var(--clr-grey-d1)",
          fontSize: "32px",
          fontWeight: "400",
        }}
      >
        ...
      </div>
    </div>
  )
}
