import React from "react"
import classNames from "classnames"

import * as labelStyles from "../../../styles/labelStyles.module.css"

export const PanelLabelMain = ({ children, centered = false }) => (
  <h3
    className={classNames(
      labelStyles.panelLabel,
      labelStyles.noWrap,
      labelStyles.main,
      { [labelStyles.centered]: centered }
    )}
  >
    {children}
  </h3>
)

// className = {classNames(styles.panelLabel, styles.main)}
