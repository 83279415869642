export const company = process.env.GATSBY_COMPANY ? process.env.GATSBY_COMPANY : "DEFAULT"
const settings = {
  DEFAULT: {
    appName: "Default",
    favicon: "/gasefi_favicon.ico",
  },
  GASEFI: {
    appName: "Gasefi",
    favicon: "/gasefi_favicon.ico",
  },
  STYLOTECA: {
    appName: "Styloteca",
    favicon: "/styloteca_favicon.ico",
  }
}

export const appName = settings[company].appName || settings["DEFAULT"].appName
export const favicon = settings[company].favicon || settings["DEFAULT"].favicon