import { HeapObj } from "./heapObj"
import { ProductObj } from "./productObj"
import { heapSets, groupNames, sortingNames } from "./constants"

const defaultGroup = groupNames.SHAPE
const defaultSorting = sortingNames.NONE
const defaultColorOptions = []
const defaultHeaps = []
const defaultProducts = []
const defaultLoading = false

export class ResultsObj {
  constructor(data, grouping = defaultGroup, sorting = defaultSorting) {
    this._loading = defaultLoading
    this._loadingMore = defaultLoading
    this._count = 0
    this._products = defaultProducts
    this._heaps = defaultHeaps
    this._lastIndex = 0
    this._pageCount = 0
    this._grouping = grouping
    this._sorting = sorting

    if (this.isInputDataValid(data)) {
      if (data[this._grouping][this._sorting]) {
        this._count = data.productCount ? data.productCount : 0
        this._lastIndex = data[this._grouping][this._sorting].pageIndex
          ? data[this._grouping][this._sorting].pageIndex
          : 0
        this._pageCount = data[this._grouping][this._sorting].pageCount
          ? data[this._grouping][this._sorting].pageCount
          : 0
        this._loading =
          typeof data.isLoading !== "undefined" ? data.isLoading : false
        this._loadingMore =
          typeof data.loadingMore !== "undefined" ? data.loadingMore : false
        this._products = this.getProducts(
          data[this._grouping][this._sorting],
          this._grouping,
          this._sorting
        )
        this._heaps = this.getHeaps(data[this._grouping][this._sorting])
      }
    }
  }

  isInputDataValid = data => {
    if (typeof data === "undefined") return false
    if (data === null) return false
    if (Array.isArray(data)) return false
    return true
  }

  getColorOptions = (colorVariants, product) => {
    if (
      typeof colorVariants === "undefined" ||
      colorVariants === null ||
      !Array.isArray(colorVariants)
    )
      return defaultColorOptions
    var result = colorVariants.map((colorOption, optionIndex) => {
      return {
        id: colorOption.id,
        index: optionIndex,
        productImage: colorOption.large_image,
        productThumbnail: colorOption.thumbnail,
        caption: colorOption.caption,
        colorCode:
          colorOption.colors && Array.isArray(colorOption.colors)
            ? colorOption.colors[0]
            : null,
        colorCodes:
          colorOption.colors && Array.isArray(colorOption.colors)
            ? colorOption.colors
            : null,
        colorImage:
          colorOption.visualization && colorOption.visualization.url
            ? colorOption.visualization.url
            : "#",
        isSelected: colorOption.selected ? colorOption.selected : false,
        price: colorOption.price / 100,
      }
    })
    const selectedArray = result.filter(color => color.isSelected === true)
    if (selectedArray.length === 0) {
      result[0].isSelected = true
    }
    return result
  }

  getProducts = (data, grouping, sorting) => {
    if (!data.products) return defaultProducts

    const result = data.products.map((productFromAPI, productIndex) => ({
      id: productFromAPI.id,
      index: productIndex,
      brand: productFromAPI.brand ? productFromAPI.brand : "",
      name: productFromAPI.name ? productFromAPI.name : "",
      image: productFromAPI.visuals[0].large_image,
      thumbnail: productFromAPI.visuals[0].thumbnail,
      price: productFromAPI.price / 100,
      grouping: grouping,
      sorting: sorting,
      colorOptions: this.getColorOptions(
        productFromAPI.visuals,
        productFromAPI
      ),
    }))
    return result
  }

  getHeaps = data => {
    if (!data.heaps) return defaultHeaps
    return data.heaps.map((heapFromAPI, index) => {
      return {
        id: heapFromAPI.id,
        caption: heapFromAPI.caption,
        icon: heapFromAPI.icon,
        images: heapFromAPI.images,
        delta: heapFromAPI.mutation,
        count: heapFromAPI.count,
        heapSet: heapSets[index % heapSets.length],
      }
    })
  }

  set loading(value) {
    this._loading = value
  }

  set loadingMore(value) {
    this._loadingMore = value
  }

  get loading() {
    return this._loading
  }

  get loadingMore() {
    return this._loadingMore
  }

  get products() {
    if (!this._products) return defaultProducts
    return this._products.map(product => new ProductObj(product))
  }

  get heaps() {
    if (!this._heaps) return defaultHeaps
    return this._heaps.map(heap => new HeapObj(heap))
  }

  get count() {
    return this._count
  }

  get grouping() {
    return this._grouping
  }

  get sorting() {
    return this._sorting
  }

  _getIndexFromId = productId => {
    if (typeof productId === "undefined" || productId === null) return -1
    return this._products.findIndex(product => product.id === productId)
  }

  _getProductByIndex = index => {
    if (index >= 0 && index < this._products.length)
      return new ProductObj(this._products[index])
    return null
  }

  getProduct = productId => {
    return this._getProductByIndex(this._getIndexFromId(productId))
  }

  getPreviousProduct = productId => {
    return this._getProductByIndex(this._getIndexFromId(productId) - 1)
  }

  getNextProduct = productId => {
    return this._getProductByIndex(this._getIndexFromId(productId) + 1)
  }

  get hasMorePages() {
    return this._pageCount > this._lastIndex + 1
  }

  get pageIndexToFetch() {
    return this.hasMorePages ? this._lastIndex + 1 : -1
  }

  shouldChangeGrouping = groupingIndex => {
    return groupNames[Object.keys(groupNames)[groupingIndex]] !== this._grouping
  }

  shouldLoadMore = productId => {
    const productIndex = this._getIndexFromId(productId)
    if (productIndex > this._products.length - 5 && this.hasMorePages) {
      return true
    }
    return false
  }
}
