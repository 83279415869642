import React from "react"
import PropTypes from "prop-types"
import { SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"
import { getPathnameString } from "../../utils/helpers"
import { Icon } from "../index"
import { icons, iconsColors } from "../icon.utils"

import * as styles from "./safeButton.module.css"
import * as safeLinkStyles from "../safeLink.module.css"

export const PhotoUploadButton = ({ location, landing = false }) => (
  <SafeLink
    to={getPathnameString(location)}
    action={safeLinkActions.SHOW_UPLOADS}
    className={safeLinkStyles.noUnderline}
  >
    <Icon icon={icons.camera} color={iconsColors.BLACK} size={32} />
    {landing && <div className={styles.uploadButtonText}>By photo</div>}
  </SafeLink>
)

PhotoUploadButton.propTypes = {
  location: PropTypes.object.isRequired,
}
