import { hasCoordinateVisualization } from "./coordinateVisualization"

export const getTexts = (data, panelKey) => {
  let result
  if (hasCoordinateVisualization(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    if (coordinateVisualization.texts) {
      result = coordinateVisualization.texts
    }
  }
  return result
}

export const getText = (data, panelKey) => {
  let result
  if (hasCoordinateVisualization(data, panelKey)) {
    const coordinateVisualization = data.__coordinate_visualization__[panelKey]
    if (
      coordinateVisualization.type &&
      coordinateVisualization.type === "text"
    ) {
      result = coordinateVisualization.text
    }
  }
  return result
}
