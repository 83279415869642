import { productDetailsActionTypes } from "./productDetailsActions"
const initialState = {}

export const productDetailsReducer = (state = initialState, action = {}) => {
  let timestamp = Date.now()
  let currentState = JSON.parse(JSON.stringify(state))
  let productId

  switch (action.type) {
    case productDetailsActionTypes.PRODUCT_DETAILS_FETCH_REQUEST:
      productId = action.payload.productId
      return {
        ...state,
        [productId]: { ...state[productId], loading: true },
      }

    case productDetailsActionTypes.PRODUCT_DETAILS_FETCH_SUCCESS:
      productId = Object.keys(action.payload)[0]
      return {
        ...state,
        [productId]: { ...action.payload[productId], loading: false },
      }

    case productDetailsActionTypes.PRODUCT_DETAILS_FETCH_FAILURE:
      productId = action.payload.productId
      return {
        ...state,
        [productId]: {
          ...state[productId],
          error: action.payload.error,
          loading: false,
        },
      }

    default:
      return state
  }
}
