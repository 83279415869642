import React from "react"

import * as styles from "./container.module.css"

export const DialogTitle = ({ children }) => {
  return (
    <h2 data-testid="dialog-title" className={styles.dialogTitle}>
      {children}
    </h2>
  )
}
