export const historyActionTypes = {
  HISTORY_PUSH: "HISTORY_PUSH",
  HISTORY_SAVE: "HISTORY_SAVE",
  HISTORY_LEVEL_DECREASE: "HISTORY_LEVEL_DECREASE",
  HISTORY_IS_MOBILE_SET: "HISTORY_IS_MOBILE_SET",
  HISTORY_SORTING_SET: "HISTORY_SORTING_SET",
  HISTORY_GROUPING_SET: "HISTORY_GROUPING_SET",
  HISTORY_TOUCHABLE_SET: "HISTORY_TOUCHABLE_SET",
}

export const pushHistory = (timestamp = Date.now()) => ({
  type: historyActionTypes.HISTORY_PUSH,
  payload: {
    timestamp,
  },
})

export const saveHistory = (location, state) => ({
  type: historyActionTypes.HISTORY_SAVE,
  payload: {
    location,
    state,
  },
})

export const decreaseLevel = level => ({
  type: historyActionTypes.HISTORY_LEVEL_DECREASE,
  payload: {
    level,
  },
})

export const setIsMobile = isMobile => ({
  type: historyActionTypes.HISTORY_IS_MOBILE_SET,
  payload: {
    isMobile,
  },
})

export const setSorting = sorting => ({
  type: historyActionTypes.HISTORY_SORTING_SET,
  payload: {
    sorting,
  },
})

export const setGrouping = grouping => ({
  type: historyActionTypes.HISTORY_GROUPING_SET,
  payload: {
    grouping,
  },
})

export const setTouchable = touchable => ({
  type: historyActionTypes.HISTORY_TOUCHABLE_SET,
  payload: {
    touchable,
  },
})
