import React from "react"
import { useDispatch } from "react-redux"
import useLocale from "../../hooks/useLocale"
import VendorBox from "./VendorBox"
import PropTypes from "prop-types"
import { Loader } from "../../components/index"

import {
  ColorIndicator,
  labels,
  selectColor,
  indicatorPlacementOptions,
} from "../../components/index"

import * as styles from "./productCardExpanded.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"
import { cColorOptionContainer } from "./productCardExpanded.module.css"

export const ProductCardExpanded = ({ product, position, detailsLoading }) => {
  const showProductId =
    window && window.gsfTesting && window.gsfTesting.showProductId
  const showVisualId =
    window && window.gsfTesting && window.gsfTesting.showVisualId

  const dispatch = useDispatch()
  const locale = useLocale()

  if (!product) return null

  const handleClick = colorIndex => {
    dispatch(
      selectColor({
        position: position,
        productIndex: product.index,
        colorIndex: colorIndex,
        grouping: product.grouping,
        sorting: product.sorting,
      })
    )
  }

  const selectedOptionIndex = product.colorOptions.findIndex(
    option => option.isSelected
  )
  const visualId =
    product.colorOptions[selectedOptionIndex >= 0 ? selectedOptionIndex : 0].id

  const colorOptions = product.colorOptions.map((colorOption, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          handleClick(colorOption.index)
        }}
        style={{ cursor: "pointer" }}
      >
        <ColorIndicator
          selection={colorOption}
          indicatorPlacement={indicatorPlacementOptions.EXPANDED_CARD}
        />
      </div>
    )
  })

  return (
    <div
      data-testid="product-card-expanded"
      className={styles.productCardExpanded}
    >
      <div className={styles.imageContainer}>
        <img src={product.image} alt={product.name} />
        {showProductId || showVisualId ? (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              backgroundColor: "white",
              color: "#aaa",
              padding: "4px",
            }}
          >
            {showProductId ? <p>{"product ID: " + product.id}</p> : null}
            {showVisualId ? <p>{"visual ID: " + visualId}</p> : null}
          </div>
        ) : null}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.colorContainer}>
          <div className={labelStyles.productCaption}>
            {labels[locale].COLOR_OPTIONS}
          </div>
          <div className={styles.colorOptions}>
            <div className={cColorOptionContainer}>{colorOptions}</div>
          </div>
        </div>
        <div className={styles.colorContainer}>
          <div className={labelStyles.productCaption}>
            {labels[locale].VENDORS}
          </div>
          {detailsLoading ? (
            <Loader small />
          ) : (
            <>
              {product &&
              product.selectedOption &&
              product.selectedOption.storeIds &&
              product.selectedOption.storeIds.length > 0 ? (
                <>
                  {product.selectedOption.storeIds.map(storeId => (
                    <VendorBox
                      key={storeId}
                      id={storeId}
                      store={product.selectedOption.storeEntities[storeId]}
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ProductCardExpanded.propTypes = {
  product: PropTypes.object,
}

/*
          {detailsLoading ? (
            <Loader />
          ) : (
            <>
              {product &&
              product.selectedOption &&
              product.selectedOption.storeIds &&
              product.selectedOption.storeIds.length > 0 ? (
                <>
                  {product.selectedOption.storeIds.map(storeId => (
                    <VendorBox
                      key={storeId}
                      id={storeId}
                      store={product.selectedOption.storeEntities[storeId]}
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
*/
