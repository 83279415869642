import React from "react"
import classNames from "classnames"
import { Checkbox, Spacer } from "./index.js"

import * as styles from "./basicComponent.module.css"

export const ListItem = ({ isSelected, caption, count }) => {
  return (
    <li
      data-testid="list-item-component"
      className={classNames(styles.listItem, { [styles.disabled]: count <= 0 })}
    >
      <Checkbox checked={isSelected} />
      <Spacer width={"20px"} />
      <div
        className={classNames(styles.listItemCaption, {
          [styles.selected]: isSelected,
        })}
      >
        {caption}
      </div>
      <div style={{ flexGrow: "1" }} />
      <div className={styles.listItemCount}>{count}</div>
    </li>
  )
}
