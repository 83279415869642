import React from "react"
import classNames from "classnames"
import useLocale from "../../hooks/useLocale"

import { labels, Image } from "../index"

import {
  cUploadItem,
  cUploadSelectionIndicator,
  cUploadIndicator,
  cTimestampContainer,
  cUnseenMarker,
  cMobile,
  cSelected,
  cProcessing,
} from "./uploadItem.module.css"

export const UploadItem = ({ item, selected, setSelected, isMobile }) => {
  const locale = useLocale()
  const diff = Date.now() - item.uploaded
  var timestampLabel = labels[locale].UPLOAD_JUST_NOW
  if (Math.floor(diff / (365 * 24 * 60 * 60 * 1000)) > 0)
    timestampLabel = labels[locale].UPLOAD_YEARS_AGO.replace(
      "%VALUE%",
      Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
    )
  else if (Math.floor(diff / (30.42 * 24 * 60 * 60 * 1000)) > 0)
    timestampLabel = labels[locale].UPLOAD_MONTHS_AGO.replace(
      "%VALUE%",
      Math.floor(diff / (30.42 * 24 * 60 * 60 * 1000))
    )
  else if (Math.floor(diff / (7 * 24 * 60 * 60 * 1000)) > 0)
    timestampLabel = labels[locale].UPLOAD_WEEKS_AGO.replace(
      "%VALUE%",
      Math.floor(diff / (7 * 24 * 60 * 60 * 1000))
    )
  else if (Math.floor(diff / (24 * 60 * 60 * 1000)) > 0)
    timestampLabel = labels[locale].UPLOAD_DAYS_AGO.replace(
      "%VALUE%",
      Math.floor(diff / (24 * 60 * 60 * 1000))
    )
  else if (Math.floor(diff / (60 * 60 * 1000)) > 0)
    timestampLabel = labels[locale].UPLOAD_HOURS_AGO.replace(
      "%VALUE%",
      Math.floor(diff / (60 * 60 * 1000))
    )
  else if (Math.floor(diff / (60 * 1000)) > 0)
    timestampLabel = labels[locale].UPLOAD_MINUTES_AGO.replace(
      "%VALUE%",
      Math.floor(diff / (60 * 1000))
    )
  else if (Math.floor(diff / 1000) > 0)
    timestampLabel = labels[locale].UPLOAD_SECONDS_AGO.replace(
      "%VALUE%",
      Math.floor(diff / 1000)
    )

  return (
    <div
      className={classNames(cUploadItem, { [cMobile]: isMobile })}
      onClick={() => {
        setSelected(item.index)
      }}
    >
      <div
        className={classNames(
          cUploadSelectionIndicator,
          { [cSelected]: selected && !isMobile },
          { [cMobile]: isMobile }
        )}
      >
        <div
          className={classNames(
            cUploadIndicator,
            { [cSelected]: selected && !isMobile },
            { [cMobile]: isMobile },
            { [cProcessing]: !item.finished }
          )}
        >
          <Image
            src={item.productImage}
            alt={"Uploaded photo " + item.index}
            cover
          />
          <div className={cTimestampContainer}>{timestampLabel}</div>
        </div>
      </div>
      {item.finished && !item.isSeen && <div className={cUnseenMarker} />}
    </div>
  )
}
