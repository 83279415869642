import React from "react"
import PropTypes from "prop-types"
import { ariaRoles } from "../../utils/constants"
import {
  ChooseValueButton,
  SectionHeader,
  Grid,
  SizeFiltribute,
} from "../index.js"
import useNavigator from "../../hooks/useNavigator.js"
import useFilterPanels from "../../hooks/useFilterPanels.js"
import usePanel from "../../hooks/usePanel.js"
import * as selectorStyles from "./selector.module.css"

export const SizeSelector = () => {
  const { location, position } = useNavigator()
  const { filterPanels: filterObj } = useFilterPanels({ location })
  const { openPanel } = usePanel()
  const filterPanelObj = filterObj.getPanel(openPanel)
  const sizeButtons = filterPanelObj.filtributes.map((selection, index) => {
    return (
      <ChooseValueButton
        key={index}
        pathname={selection.getLinkUrl(position)}
        enabled={true}
      >
        <SizeFiltribute
          isSelected={selection.selected}
          caption={selection.text}
          count={selection.count}
        />
      </ChooseValueButton>
    )
  })

  return (
    <>
      <SectionHeader />
      <div className={selectorStyles.sectionBody} role={ariaRoles.LIST}>
        <Grid>{sizeButtons}</Grid>
      </div>
    </>
  )
}

SizeSelector.propTypes = {
  position: PropTypes.object.isRequired,
  filterPanelObj: PropTypes.object.isRequired,
}
