import React from "react"

function PriceIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <path
        d="M37.717 12.134 13.79 9.94a2.324 2.324 0 0 0-2.526 2.102l-1.247 13.596a2.324 2.324 0 0 0 2.102 2.526l23.926 2.194a2.324 2.324 0 0 0 2.526-2.102L39.82 14.66a2.324 2.324 0 0 0-2.102-2.526Z"
        fill="#7C9BB0"
      />
      <path
        d="m39.688 16.087-28.554-2.618-.329 3.581 28.554 2.618.329-3.581Z"
        fill="#648296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m39 23.597-2.725-7.883a2.342 2.342 0 0 0-2.979-1.449l-22.888 7.91-.075.027-.315 3.436a2.324 2.324 0 0 0 2.102 2.526l23.926 2.194a2.324 2.324 0 0 0 2.526-2.102l.427-4.66Z"
        fill="#000"
        fillOpacity={0.05}
      />
      <path
        d="m10.006 23.75 22.888-7.91a2.342 2.342 0 0 1 2.98 1.45l4.494 13.005a2.342 2.342 0 0 1-1.449 2.979l-22.888 7.91a2.342 2.342 0 0 1-2.979-1.448L8.557 26.73a2.342 2.342 0 0 1 1.449-2.98Z"
        fill="#93C1CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.47 16.333c-3.356 2.231-6.5 5.356-8.93 9.716-1.697 3.045-6.263 5.83-11.663 7.394l-2.32-6.713a2.342 2.342 0 0 1 1.449-2.98l21.464-7.417Z"
        fill="#71B5C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m33.103 30.51 4.591-1.588.294.85-4.591 1.586-.294-.849Zm-19.701-.47 3.79-1.31a1.468 1.468 0 0 1 1.867.907l.48 1.392a1.468 1.468 0 0 1-.907 1.867l-3.79 1.31a1.468 1.468 0 0 1-1.867-.908l-.481-1.392a1.468 1.468 0 0 1 .908-1.867Zm18.027 1.048-4.591 1.587.293.848 4.591-1.586-.293-.85Zm-10.855 3.751 4.59-1.587.294.85-4.591 1.586-.294-.849Zm-1.674.578-4.59 1.587.293.85 4.59-1.588-.293-.849Z"
        fill="#fff"
      />
    </svg>
  )
}

export { PriceIcon }
