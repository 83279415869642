const defaultLoading = false
const defaultUploads = []
const defaultUnseanCount = 0
const defaultDetectedProps = []

export class UploadsObj {
  constructor(data) {
    this._loading = defaultLoading
    this._uploads = this.getUploads(data)
    this._unseenCount = this.getUnseenCount(data)
  }

  getUploads = data => {
    if (typeof data === "undefined" || data === null) return defaultUploads
    if (Array.isArray(data.uploads)) {
      return data.uploads.map((upload, uploadIndex) => ({
        id: upload.id,
        index: uploadIndex,
        productImage: upload.image_url,
        isSeen: !upload.unseen,
        finished: upload.finished,
        uploaded: upload.uploaded,
        detectedProps: this.getDetectedProps(upload),
      }))
    }
  }

  getDetectedProps = data => {
    if (
      typeof data === "undefined" ||
      data === null ||
      typeof data.coord === "undefined"
    )
      return defaultDetectedProps
    const result = Object.keys(data.coord).map(propKey => {
      const truncatedCoord = data.coord[propKey]
        ? data.coord[propKey].map(singleCoord => singleCoord.name)
        : []
      return {
        prop: propKey,
        coord: truncatedCoord,
        caption: data.captions[propKey] ? data.captions[propKey] : "",
        image: data.visualization[propKey]
          ? data.visualization[propKey].url
          : "#",
      }
    })
    return result
  }

  getUnseenCount = data => {
    if (typeof data === "undefined" || data === null) return defaultUploads
    var result = 0
    if (Array.isArray(data.uploads)) {
      data.uploads.map((upload, uploadIndex) => {
        if (upload.unseen) result = result + 1
        return false
      })
    }
    return result
  }

  get showUnseenMarker() {
    return this._unseenCount > 0
  }

  get items() {
    return this._uploads ? this._uploads : []
  }
}
