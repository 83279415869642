import React from "react"

export const ToeIcon = props => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <circle cx={24} cy={24} fill="#7DB5C7" r={15} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.708 34.505A14.952 14.952 0 0 0 39 24c0-3.138-.964-6.05-2.61-8.459-2.194 1.044-5.523 2.164-8.995 3.332-4.951 1.665-10.196 3.43-12.86 5.213-.723.485-.619 3.317-.241 4.098 2.617 5.406 10.434 8.092 18.801 6.68a16.104 16.104 0 0 0 1.613-.36Z"
        fill="#7595AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.317 32.563A14.93 14.93 0 0 0 39 24c0-3.627-1.287-6.953-3.43-9.546-2.188 1.069-5.605 2.218-9.175 3.419-4.951 1.665-10.196 3.43-12.86 5.214-.723.484-.619 3.316-.241 4.097 2.617 5.406 10.434 8.092 18.801 6.68 1.46-.246 2.888-.707 4.222-1.301Z"
        fill="#5A7587"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4 28.212c.39-1.336.6-2.75.6-4.212 0-3.99-1.557-7.615-4.097-10.302-3.086 1.184-8.081 2.897-10.728 3.776-8.918 2.961-12.462 5.268-10.364 8.23 2.309 3.26 8.264 6.232 17.424 4.786 3.352-.53 5.571-1.334 7.166-2.278Z"
        fill="#7695A9"
      />
      <path
        d="M18.606 17.967C19.43 17.475 25.538 14 25.538 14L28 17.574 18.038 20s-.256-1.541.568-2.033Z"
        fill="#F27F23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4 28.212a15.004 15.004 0 0 0 .579-5.015c-.625-.778-3.623-4.377-7.879-7.347-4.8-3.35-6-2.35-6-2.35l-.008.005a.498.498 0 0 0-.021.009s-5.544 3.753-5.785 3.925c-.241.172-.241.31-.241.38.1-.023.195-.038.29-.046.411-.01 1.213.08 2.015.777 1.15 1 2.659 2.44 3.35 3.35.372.49.812 1.047 1.266 1.623.89 1.127 1.834 2.325 2.434 3.227.143.215.272.414.39.6.058.103.112.203.16.3.647 1.298.894 2.324.981 3.019.427-.05.862-.11 1.304-.179 3.352-.53 5.571-1.334 7.166-2.278Z"
        fill="#FDB046"
      />
    </svg>
  )
}
