import React from "react"
import { SelectingValueIcon } from "../../index"

export const BrandIndicator = ({ selection }) => {
  let selectionShort = null
  let fontSize = "18px"
  let overflow = false

  if (selection.caption && selection.caption !== "Brand") {
    selectionShort = selection.caption.slice(0, 1)
    if (selection.caption.split(", ").length > 1) {
      selectionShort =
        selectionShort + ", " + selection.caption.split(", ")[1].slice(0, 1)
      fontSize = fontSize = "14px"
      if (selection.caption.split(", ").length > 2) overflow = true
    }
  }

  if (selectionShort)
    return (
      <div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "var(--clr-grey-d1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            color: "#f6f6f6",
            fontSize: fontSize,
            lineHeight: "22px",
            fontWeight: "400",
          }}
        >
          {selectionShort}
        </div>
        {overflow ? (
          <div
            style={{
              color: "#f6f6f6",
              fontSize: fontSize,
              lineHeight: "2px",
            }}
          >
            ...
          </div>
        ) : null}
      </div>
    )

  return <SelectingValueIcon />
}
