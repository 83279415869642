// extracted by mini-css-extract-plugin
export var basicSwitch = "buttonStyles-module--basicSwitch--NLV0s";
export var carouselButtonBack = "buttonStyles-module--carouselButtonBack--6PqkY";
export var carouselButtonForward = "buttonStyles-module--carouselButtonForward--RISJo";
export var categoryUp = "buttonStyles-module--categoryUp--AQzFZ";
export var centered = "buttonStyles-module--centered--pyk2q";
export var hover = "buttonStyles-module--hover--LAFKb";
export var mobile = "buttonStyles-module--mobile--d7kWe";
export var off = "buttonStyles-module--off--SP1aP";
export var on = "buttonStyles-module--on--JCLJX";
export var removePanelButton = "buttonStyles-module--removePanelButton--57doN";
export var roundFloating = "buttonStyles-module--roundFloating--gIYMw";
export var roundFloatingControlledHover = "buttonStyles-module--roundFloatingControlledHover--zjKPy";
export var roundedFloating = "buttonStyles-module--roundedFloating--ozSR0";
export var switchIndicator = "buttonStyles-module--switchIndicator--+W4wc";
export var switchWithLabel = "buttonStyles-module--switchWithLabel--Kwwdi";