import {
  safeLinkActions,
  panelKeys,
  dialogNames,
  hrefToPosition,
  removeEmptyFromPosition,
  positionToLocation,
  getPathnameString,
} from "../components/index"

const initialState = {
  key: 0,
  level: 0,
  distance: 0,
  openPanel: null,
  openDialog: null,
  openDropdown: null,
  origin: null,
}

export class SafeLinkObj {
  constructor(action, actionPayload, state, location, to) {
    this._action = action
    this._actionPayload = actionPayload
    this._state = { ...initialState, ...state }
    this._location = location
    this._to = to
  }

  closePanel = state => ({ ...state, openPanel: null, level: 0 })
  closeDialog = state => ({ ...state, openDialog: null })
  closeDropdown = state => ({ ...state, openDropdown: null })
  closeAll = state => ({
    ...state,
    openPanel: null,
    openDialog: null,
    openDropdown: null,
    level: 0,
  })

  openPanel = (panelId, state, location) => {
    var result = JSON.parse(JSON.stringify(state))
    if (panelId) {
      result = this.closeDialog(this.closeDropdown(result))
      result.openPanel = panelId
      result.level = panelId === panelKeys.ADD_FILTER ? 1 : 2
      result.origin = location && location.href ? location.href : null
    }
    return result
  }

  openDialog = (dialogId, state) => {
    var result = this.closeDropdown(state)
    result.openDialog = dialogId
    return result
  }

  decreaseOneLevel = state => {
    const originPosition = hrefToPosition(state.origin)
    var result = this.closeDialog(this.closeDropdown(state))
    if (
      state.level === 2 &&
      typeof originPosition[state.openPanel] === "undefined"
    ) {
      result.openPanel = panelKeys.ADD_FILTER
      result.level = 1
    } else {
      result = this.closePanel(result)
    }
    return result
  }

  setDistance = (distance, state) => {
    var result = JSON.parse(JSON.stringify(state))
    result.distance = distance
    return result
  }

  increaseDistance = state => {
    var result = JSON.parse(JSON.stringify(state))
    result.distance = state.distance ? state.distance + 1 : 1
    return result
  }

  getNewState = (action, actionPayload, state, location) => {
    var result = JSON.parse(JSON.stringify(state))
    var panelId =
      actionPayload && actionPayload.panelId ? actionPayload.panelId : null
    switch (action) {
      case safeLinkActions.CHOOSE_VALUE: {
        result = this.increaseDistance(state)
        break
      }
      case safeLinkActions.TOGGLE_PANEL:
        if (state.openPanel !== panelId) {
          result = this.openPanel(panelId, state, location)
          result = this.setDistance(1, result)
        } else {
          result = this.closePanel(state)
        }
        break
      case safeLinkActions.TOGGLE_ADD_FILTER:
        result =
          state.openPanel === panelId
            ? this.closePanel(state)
            : this.openPanel(panelId, state, location)
        break
      case safeLinkActions.SHOW_PRODUCTS:
        result = this.closePanel(state)
        break
      case safeLinkActions.ADD_PANEL:
        result = this.openPanel(panelId, state, location)
        break
      case safeLinkActions.REMOVE_PANEL:
        if (state.openPanel === panelId)
          result = this.openPanel(panelKeys.ADD_FILTER, state, location)
        break
      case safeLinkActions.MORE_OPTIONS:
        result = this.openDialog(dialogNames.MORE_OPTIONS, state)
        break
      case safeLinkActions.SHOW_UPLOADS:
        result = this.openDialog(dialogNames.UPLOADS, state)
        break
      case safeLinkActions.CLOSE_DIALOG:
        result = this.closeDialog(state)
        break
      case safeLinkActions.BACK_ONE_LEVEL:
        result = this.decreaseOneLevel(state)
        break
      default:
        break
    }
    result.key = String(Date.now())
    return result
  }

  removeEmptyCoords = href => {
    return getPathnameString(
      positionToLocation(removeEmptyFromPosition(hrefToPosition(href)))
    )
  }

  get newState() {
    return this.getNewState(
      this._action,
      this._actionPayload,
      this._state,
      this._location
    )
  }

  get to() {
    if (
      this._action === safeLinkActions.TOGGLE_PANEL ||
      this._action === safeLinkActions.SHOW_PRODUCTS ||
      this._action === safeLinkActions.TOGGLE_ADD_FILTER
    )
      return this.removeEmptyCoords(this._to)
    return this._to
  }
}
