import { useState, useEffect } from "react"

const defaultDeploymentVersion = "132"
const defaultFilterPanelVersion = "0.9.0b80"
const defaultCaptionsVersion = "0.0.1b26"

const useVersioning = () => {
  const [testing, setTesting] = useState(false)
  const [deploymentVersion, setDeploymentVersion] = useState(
    defaultDeploymentVersion
  )
  const [filterPanelVersion, setFilterPanelVersion] = useState(
    defaultFilterPanelVersion
  )
  const [captionsVersion, setCaptionsVersion] = useState(defaultCaptionsVersion)

  useEffect(() => {
    if (window && window.gsfTesting) {
      if (window.gsfTesting.testing) {
        setTesting(true)
      }
      if (window.gsfTesting.deploymentId) {
        setDeploymentVersion(window.gsfTesting.deploymentId)
      }
      if (window.gsfTesting.filterPanels) {
        setFilterPanelVersion(window.gsfTesting.filterPanels)
      }
      if (window.gsfTesting.captions) {
        setCaptionsVersion(window.gsfTesting.captions)
      }
    }
  }, [])

  return {
    testing,
    deploymentVersion,
    filterPanelVersion,
    captionsVersion,
  }
}

export default useVersioning
