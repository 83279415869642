import React from "react"
export const EmptyIcon = props => {
  return (
    <svg
      width={50}
      height={50}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    ></svg>
  )
}
