import { ofType } from "redux-observable"
import {
  fetchPhotoSearchSuccess,
  fetchPhotoSearchFailure,
  postPhotoSearchSuccess,
  postPhotoSearchFailure,
  photoSearchActionTypes,
} from "./index"
import {
  catchError,
  delay,
  map,
  retryWhen,
  scan,
  switchMap,
} from "rxjs/operators"
import { from, of } from "rxjs"
import { serializePosition } from "../../utils/positionUtils"

const mockUrl = "https://run.mocky.io/v3/2597e1c3-e818-4458-b7ea-73fd4b5ded56"

export const getPhotoSearchEpic = (action$, store$, { axiosClient }) =>
  action$.pipe(
    ofType(photoSearchActionTypes.PHOTO_SEARCH_FETCH_REQUEST),
    switchMap(action => {
      const url =
        action.payload && action.payload.url ? action.payload.url : mockUrl
      return from(
        axiosClient.send({
          method: "get",
          url: url,
        })
      ).pipe(
        retryWhen(errors => {
          return errors.pipe(
            scan((acc, err) => {
              if (acc >= 3) {
                throw new Error("Network error")
              }

              return acc + 1
            }, 0),
            delay(250)
          )
        }),
        map(response =>
          fetchPhotoSearchSuccess({
            ...response,
          })
        ),
        catchError(error => {
          console.error("Fetch timeout", { error })

          return of(
            fetchPhotoSearchFailure({
              error,
            })
          )
        })
      )
    })
  )

export const postPhotoSearchEpic = (action$, store$, { axiosClient }) =>
  action$.pipe(
    ofType(photoSearchActionTypes.PHOTO_SEARCH_POST_REQUEST),
    switchMap(action => {
      const url =
        action.payload && action.payload.url ? action.payload.url : mockUrl
      const body =
        action.payload && action.payload.body ? action.payload.body : undefined
      return from(
        axiosClient.send({
          method: "post",
          url: url,
          body: body,
        })
      ).pipe(
        retryWhen(errors => {
          return errors.pipe(
            scan((acc, err) => {
              if (acc >= 3) {
                throw new Error("Network error")
              }

              return acc + 1
            }, 0),
            delay(250)
          )
        }),
        map(response =>
          postPhotoSearchSuccess({
            ...response,
          })
        ),
        catchError(error => {
          console.error("Fetch timeout", { error })

          return of(
            postPhotoSearchFailure({
              error,
            })
          )
        })
      )
    })
  )
