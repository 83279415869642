import {
  arrayOrderAtoZ,
  arrayExcludeStringsStartingWith,
  arrayIncludeStringsStartingWith,
} from "../../../utils/arrayUtils"
import { panelOrder } from "../../../utils/constants"
import { getPanelNames } from "./getPanelNames"

export const getOrderedPanels = data => {
  const restPanels = arrayOrderAtoZ(
    arrayExcludeStringsStartingWith(Object.keys(data), panelOrder)
  )
  const panelsToOrder = arrayIncludeStringsStartingWith(
    getPanelNames(data),
    panelOrder
  )
  var orderedPanels = {}
  panelOrder.map(nameFromOrderList => {
    const array = arrayOrderAtoZ(
      arrayIncludeStringsStartingWith(panelsToOrder, [nameFromOrderList])
    )
    array.map(panelName => {
      orderedPanels[panelName] = data[panelName]
      return false
    })
    return false
  })
  restPanels.map(key => {
    orderedPanels[key] = data[key]
    return false
  })
  return orderedPanels
}
