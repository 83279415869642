import { useSelector } from "react-redux"

const usePanel = () => {
  const openPanel = useSelector(store =>
    store.history && store.history.state ? store.history.state.openPanel : null
  )

  return { openPanel, isPanelOpen: openPanel != null }
}

export default usePanel
