import React from "react"
import { Icon, icons, iconsColors } from "../../index"

export const AddFilterIcon = ({ isSelected }) => {
  return (
    <Icon
      icon={icons.filter}
      color={isSelected ? iconsColors.GREEN : iconsColors.WHITE}
      size={36}
    />
  )
}
