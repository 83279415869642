import React from "react"
import PropTypes from "prop-types"
import { Icon, icons, SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"
import * as buttonStyles from "../../styles/buttonStyles.module.css"

export const ClosePanelButton = ({ to }) => (
  <SafeLink to={to} action={safeLinkActions.SHOW_PRODUCTS}>
    <div className={buttonStyles.roundFloating}>
      <Icon icon={icons.close} />
    </div>
  </SafeLink>
)

ClosePanelButton.propTypes = {
  to: PropTypes.string.isRequired,
}
