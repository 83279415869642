// extracted by mini-css-extract-plugin
export var cColorOptionsContainer = "productCard-module--cColorOptionsContainer--UasxJ";
export var cLinkContainer = "productCard-module--cLinkContainer--f-ZHx";
export var colorIndicator = "productCard-module--colorIndicator--K7B7T";
export var colorOption = "productCard-module--colorOption--8J-wA";
export var colorOptions = "productCard-module--colorOptions--I2kSh";
export var colorSelectionIndicator = "productCard-module--colorSelectionIndicator--n9kIf";
export var hoverBtnConainer = "productCard-module--hoverBtnConainer--yt3wq";
export var hoverable = "productCard-module--hoverable--MLF2B";
export var imageContainer = "productCard-module--imageContainer--6K+qT";
export var infoContainer = "productCard-module--infoContainer--Wtd1h";
export var loaderCard = "productCard-module--loaderCard--I74Cf";
export var loading = "productCard-module--loading--yBCuA";
export var moreOptionsPopup = "productCard-module--moreOptionsPopup--Jm51o";
export var priceInfo = "productCard-module--priceInfo--92Ael";
export var productCard = "productCard-module--productCard--R7hhG";
export var selected = "productCard-module--selected--12VV3";
export var shine = "productCard-module--shine--7SNOO";