import { ofType } from "redux-observable"
import {
  fetchHeapViewSuccess,
  fetchHeapViewFailure,
  fetchProductDetailsSuccess,
  fetchProductDetailsFailure,
  heapViewActionTypes,
} from "./heapViewByPositionActions"
import {
  catchError,
  delay,
  map,
  retryWhen,
  scan,
  switchMap,
} from "rxjs/operators"
import { from, of } from "rxjs"
import { serializePosition, dirFromPosition } from "../../utils/positionUtils"
import { storeReducers, storeReducerVersions } from "../../utils/constants"

export const getHeapViewByPositionEpic = (action$, store$, { axiosClient }) =>
  action$.pipe(
    ofType(
      heapViewActionTypes.HEAP_VIEW_FETCH_REQUEST,
      heapViewActionTypes.HEAP_VIEW_REFETCH_REQUEST
    ),
    switchMap(action => {
      const requestPage = action.payload.pageIndex
        ? action.payload.pageIndex
        : 0
      const requestPosition = action.payload.position
      const requestGrouping = action.payload.grouping
      const requestSorting = action.payload.sorting
      const deploymentVersion =
        parseInt(action.payload.deploymentVersion) >= 0
          ? action.payload.deploymentVersion
          : 0
      const filterPanelVersion = action.payload.filterPanelVersion
        ? action.payload.filterPanelVersion
        : "0.0.0"

      const requestBody = {
        coord: requestPosition,
        filter_panels: filterPanelVersion,
        page_no: requestPage,
        order: requestSorting,
        deployment_id: deploymentVersion,
      }

      return from(
        axiosClient.send({
          method: "post",
          url: "filipa/tools/produce_page",
          body: requestBody,
          authenticated: true,
        })
      ).pipe(
        retryWhen(errors => {
          return errors.pipe(
            scan((acc, err) => {
              if (acc >= 3) {
                throw new Error("Network error | " + err.message)
              }

              return acc + 1
            }, 0),
            delay(250)
          )
        }),
        map(response =>
          fetchHeapViewSuccess({
            ...response,
            __position: serializePosition(requestPosition),
            __pageIndex: requestPage,
            __grouping: requestGrouping,
            __sorting: requestSorting,
            __version:
              storeReducerVersions[storeReducers.HEAP_VIEW_BY_POSITION],
            __deploymentVersion: deploymentVersion,
          })
        ),
        catchError(error => {
          console.error("Fetch timeout", { error })
          return of(
            fetchHeapViewFailure({
              __position: serializePosition(requestPosition),
              __pageIndex: requestPage,
              __grouping: requestGrouping,
              __sorting: requestSorting,
              __version:
                storeReducerVersions[storeReducers.HEAP_VIEW_BY_POSITION],
              __deploymentVersion: deploymentVersion,
              error,
            })
          )
        })
      )
    })
  )
