import React, { useState } from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import PropTypes from "prop-types"

import {
  selectColor,
  MoreOptionsButton,
  Loader,
  locationToPosition,
  SafeLink,
  safeLinkActions,
} from "../index"
import useIsTouchable from "../../hooks/useIsTouchable"

import * as styles from "./productCard.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"
import * as safeLinkStyles from "../safeLink.module.css"
import {
  cLinkContainer,
  cColorOptionsContainer,
} from "./productCard.module.css"

const batchWindowSize = 6

export const ProductCard = ({
  isLivePage,
  product,
  location,
  lastLoadedIndex,
  setLoadedIndex,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const isTouchScreen = useIsTouchable()

  const dispatch = useDispatch()
  const handleImageLoaded = () => {
    setImageLoaded(true)
    setLoadedIndex(product.index)
  }

  const handleClick = colorIndex => {
    dispatch(
      selectColor({
        position: locationToPosition(location),
        productIndex: product.index,
        colorIndex: colorIndex,
        grouping: product.grouping,
        sorting: product.sorting,
      })
    )
  }

  const selectedIndex = product.colorOptions
    ? product.colorOptions.findIndex(colorOption => colorOption.isSelected)
    : -1

  const productImage = (
    <div
      className={classNames(styles.imageContainer, {
        [styles.loading]: isLivePage && !imageLoaded,
      })}
    >
      {product.index <= lastLoadedIndex + batchWindowSize && (
        <img
          src={
            selectedIndex > -1
              ? product.colorOptions[selectedIndex].productThumbnail
              : product.thumbnail
          }
          alt={product.name}
          onLoad={handleImageLoaded}
          key={
            Array.isArray(product.colorOptions) &&
            product.colorOptions.length > 0
              ? product.colorOptions[selectedIndex].id
              : product.id
          }
        />
      )}
    </div>
  )

  if (product.id === "_dummy") {
    return (
      <div className={classNames(styles.productCard, styles.loaderCard)}>
        <Loader />
      </div>
    )
  }

  var price = product.price

  const colorOptions =
    product.colorOptions.length > 0
      ? product.colorOptions.map((colorOption, index) => {
          if (colorOption.isSelected)
            price = colorOption.price ? colorOption.price : price

          var backgroundStyle = {
            width: "100%",
            height: "100%",
          }
          if (Array.isArray(colorOption.colorCodes)) {
            if (colorOption.colorCodes.length === 1) {
              backgroundStyle.backgroundColor = colorOption.colorCodes[0]
            }
            if (colorOption.colorCodes.length > 1) {
              const numberOfColors = colorOption.colorCodes.length
              var backgroundString = "linear-gradient(90deg "
              colorOption.colorCodes.map((colorCode, colorCodeIndex) => {
                backgroundString =
                  backgroundString +
                  ", " +
                  colorCode +
                  " " +
                  (100 / numberOfColors) * colorCodeIndex +
                  "%"
                backgroundString =
                  backgroundString +
                  ", " +
                  colorCode +
                  " " +
                  (100 / numberOfColors) * (colorCodeIndex + 1) +
                  "%"
              })
              backgroundString = backgroundString + ")"
              backgroundStyle.background = backgroundString
            }
          } else if (colorOption.colorCode) {
            backgroundStyle.backgroundColor = colorOption.colorCode
          }

          return (
            <div key={index} className={styles.colorOption}>
              <div
                className={classNames(styles.colorSelectionIndicator, {
                  [styles.selected]: colorOption.isSelected,
                })}
              >
                <div
                  className={classNames(styles.colorIndicator, {
                    [styles.selected]: colorOption.isSelected,
                  })}
                  onClick={() => {
                    handleClick(colorOption.index)
                  }}
                >
                  {colorOption.imageUrl !== "#" ? (
                    <img src={colorOption.imageUrl} alt={colorOption.caption} />
                  ) : colorOption.colorCode !== null ? (
                    <div style={backgroundStyle} />
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </div>
          )
        })
      : null

  const touchCard = (
    <div className={classNames(styles.productCard, styles.hoverable)}>
      {productImage}
      <div className={styles.infoContainer}>
        <div className={styles.colorOptions}>
          <div className={cColorOptionsContainer}>{colorOptions}</div>
        </div>
        <h4 className={labelStyles.productCaption}>
          {product.brand + (product.name ? " " + product.name : "")}
        </h4>
        <p
          style={{
            margin: "0",
            position: "absolute",
            bottom: "10px",
            right: "10px",
            fontSize: "var( --font-size-l)",
            fontWeight: "var(--font-weight-light)",
          }}
        >
          {"$" + price}
        </p>
      </div>
      <div className={cLinkContainer}>
        <SafeLink
          to={product.getDetailsUrl(location)}
          action={safeLinkActions.MORE_OPTIONS}
          className={safeLinkStyles.noUnderline}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  )

  const hoverCard = (
    <div className={classNames(styles.productCard)}>
      {productImage}
      <div className={styles.infoContainer}>
        <div className={styles.colorOptions}>{colorOptions}</div>
        <h4 className={labelStyles.productCaption}>
          {product.brand + (product.name ? " " + product.name : "")}
        </h4>
        <MoreOptionsButton pathname={product.getDetailsUrl(location)} />
        <p
          style={{
            display: "flex",
            margin: "0",
            position: "absolute",
            bottom: "10px",
            right: "10px",
            fontSize: "var( --font-size-l)",
            fontWeight: "var(--font-weight-light)",
            paddingLeft: "20px",
            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%)",
          }}
        >
          {"$" + price}
        </p>
      </div>
    </div>
  )

  return <>{isTouchScreen ? touchCard : hoverCard}</>
}

/*
        <div className={styles.hoverBtnConainer}>
          <MoreOptionsButton
            pathname={product.getDetailsUrl(location)}
            hoverButton={true}
          />
        </div>
*/

/*
          <div className={styles.priceInfo}>
            <p>&euro;{product.price}</p>
          </div>
*/

ProductCard.propTypes = {
  product: PropTypes.object,
  location: PropTypes.object,
}
