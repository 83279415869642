import { addParamToSearch } from "./positionUtils"
import { nonPositionParams } from "./constants"

export class ProductObj {
  constructor(data) {
    this._id = data.id
    this._index = data.index
    this._brand = data.brand
    this._name = data.name
    this._image = data.image
    this._thumbnail = data.thumbnail
    this._price = data.price
    this._grouping = data.grouping
    this._sorting = data.sorting
    this._colorOptions = data.colorOptions.map(colorOption => {
      if (colorOption.isSelected && colorOption.productImage)
        this._image = colorOption.productImage
      return {
        id: colorOption.id,
        index: colorOption.index,
        productImage: colorOption.productImage,
        productThumbnail: colorOption.productThumbnail,
        caption: colorOption.caption,
        imageUrl: colorOption.colorImage,
        colorCode: colorOption.colorCode,
        colorCodes: colorOption.colorCodes,
        isSelected: colorOption.isSelected,
        price: colorOption.price,
        ...colorOption,
      }
    })
    this._data = { ...data }
    this._storeList = []
  }

  getStoreListFromSizes(data) {
    var result = []
    if (data && data.visuals) {
      data.visuals.map(visual => {
        const colorOption = this._colorOptions.filter(
          option => option.id === visual.id
        )[0]
        const colorOptionIndex = colorOption ? colorOption.index : -1
        var storesForVisual = []
        visual.sizes.map(size => {
          size.stores.map(storeItem => {
            var storeIndex = result.indexOf(storeItem.store)
            if (storeIndex < 0) result.push(storeItem.store)
          })
        })
      })
    }
    return result
  }

  getVisualsWithCorrespondingColorOptions(data) {
    let result = []
    if (data && data.visuals) {
      data.visuals.map((visual, index) => {
        const colorOption = this._colorOptions.filter(
          option => option.id === visual.id
        )[0]
        const colorOptionIndex = colorOption ? colorOption.index : -1
        if (colorOptionIndex > -1) {
          result.push(visual)
        }
      })
    }
    return result
  }

  getColorOptionPrices(data) {
    if (data && data.visuals) {
      data.visuals.map(visual => {
        const colorOption = this._colorOptions.filter(
          option => option.id === visual.id
        )[0]
        const colorOptionIndex = colorOption ? colorOption.index : -1
        if (colorOptionIndex > -1)
          this._colorOptions[colorOptionIndex].price = visual.price / 100
        return false
      })
    }
  }

  getStoresFromSizes(sizes) {
    let result = {}
    sizes.map(sizeItem => {
      if (Array.isArray(sizeItem.stores)) {
        sizeItem.stores.map(storeFromSizes => {
          if (result[storeFromSizes.store] == null) {
            result[storeFromSizes.store] = []
          }
          result[storeFromSizes.store].push({
            ...sizeItem,
            url: storeFromSizes.url,
          })
          return false
        })
      }
      return false
    })
    return result
  }

  mergeVisualsIntoColorOptions(dataWithVisuals, colorOptions) {
    if (!(dataWithVisuals && dataWithVisuals.visuals)) return colorOptions
    const result = colorOptions.map(colorOption => {
      const visual = dataWithVisuals.visuals.find(
        visual => colorOption.id === visual.id
      )
      if (visual != null) {
        const stores = this.getStoresFromSizes(visual.sizes)
        return {
          ...colorOption,
          sizes: visual.sizes,
          storeIds: Object.keys(stores),
          storeEntities: stores,
        }
      }
      return colorOption
    })
    return result
  }

  addDetails(data) {
    this._colorOptions = this.mergeVisualsIntoColorOptions(
      data,
      this._colorOptions
    )
  }

  get id() {
    return this._id
  }

  get index() {
    return this._index
  }

  get brand() {
    return this._brand
  }

  get name() {
    return this._name
  }

  get thumbnail() {
    return this._thumbnail
  }

  get image() {
    return this._image
  }

  get price() {
    return this._price
  }

  get grouping() {
    return this._grouping
  }

  get sorting() {
    return this._sorting
  }

  get colorOptions() {
    return this._colorOptions
  }

  get storeList() {
    return this._storeList
  }

  get selectedOption() {
    return this._colorOptions.find(colorOption => colorOption.isSelected)
  }

  getDetailsUrl(currentLocation = {}) {
    return addParamToSearch(
      currentLocation,
      nonPositionParams.PRODUCT,
      this._id
    )
  }
}
