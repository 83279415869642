export const validateImage = image => {
  var validTypes = ["image/jpeg", "image/png", "image/gif"]
  if (validTypes.indexOf(image.type) === -1) {
    alert("Invalid File Type")
    return false
  }
  var maxSizeInBytes = 10e6
  if (image.size > maxSizeInBytes) {
    alert("File too large")
    return false
  }
  return true
}

export const readImageFromFile = (imageFile, setUploadedImage) => {
  var reader = new FileReader()
  reader.onload = function (e) {
    setUploadedImage(e.target.result)
  }
  reader.readAsDataURL(imageFile)
}

export const handleFiles = (files, setUploadedImage) => {
  if (validateImage(files[0])) readImageFromFile(files[0], setUploadedImage)
}

export const handleHtml = (data, setUploadedImage) => {
  return false
  // var html = data.getData("text/html")
  // var src = html && /[\s]src="?([^"\s]+)"?\s*/.exec(html)
  /*
  var backgroundImg =
    html && /\bbackground-image:[\s]*url\(['"]?([^'"\s]+)/.exec(html)
  var url = null
  if (src) url = src[1]
  else if (backgroundImg) url = backgroundImg[1]
  if (url) setUploadedImage(url)
  */
}
