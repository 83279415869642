import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { AddPanelFiltribute } from "./filtributes/addPanelFiltribute"
import { addFilterSelectionTypes } from "../../utils/constants"
import {
  SectionHeader,
  Icon,
  icons,
  panelKeys,
  SafeLink,
  safeLinkActions,
  screenWidthBreakPointForFilterPanelInMobile,
} from "../index.js"
import { getPathnameString } from "../../utils/positionUtils"
import { SafeButton, presentations } from "../buttons/safeButton"
import useLocale from "../../hooks/useLocale.js"
import { labels } from "../../utils/labelConstants"
import useNavigator from "../../hooks/useNavigator.js"
import useFilterPanels from "../../hooks/useFilterPanels.js"
import usePanel from "../../hooks/usePanel.js"
import * as selectorStyles from "./selector.module.css"
import * as buttonStyles from "../../styles/buttonStyles.module.css"

export const NewFilterSelector = () => {
  const { location } = useNavigator()
  const { filterPanels: filterObj } = useFilterPanels({ location })
  const { openPanel } = usePanel()
  const filterPanelObj = filterObj.getPanel(openPanel)
  const isMobileRef = useRef()
  const [isMobile, _setIsMobile] = useState(false)
  const setIsMobile = value => {
    if (value !== isMobileRef.current) {
      isMobileRef.current = value
      _setIsMobile(value)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const [collections, setCollections] = useState({})
  const locale = useLocale()

  const handleCollectionToggle = collectionName => {
    var newCollections = JSON.parse(JSON.stringify(collections))
    newCollections[collectionName] =
      typeof collections[collectionName] !== "undefined"
        ? !collections[collectionName]
        : true
    setCollections(newCollections)
  }

  const handleResize = () => {
    if (window.innerWidth <= screenWidthBreakPointForFilterPanelInMobile)
      setIsMobile(true)
    else setIsMobile(false)
  }

  const getSelectionList = (selections, collectionName) => {
    var result = selections.map(selection => {
      if (selection.selectionType === addFilterSelectionTypes.SELECTION) {
        return (
          <div key={selection.id} style={{ width: "100%" }}>
            <SafeButton
              to={getPathnameString(location)}
              presentation={presentations.ADD_PANEL}
              payload={{ panelId: selection.id }}
            >
              <AddPanelFiltribute
                value={selection.id}
                caption={selection.caption}
                inCollection={
                  typeof collectionName !== "undefined" ? true : false
                }
                isOpen={
                  typeof collections[collectionName] !== "undefined"
                    ? collections[collectionName]
                    : false
                }
              />
            </SafeButton>
          </div>
        )
      }
      if (selection.selectionType === addFilterSelectionTypes.COLLECTION) {
        const collection = getSelectionList(selection.items, selection.caption)
        const isOpen =
          typeof collections[selection.caption] !== "undefined"
            ? collections[selection.caption]
            : false
        return (
          <div key={selection.id} style={{ width: "100%" }}>
            <div
              onClick={() => {
                handleCollectionToggle(selection.caption)
              }}
              style={{ position: "relative", cursor: "pointer" }}
            >
              <AddPanelFiltribute
                value={selection.id}
                caption={selection.caption}
                inCollection={false}
                isCollection={true}
                isOpen={isOpen}
              />
            </div>
            {isOpen && collection}
          </div>
        )
      }
      return null
    })
    return result
  }

  /*
    { isOpen && 
      <div style={{position: "relative"}}>
        { collection }
        <div style={{ position: "absolute", bottom: "-50px", height: "50px", width: "100%", backgroundColor: "white", boxShadow: "0 -10px 15px -15px rgb(0 0 0 / 10%)"}}/>
      </div>
    }
  */

  const getActiveFilterList = selections => {
    var result = selections.map(selection => {
      return (
        <div
          key={selection.id}
          style={{ width: "calc(100% - 50px)", position: "relative" }}
        >
          <SafeButton
            to={getPathnameString(location)}
            presentation={presentations.ADD_PANEL}
            payload={{ panelId: selection.id }}
          >
            <AddPanelFiltribute
              value={selection.id}
              caption={selection.caption}
              details={selection.details}
              imageUrl={selection.imageUrl}
              isSelected={selection.selected}
              isMobile={isMobile}
              inCollection={false}
            />
          </SafeButton>
          {selection.id !== panelKeys.SHAPE_FILTER ? (
            <div
              className={buttonStyles.roundFloating}
              style={{ right: "-50px" }}
            >
              <SafeLink
                to={
                  filterObj
                    ? filterObj.getPanel(selection.id).getRemoveUrl(location)
                    : ""
                }
                action={safeLinkActions.REMOVE_PANEL}
                actionPayload={selection.id}
              >
                <Icon icon={icons.recycleBin} />
              </SafeLink>
            </div>
          ) : (
            <div style={{ display: "none" }} />
          )}
        </div>
      )
    })
    return result
  }

  const selectionList = getSelectionList(
    filterPanelObj.filtributes.filter(filtribute => !filtribute.selected)
  )
  const activeFilterList = getActiveFilterList(
    filterPanelObj.filtributes.filter(filtribute => filtribute.selected)
  )

  return (
    <>
      <SectionHeader />
      <div className={selectorStyles.sectionBody}>
        {selectionList.length > 0 ? (
          <>
            <p>{labels[locale].MANAGE_CHOOSE_TO_ADD}</p>
            {selectionList}
          </>
        ) : (
          <div style={{ display: "none" }} />
        )}
        {activeFilterList.length > 0 ? (
          <>
            <p>{labels[locale].MANAGE_EDIT}</p>
            {activeFilterList}
          </>
        ) : (
          <div style={{ display: "none" }} />
        )}
      </div>
    </>
  )
}

NewFilterSelector.propTypes = {
  location: PropTypes.object.isRequired,
  filterPanelObj: PropTypes.object.isRequired,
}
