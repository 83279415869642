import React from "react"
import { NavigationArrows } from "./navigationArrows"

import * as styles from "./desktopGallery.module.css"

export const DesktopGallery = ({
  children,
  location,
  previousProduct,
  nextProduct,
}) => {
  return (
    <div data-testid="desktop-gallery" className={styles.desktopGallery}>
      {children}
      <NavigationArrows
        location={location}
        previousProduct={previousProduct}
        nextProduct={nextProduct}
      />
    </div>
  )
}
