import React from "react"

export const ElementsIcon = props => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <circle cx={23.223} cy={26.78} r={9.695} fill="#7695A9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.932 31.738c-.402-3.432.169-7.826 5.509-8.437 4.601-.527 4.659-3.461 3.634-6.18 4.954.424 8.843 4.58 8.843 9.644 0 5.345-4.334 9.679-9.68 9.679a9.674 9.674 0 0 1-8.306-4.707Z"
        fill="#648296"
      />
      <circle cx={35.023} cy={29.827} r={7.977} fill="#FDB046" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.433 36.954c-.609-2.923-.083-6.158 3.624-8.52 3.21-2.044 4.352-3.781 4.52-5.157a7.977 7.977 0 0 1-8.144 13.677Z"
        fill="#F98F3B"
      />
      <ellipse cx={35.189} cy={15.756} rx={7.811} ry={7.756} fill="#93C1CF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.699 22.738c-.075-1.62.148-3.847 1.247-6.495 1.503-3.622-.887-6.44-2.804-8.001a7.785 7.785 0 0 1 1.556 14.496Z"
        fill="#71B5C9"
      />
      <circle cx={11.313} cy={30.492} r={7.312} fill="#FAC853" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.202 37.212c1.13-1.45 1.327-3.537-.572-6.54-2.18-3.448-1.68-5.705-.201-7.18a7.316 7.316 0 0 1 .773 13.72Z"
        fill="#FCB308"
      />
      <path
        d="M7.657 29.498a.024.024 0 0 1 .012-.026l3.817-2.085c.707-.386 1.463-.46 2.244-.386a4.816 4.816 0 0 1 1 0c1.015-.114 2.093.169 3.177.453.537.14 1.074.282 1.606.376 1.176-.225 2.536-.866 4.205-2.172 1.815-1.42 3.854-5.256 5.338-8.047.6-1.128 1.11-2.086 1.476-2.645.404-.614.702-1.09.945-1.477.524-.836.793-1.264 1.313-1.798.7-.718 1.504-.707 1.63-.7.01 0 .015.009.012.017a.014.014 0 0 0 .013.02c.113.005.225.023.335.052.324-.098.574-.093.64-.09.01 0 .015.01.012.018a.014.014 0 0 0 .013.02c.113.005.225.023.335.052.324-.098.574-.093.64-.09.01 0 .015.01.012.018a.014.014 0 0 0 .013.02c1.678.09 3.151 2.787 3.333 6.175.03.577.022 1.139-.022 1.674.008.108.016.216.022.326.102 1.914-.23 3.653-.837 4.852l-1.145 11.248h-3.552l-.625-9.828-.273-.083c-.147-.045-.293-.09-.439-.132-.471.23-1.013.697-1.465 1.141-.344.338-.64.72-.914 1.117l-4.498 6.553-.386.605a1.355 1.355 0 0 1-1.143.627H8.548a.011.011 0 0 1-.01-.009.011.011 0 0 0-.013-.008 1.939 1.939 0 0 1-2.136-2.421 1.942 1.942 0 0 1 0-1 1.941 1.941 0 0 1 1.252-2.338.024.024 0 0 0 .016-.029Z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.67 30.472a.024.024 0 0 0-.013.026.024.024 0 0 1-.016.029 1.94 1.94 0 0 0 .884 3.758c.006 0 .011.004.012.01a.011.011 0 0 0 .011.008h13.953a1.354 1.354 0 0 0 1.143-.627l.386-.605 4.498-6.553c.273-.397.57-.78.914-1.117.53-.52 1.182-1.073 1.7-1.236.724-.229 1.46-.002 2.204.227l.273.083.625 9.828h1.552l1.145-11.248c.607-1.199.939-2.938.837-4.852-.182-3.388-1.655-6.085-3.333-6.176a.014.014 0 0 1-.013-.019c.003-.008-.002-.017-.011-.018-.127-.006-.932-.017-1.631.7-.52.535-.789.963-1.313 1.799-.243.387-.54.863-.945 1.477-.366.559-.876 1.516-1.476 2.645-1.484 2.791-3.523 6.627-5.338 8.047-2.548 1.994-4.376 2.438-5.927 2.216-.623-.089-1.254-.255-1.883-.42-1.542-.404-3.07-.805-4.423-.067L8.67 30.472Z"
        fill="#fff"
      />
    </svg>
  )
}
