import React from "react"

function PatternIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <path
        d="M8.016 20.391a1 1 0 0 0-.707 1.225l5.176 19.318a1 1 0 0 0 1.225.708l19.318-5.177a1 1 0 0 0 .708-1.225l-5.177-19.318a1 1 0 0 0-1.225-.707L8.016 20.39Z"
        fill="#28AED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.064 25.713C31.51 31.14 28.31 36.716 22.92 38.17c-5.391 1.454-10.932-1.766-12.377-7.192-1.444-5.426 1.755-11.002 7.146-12.456 5.39-1.454 10.932 1.766 12.377 7.192Zm-3.486.94c.929 3.489-1.128 7.074-4.593 8.008-3.466.935-7.028-1.135-7.957-4.623-.928-3.488 1.128-7.073 4.594-8.008 3.465-.934 7.028 1.136 7.956 4.624Zm-5.527 4.5a2.908 2.908 0 0 0 2.041-3.56 2.885 2.885 0 0 0-3.536-2.054 2.908 2.908 0 0 0-2.041 3.559 2.885 2.885 0 0 0 3.536 2.055Z"
        fill="#5DEBC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m29.707 20.204-1.148-4.282a1 1 0 0 0-1.225-.707L8.016 20.39a1 1 0 0 0-.707 1.225l1.196 4.465c1.342.475 2.846.818 4.533.993 6.313.653 9.813-2.32 12.453-4.562 1.573-1.337 2.84-2.413 4.216-2.308Z"
        fill="#209ABF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.335 21.039c-2.468-2.401-6.09-3.476-9.647-2.517-3.93 1.06-6.696 4.312-7.346 8.099a20.908 20.908 0 0 0 3.574.521c.447-2.393 2.212-4.439 4.706-5.112a6.457 6.457 0 0 1 5.871 1.319c.348-.285.679-.567.998-.837.658-.56 1.262-1.073 1.844-1.473Zm-6.116 4.55a2.879 2.879 0 0 0-3.385 1.251 13.353 13.353 0 0 0 3.385-1.25Z"
        fill="#23D0D0"
      />
      <path
        d="M14.408 14.256a1 1 0 0 0-.365 1.362l9.74 16.982a1 1 0 0 0 1.37.367l16.867-9.801a1 1 0 0 0 .365-1.362l-9.74-16.981a1 1 0 0 0-1.37-.367l-16.867 9.8Z"
        fill="#F98F3B"
      />
      <path
        d="m35.211 9.297-2.567-4.474a1 1 0 0 0-1.37-.367l-16.866 9.8a1 1 0 0 0-.37 1.355l7.68 13.386c2.597-2.153 3.478-5.475 4.358-8.798 1.282-4.834 2.563-9.669 9.135-10.902Z"
        fill="#DE8036"
      />
      <path
        d="M28.381 6.14 24.662 8.3l10.736 18.716 3.72-2.16L28.38 6.138ZM20.943 10.461l-3.72 2.162L27.96 31.338l3.72-2.16L20.942 10.46Z"
        fill="#FACC55"
      />
      <path
        d="m31.14 10.95-2.759-4.81-3.719 2.16 3.485 6.075c.712-1.336 1.653-2.517 2.994-3.425ZM26.255 19.72l-5.312-9.259-3.72 2.162 7.267 12.665c.704-1.568 1.164-3.304 1.624-5.039l.14-.528Z"
        fill="#FDB046"
      />
    </svg>
  )
}

export { PatternIcon }
