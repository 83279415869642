import React from "react"

import * as styles from "./newSearchButton.module.css"
import * as buttonStyles from "../../styles/buttonStyles.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

export const NewSearchButton = ({ handleClick }) => {
  return (
    <div className={buttonStyles.categoryUp} onClick={handleClick}>
      <h4 className={labelStyles.categoryUp}>New Search</h4>
    </div>
  )
}
