import React from "react"
import classNames from "classnames"
import { Icon, icons, iconsColors } from "../../index"
import * as styles from "./filtribute.module.css"

export const SizeFiltribute = ({
  value,
  url,
  alt,
  isSelected,
  hasChildren,
  caption,
  count,
  multiselect,
}) => {
  return (
    <div
      data-testid="size-filtribute"
      className={classNames(styles.sizeFiltribute, {
        [styles.disabled]: count === 0,
      })}
    >
      <div
        className={classNames(styles.sizeSelectionIndicator, {
          [styles.selected]: isSelected,
        })}
      >
        <div
          className={classNames(styles.sizeIndicator, {
            [styles.selected]: isSelected,
          })}
        >
          {caption}
        </div>
      </div>
      <div className={styles.filtributeDetails}>
        <p>{count}</p>
      </div>
    </div>
  )
}
