export const heapViewActionTypes = {
  HEAP_VIEW_FETCH_REQUEST: "HEAP_VIEW_FETCH_REQUEST",
  HEAP_VIEW_REFETCH_REQUEST: "HEAP_VIEW_REFETCH_REQUEST",
  HEAP_VIEW_FETCH_SUCCESS: "HEAP_VIEW_FETCH_SUCCESS",
  HEAP_VIEW_FETCH_FAILURE: "HEAP_VIEW_FETCH_FAILURE",
  HEAP_VIEW_SELECT_COLOR: "HEAP_VIEW_SELECT_COLOR",
  HEAP_VIEW_PRODUCT_COUNT: "HEAP_VIEW_PRODUCT_COUNT",
}

export const fetchHeapViewRequest = payload => ({
  type: heapViewActionTypes.HEAP_VIEW_FETCH_REQUEST,
  payload,
})

export const refetchHeapViewRequest = payload => ({
  type: heapViewActionTypes.HEAP_VIEW_REFETCH_REQUEST,
  payload,
})

export const fetchHeapViewSuccess = payload => ({
  type: heapViewActionTypes.HEAP_VIEW_FETCH_SUCCESS,
  payload,
})

export const fetchHeapViewFailure = payload => ({
  type: heapViewActionTypes.HEAP_VIEW_FETCH_FAILURE,
  payload,
})

export const selectColor = payload => ({
  type: heapViewActionTypes.HEAP_VIEW_SELECT_COLOR,
  payload,
})

export const updateProductCount = payload => ({
  type: heapViewActionTypes.HEAP_VIEW_PRODUCT_COUNT,
  payload,
})
