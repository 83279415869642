export const errors = {
  missingConfig: "Missing config",
  missingURL: "Config missing URL",
  missingMethod: "Config missing method",
}

export const storeReducers = {
  HEAP_VIEW_BY_POSITION: "heapViewByPosition",
  FILTER_PANELS_BY_POSITION: "filterPanelsByPosition",
  HISTORY: "history",
  PHOTO_SEARCH: "photoSearch",
  PRODUCT_DETAILS: "productDetails",
}

export const storeReducerVersions = {
  [storeReducers.HEAP_VIEW_BY_POSITION]: Date.now(),
  [storeReducers.FILTER_PANELS_BY_POSITION]: Date.now(),
  [storeReducers.HISTORY]: Date.now(),
  [storeReducers.PHOTO_SEARCH]: Date.now(),
  [storeReducers.PRODUCT_DETAILS]: Date.now(),
}

export const panelKeys = {
  ADD_FILTER: "manageFilters",
  SHAPE_FILTER: "shape",
  SHOE_ELEMENT: "shoe.",
  COLOR_FILTER: "color",
  COLOR_FINISH_FILTER: "color_finish",
  MULTICOLOR_FILTER: "multicolor",
  MULTICOLOR_FILTER_ORANGE: "multi.oranges",
  MULTICOLOR_FILTER_YELLOW: "multi.yellows",
  MULTICOLOR_FILTER_RED: "multi.reds",
  MULTICOLOR_FILTER_BROWN: "multi.browns",
  MULTICOLOR_FILTER_PINK: "multi.pinks",
  MULTICOLOR_FILTER_PURPLE: "multi.purples",
  MULTICOLOR_FILTER_BLUE: "multi.blues",
  MULTICOLOR_FILTER_GREEN: "multi.greens",
  MULTICOLOR_FILTER_GREY: "multi.greys",
  MULTICOLOR_FILTER_BLACK: "multi.black",
  MULTICOLOR_FILTER_WHITE: "multi.white",
  MULTICOLOR_FILTER_GOLD: "multi.gold",
  MULTICOLOR_FILTER_SILVER: "multi.silver",
  PATTERN_FILTER: "pattern",
  BRAND_FILTER: "brand",
  PRICE_FILTER: "price",
  PRICE_FILTER_GTE: "price_gte",
  PRICE_FILTER_LTE: "price_lte",
  SIZE_FILTER: "size",
  VENDOR_FILTER: "store",
  HEEL_HEIGHT_FILTER: "shoe.heel_height",
  HEEL_TYPE_FILTER: "shoe.heel_type",
  PLATFORM_FILTER: "shoe.platform",
  TOE_TYPE_FILTER: "shoe.toe",
  BACK_FILTER: "shoe.back",
  FASTENING_TYPE_FILTER: "shoe.fasten",
  DECORATIONS_TYPE_FILTER: "shoe.deco",
}

export const syntheticPanels = {
  [panelKeys.PRICE_FILTER]: [
    panelKeys.PRICE_FILTER_GTE,
    panelKeys.PRICE_FILTER_LTE,
  ],
  [panelKeys.COLOR_FILTER]: [
    panelKeys.MULTICOLOR_FILTER_ORANGE,
    panelKeys.MULTICOLOR_FILTER_YELLOW,
    panelKeys.MULTICOLOR_FILTER_RED,
    panelKeys.MULTICOLOR_FILTER_BROWN,
    panelKeys.MULTICOLOR_FILTER_PINK,
    panelKeys.MULTICOLOR_FILTER_PURPLE,
    panelKeys.MULTICOLOR_FILTER_BLUE,
    panelKeys.MULTICOLOR_FILTER_GREEN,
    panelKeys.MULTICOLOR_FILTER_GREY,
    panelKeys.MULTICOLOR_FILTER_BLACK,
    panelKeys.MULTICOLOR_FILTER_WHITE,
    panelKeys.MULTICOLOR_FILTER_GOLD,
    panelKeys.MULTICOLOR_FILTER_SILVER,
  ],
}

export const multicolorOptions = {
  MUST_CONTAIN: "Must contain",
  IGNORE: "Ignore",
  MUST_NOT_CONTAIN: "Must Not Contain",
}

export const mustContain = {
  [panelKeys.MULTICOLOR_FILTER_ORANGE]: "fa.color.shoe.multi.oranges.must",
  [panelKeys.MULTICOLOR_FILTER_YELLOW]: "fa.color.shoe.multi.yellows.must",
  [panelKeys.MULTICOLOR_FILTER_RED]: "fa.color.shoe.multi.reds.must",
  [panelKeys.MULTICOLOR_FILTER_BROWN]: "fa.color.shoe.multi.browns.must",
  [panelKeys.MULTICOLOR_FILTER_PINK]: "fa.color.shoe.multi.pinks.must",
  [panelKeys.MULTICOLOR_FILTER_PURPLE]: "fa.color.shoe.multi.purples.must",
  [panelKeys.MULTICOLOR_FILTER_BLUE]: "fa.color.shoe.multi.blues.must",
  [panelKeys.MULTICOLOR_FILTER_GREEN]: "fa.color.shoe.multi.greens.must",
  [panelKeys.MULTICOLOR_FILTER_GREY]: "fa.color.shoe.multi.greys.must",
  [panelKeys.MULTICOLOR_FILTER_BLACK]: "fa.color.shoe.multi.black.must",
  [panelKeys.MULTICOLOR_FILTER_WHITE]: "fa.color.shoe.multi.white.must",
  [panelKeys.MULTICOLOR_FILTER_GOLD]: "fa.color.shoe.multi.gold.must",
  [panelKeys.MULTICOLOR_FILTER_SILVER]: "fa.color.shoe.multi.silver.must",
}

export const mustNotContain = {
  [panelKeys.MULTICOLOR_FILTER_ORANGE]: "fa.color.shoe.multi.oranges.must_not",
  [panelKeys.MULTICOLOR_FILTER_YELLOW]: "fa.color.shoe.multi.yellows.must_not",
  [panelKeys.MULTICOLOR_FILTER_RED]: "fa.color.shoe.multi.reds.must_not",
  [panelKeys.MULTICOLOR_FILTER_BROWN]: "fa.color.shoe.multi.browns.must_not",
  [panelKeys.MULTICOLOR_FILTER_PINK]: "fa.color.shoe.multi.pinks.must_not",
  [panelKeys.MULTICOLOR_FILTER_PURPLE]: "fa.color.shoe.multi.purples.must_not",
  [panelKeys.MULTICOLOR_FILTER_BLUE]: "fa.color.shoe.multi.blues.must_not",
  [panelKeys.MULTICOLOR_FILTER_GREEN]: "fa.color.shoe.multi.greens.must_not",
  [panelKeys.MULTICOLOR_FILTER_GREY]: "fa.color.shoe.multi.greys.must_not",
  [panelKeys.MULTICOLOR_FILTER_BLACK]: "fa.color.shoe.multi.black.must_not",
  [panelKeys.MULTICOLOR_FILTER_WHITE]: "fa.color.shoe.multi.white.must_not",
  [panelKeys.MULTICOLOR_FILTER_GOLD]: "fa.color.shoe.multi.gold.must_not",
  [panelKeys.MULTICOLOR_FILTER_SILVER]: "fa.color.shoe.multi.silver.must_not",
}

export const ignore = {
  [panelKeys.MULTICOLOR_FILTER_ORANGE]: "fa.color.shoe.multi.oranges.ignore",
  [panelKeys.MULTICOLOR_FILTER_YELLOW]: "fa.color.shoe.multi.yellows.ignore",
  [panelKeys.MULTICOLOR_FILTER_RED]: "fa.color.shoe.multi.reds.ignore",
  [panelKeys.MULTICOLOR_FILTER_BROWN]: "fa.color.shoe.multi.browns.ignore",
  [panelKeys.MULTICOLOR_FILTER_PINK]: "fa.color.shoe.multi.pinks.ignore",
  [panelKeys.MULTICOLOR_FILTER_PURPLE]: "fa.color.shoe.multi.purples.ignore",
  [panelKeys.MULTICOLOR_FILTER_BLUE]: "fa.color.shoe.multi.blues.ignore",
  [panelKeys.MULTICOLOR_FILTER_GREEN]: "fa.color.shoe.multi.greens.ignore",
  [panelKeys.MULTICOLOR_FILTER_GREY]: "fa.color.shoe.multi.greys.ignore",
  [panelKeys.MULTICOLOR_FILTER_BLACK]: "fa.color.shoe.multi.black.ignore",
  [panelKeys.MULTICOLOR_FILTER_WHITE]: "fa.color.shoe.multi.white.ignore",
  [panelKeys.MULTICOLOR_FILTER_GOLD]: "fa.color.shoe.multi.gold.ignore",
  [panelKeys.MULTICOLOR_FILTER_SILVER]: "fa.color.shoe.multi.silver.ignore",
}

export const multicolorIndex = {
  [mustContain[panelKeys.MULTICOLOR_FILTER_ORANGE]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_YELLOW]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_RED]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_BROWN]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_PINK]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_PURPLE]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_BLUE]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_GREEN]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_GREY]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_BLACK]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_WHITE]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_GOLD]]: 0,
  [mustContain[panelKeys.MULTICOLOR_FILTER_SILVER]]: 0,

  [ignore[panelKeys.MULTICOLOR_FILTER_ORANGE]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_YELLOW]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_RED]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_BROWN]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_PINK]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_PURPLE]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_BLUE]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_GREEN]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_GREY]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_BLACK]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_WHITE]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_GOLD]]: 1,
  [ignore[panelKeys.MULTICOLOR_FILTER_SILVER]]: 1,

  [mustNotContain[panelKeys.MULTICOLOR_FILTER_ORANGE]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_YELLOW]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_RED]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_BROWN]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_PINK]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_PURPLE]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_BLUE]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_GREEN]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_GREY]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_BLACK]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_WHITE]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_GOLD]]: 2,
  [mustNotContain[panelKeys.MULTICOLOR_FILTER_SILVER]]: 2,
}

export const colors = {
  [panelKeys.MULTICOLOR_FILTER_ORANGE]: "#E69723",
  [panelKeys.MULTICOLOR_FILTER_YELLOW]: "#FACC55",
  [panelKeys.MULTICOLOR_FILTER_RED]: "#DF202E",
  [panelKeys.MULTICOLOR_FILTER_BROWN]: "#725039",
  [panelKeys.MULTICOLOR_FILTER_PINK]: "#F2728C",
  [panelKeys.MULTICOLOR_FILTER_PURPLE]: "#A13895",
  [panelKeys.MULTICOLOR_FILTER_BLUE]: "#307BBF",
  [panelKeys.MULTICOLOR_FILTER_GREEN]: "#2C9E48",
  [panelKeys.MULTICOLOR_FILTER_GREY]: "#808282",
  [panelKeys.MULTICOLOR_FILTER_BLACK]: "#000000",
  [panelKeys.MULTICOLOR_FILTER_WHITE]: "#FAFAFA",
  [panelKeys.MULTICOLOR_FILTER_GOLD]:
    "linear-gradient(-45deg, rgba(252,186,44,1) 0%, rgba(252,249,195,1) 50%, rgba(252,176,69,1) 100%)",
  [panelKeys.MULTICOLOR_FILTER_SILVER]:
    "linear-gradient(-45deg, rgba(145,150,152,1) 0%, rgba(240,240,240,1) 50%, rgba(145,150,152,1) 100%)",
}

export const panelNames = {
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_ORANGE}`]: "Oranges",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_YELLOW}`]: "Yellows",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_RED}`]: "Reds",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_BROWN}`]: "Browns",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_PINK}`]: "Pinks",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_PURPLE}`]: "Purples",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_BLUE}`]: "Blues",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_GREEN}`]: "Greens",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_GREY}`]: "Greys",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_BLACK}`]: "Blacks",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_WHITE}`]: "Whites",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_GOLD}`]: "Gold",
  [`fp.shoe.color.${panelKeys.MULTICOLOR_FILTER_SILVER}`]: "Silver",
}

export const positionsToRemove = {
  [panelKeys.PRICE_FILTER_GTE]: "fa.price_gte.gbp.0",
  [panelKeys.PRICE_FILTER_LTE]: "fa.price_lte.gbp.inf",
  [panelKeys.MULTICOLOR_FILTER_YELLOW]:
    ignore[panelKeys.MULTICOLOR_FILTER_YELLOW],
  [panelKeys.MULTICOLOR_FILTER_ORANGE]:
    ignore[panelKeys.MULTICOLOR_FILTER_ORANGE],
  [panelKeys.MULTICOLOR_FILTER_RED]: ignore[panelKeys.MULTICOLOR_FILTER_RED],
  [panelKeys.MULTICOLOR_FILTER_BROWN]:
    ignore[panelKeys.MULTICOLOR_FILTER_BROWN],
  [panelKeys.MULTICOLOR_FILTER_PINK]: ignore[panelKeys.MULTICOLOR_FILTER_PINK],
  [panelKeys.MULTICOLOR_FILTER_PURPLE]:
    ignore[panelKeys.MULTICOLOR_FILTER_PURPLE],
  [panelKeys.MULTICOLOR_FILTER_BLUE]: ignore[panelKeys.MULTICOLOR_FILTER_BLUE],
  [panelKeys.MULTICOLOR_FILTER_GREEN]:
    ignore[panelKeys.MULTICOLOR_FILTER_GREEN],
  [panelKeys.MULTICOLOR_FILTER_GREY]: ignore[panelKeys.MULTICOLOR_FILTER_GREY],
  [panelKeys.MULTICOLOR_FILTER_BLACK]:
    ignore[panelKeys.MULTICOLOR_FILTER_BLACK],
  [panelKeys.MULTICOLOR_FILTER_WHITE]:
    ignore[panelKeys.MULTICOLOR_FILTER_WHITE],
  [panelKeys.MULTICOLOR_FILTER_GOLD]: ignore[panelKeys.MULTICOLOR_FILTER_GOLD],
  [panelKeys.MULTICOLOR_FILTER_SILVER]:
    ignore[panelKeys.MULTICOLOR_FILTER_SILVER],
}

export const panelOrder = [
  panelKeys.ADD_FILTER,
  panelKeys.SHAPE_FILTER,
  panelKeys.SHOE_ELEMENT,
  panelKeys.COLOR_FILTER,
  panelKeys.MULTICOLOR_FILTER,
  panelKeys.COLOR_FINISH_FILTER,
  panelKeys.PATTERN_FILTER,
  panelKeys.BRAND_FILTER,
  panelKeys.PRICE_FILTER,
  panelKeys.SIZE_FILTER,
  panelKeys.VENDOR_FILTER,
]

export const lockedPanels = [panelKeys.ADD_FILTER, panelKeys.SHAPE_FILTER]

export const addFilterSelectionTypes = {
  SELECTION: "selection",
  COLLECTION: "collection",
}

export const selectors = {
  NEW_FILTER: "NewFilterSelector",
  CATEGORY: "CategorySelector",
  ELEMENT: "ElementSelector",
  BRAND: "BrandSelector",
  COLOR: "ColorSelector",
  MULTICOLOR: "ColorSelector",
  COLOR_FINISH_FILTER: "ColorSelector",
  PATTERN: "PatternSelector",
  SIZE: "SizeSelector",
  VENDOR: "VendorSelector",
  PRICE: "PriceSelector",
}

export const selectorsByPanel = {
  [panelKeys.ADD_FILTER]: selectors.NEW_FILTER,
  [panelKeys.SHAPE_FILTER]: selectors.CATEGORY,
  [panelKeys.COLOR_FILTER]: selectors.COLOR,
  [panelKeys.MULTICOLOR_FILTER]: selectors.MULTICOLOR,
  [panelKeys.COLOR_FINISH_FILTER]: selectors.COLOR_FINISH_FILTER,
  [panelKeys.PATTERN_FILTER]: selectors.PATTERN,
  [panelKeys.SIZE_FILTER]: selectors.SIZE,
  [panelKeys.BRAND_FILTER]: selectors.BRAND,
  [panelKeys.PRICE_FILTER]: selectors.PRICE,
  [panelKeys.VENDOR_FILTER]: selectors.VENDOR,
  [panelKeys.SHOE_ELEMENT]: selectors.ELEMENT,
  [panelKeys.HEEL_HEIGHT_FILTER]: selectors.ELEMENT,
  [panelKeys.HEEL_TYPE_FILTER]: selectors.ELEMENT,
  [panelKeys.PLATFORM_FILTER]: selectors.ELEMENT,
  [panelKeys.TOE_TYPE_FILTER]: selectors.ELEMENT,
  [panelKeys.BACK_FILTER]: selectors.ELEMENT,
  [panelKeys.FASTENING_TYPE_FILTER]: selectors.ELEMENT,
  [panelKeys.DECORATIONS_TYPE_FILTER]: selectors.ELEMENT,
}

export const selectorCollections = {
  [panelKeys.SHOE_ELEMENT]: "Shoe Elements",
}

export const panelCaptions = {
  [panelKeys.ADD_FILTER]: "Manage Filters",
  [panelKeys.SHAPE_FILTER]: "Shape",
  CATEGORY_SUB_TYPE: "Category",
  SHAPE_SUB_TYPE: "Shape",
  [panelKeys.COLOR_FILTER]: "Color",
  [panelKeys.MULTICOLOR_FILTER]: "Multicolor",
  [panelKeys.COLOR_FINISH_FILTER]: "Color Finish",
  [panelKeys.PATTERN_FILTER]: "Pattern",
  [panelKeys.BRAND_FILTER]: "Brand",
  [panelKeys.PRICE_FILTER]: "Price",
  [panelKeys.SIZE_FILTER]: "Size",
  [panelKeys.VENDOR_FILTER]: "Store",
  [panelKeys.HEEL_HEIGHT_FILTER]: "Heel Height",
  [panelKeys.HEEL_TYPE_FILTER]: "Heel Type",
  [panelKeys.PLATFORM_FILTER]: "Platform",
  [panelKeys.TOE_TYPE_FILTER]: "Toe Type",
  [panelKeys.BACK_FILTER]: "Counter Type",
  [panelKeys.FASTENING_TYPE_FILTER]: "Fastening Type",
  [panelKeys.DECORATIONS_TYPE_FILTER]: "Decorations",
}

export const panelsWithIcons = {
  ADD_FILTER: panelKeys.ADD_FILTER,
  SHOE_ELEMENT: selectorCollections[panelKeys.SHOE_ELEMENT],
  SHAPE_FILTER: panelKeys.SHAPE_FILTER,
  COLOR_FILTER: panelKeys.COLOR_FILTER,
  MULTICOLOR_FILTER: panelKeys.MULTICOLOR_FILTER,
  PATTERN_FILTER: panelKeys.PATTERN_FILTER,
  BRAND_FILTER: panelKeys.BRAND_FILTER,
  PRICE_FILTER: panelKeys.PRICE_FILTER,
  SIZE_FILTER: panelKeys.SIZE_FILTER,
  VENDOR_FILTER: panelKeys.VENDOR_FILTER,
  HEEL_HEIGHT_FILTER: panelKeys.HEEL_HEIGHT_FILTER,
  HEEL_TYPE_FILTER: panelKeys.HEEL_TYPE_FILTER,
  PLATFORM_FILTER: panelKeys.PLATFORM_FILTER,
  TOE_TYPE_FILTER: panelKeys.TOE_TYPE_FILTER,
  BACK_FILTER: panelKeys.BACK_FILTER,
  FASTENING_TYPE_FILTER: panelKeys.FASTENING_TYPE_FILTER,
  DECORATIONS_TYPE_FILTER: panelKeys.DECORATIONS_TYPE_FILTER,
}

export const indicatorTypes = {
  IMAGE: "image",
  ICON: "icon",
  COLOR: "color",
  PRICE: "price",
  SIZE: "size",
  BRAND: "brand",
  STORE: "store",
}

export const indicatorSubTypes = {
  SHAPE: "SHAPE",
}

export const indicatorTypesByPanel = {
  [panelKeys.ADD_FILTER]: indicatorTypes.ICON,
  [panelKeys.SHAPE_FILTER]: indicatorTypes.IMAGE,
  [panelKeys.COLOR_FILTER]: indicatorTypes.COLOR,
  [panelKeys.MULTICOLOR_FILTER]: indicatorTypes.COLOR,
  [panelKeys.COLOR_FINISH_FILTER]: indicatorTypes.COLOR,
  [panelKeys.PATTERN_FILTER]: indicatorTypes.COLOR,
  [panelKeys.PRICE_FILTER]: indicatorTypes.PRICE,
  [panelKeys.SIZE_FILTER]: indicatorTypes.SIZE,
  [panelKeys.BRAND_FILTER]: indicatorTypes.BRAND,
  [panelKeys.VENDOR_FILTER]: indicatorTypes.STORE,
  [panelKeys.SHOE_ELEMENT]: indicatorTypes.IMAGE,
  [panelKeys.HEEL_HEIGHT_FILTER]: indicatorTypes.IMAGE,
  [panelKeys.HEEL_TYPE_FILTER]: indicatorTypes.IMAGE,
  [panelKeys.PLATFORM_FILTER]: indicatorTypes.IMAGE,
  [panelKeys.TOE_TYPE_FILTER]: indicatorTypes.IMAGE,
  [panelKeys.BACK_FILTER]: indicatorTypes.IMAGE,
  [panelKeys.FASTENING_TYPE_FILTER]: indicatorTypes.IMAGE,
  [panelKeys.DECORATIONS_TYPE_FILTER]: indicatorTypes.IMAGE,
}

export const groupNames = {
  NONE: "ungrouped",
  SHAPE: "byShape",
}

export const groupNameByIndex = index => {
  return groupNames[Object.keys(groupNames)[index]]
}

export const groupLabels = {
  [groupNames.NONE]: "GROUP_NONE",
  [groupNames.SHAPE]: "GROUP_BY_SHAPE",
}

export const sortingNames = {
  /* NONE: "unsorted", */
  /* POPULARITY: "byPopularity", */
  PRICE_ASC: "price_asc",
  PRICE_DESC: "price_desc",
}

export const sortingNameByIndex = index => {
  return sortingNames[Object.keys(sortingNames)[index]]
}

export const sortingLabels = {
  /*
  [sortingNames.NONE]: "SORT_NONE",
  [sortingNames.POPULARITY]: "SORT_BY_POPULARITY",
  */
  [sortingNames.PRICE_ASC]: "SORT_BY_PRICE_ASC",
  [sortingNames.PRICE_DESC]: "SORT_BY_PRICE_DESC",
}

export const nonPositionParams = {
  PRODUCT: "p",
}

export const dialogNames = {
  MORE_OPTIONS: "moreOptions",
  UPLOADS: "uploads",
}

export const mapPositionToFetchLinks = {
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93Il19:
    "https://run.mocky.io/v3/40a46900-1ea4-43e0-8b4e-0fc9856e91eb",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3B1bXBzIl19:
    "https://run.mocky.io/v3/f7662b09-91ba-4d15-8e26-2949a049c3b4",
  "eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X2JhbGxlcmluYXMiXX0=":
    "https://run.mocky.io/v3/46a88f95-bc0e-418b-bc28-5cc8e2d68565",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3NuZWFrZXJzIl19:
    "https://run.mocky.io/v3/f240e429-bc74-4829-8c0d-f335cf1a75f1",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93Il0sImNvbG9yIjpbIndoaXRlIl19:
    "https://run.mocky.io/v3/fe81ca71-0869-479c-91ec-c264b8a5f832",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93Il0sImNvbG9yIjpbImJsYWNrIl19:
    "https://run.mocky.io/v3/c9fff5d7-ac50-4824-be87-b8b3a4b7e367",
  "eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93Il0sImNvbG9yIjpbInJlZCJdfQ==":
    "https://run.mocky.io/v3/91d27552-2ed0-4e3a-946f-38949146c5f9",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3B1bXBzIl0sImNvbG9yIjpbIndoaXRlIl19:
    "https://run.mocky.io/v3/02b044db-3cac-4ec2-83c5-ea124321de46",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3B1bXBzIl0sImNvbG9yIjpbImJsYWNrIl19:
    "https://run.mocky.io/v3/938c32a2-bcdf-45a9-9496-29d355fd3d90",
  "eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3B1bXBzIl0sImNvbG9yIjpbInJlZCJdfQ==":
    "https://run.mocky.io/v3/01527b22-c14e-4a47-9f7a-debf84ce663b",
  "eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X2JhbGxlcmluYXMiXSwiY29sb3IiOlsid2hpdGUiXX0=":
    "https://run.mocky.io/v3/dee27460-ca37-4c3d-8afa-e3d1b32c33ef",
  "eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X2JhbGxlcmluYXMiXSwiY29sb3IiOlsiYmxhY2siXX0=":
    "https://run.mocky.io/v3/5ab73317-b22e-440f-8295-abcb24106b7d",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X2JhbGxlcmluYXMiXSwiY29sb3IiOlsicmVkIl19:
    "https://run.mocky.io/v3/1b65093f-afb9-49f6-a181-2d0e580a6c01",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3NuZWFrZXJzIl0sImNvbG9yIjpbIndoaXRlIl19:
    "https://run.mocky.io/v3/f99d475c-4907-46d8-a49f-5fb9dc8166d3",
  eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3NuZWFrZXJzIl0sImNvbG9yIjpbImJsYWNrIl19:
    "https://run.mocky.io/v3/4ae382e0-795d-452a-8883-f6d7c5a77d7a",
  "eyJjYXRlZ29yeSI6WyJmb290d2Vhcl93X3NuZWFrZXJzIl0sImNvbG9yIjpbInJlZCJdfQ==":
    "https://run.mocky.io/v3/20988ff1-6596-4210-ae08-237c5b4eb8c6",
}

export const heapSets = [
  [
    { top: 46, left: 235, angle: -18 },
    { top: 5, left: 135, angle: 12 },
    { top: 96, left: 55, angle: -4 },
    { top: 50, left: 230, angle: 5 },
    { top: 28, left: 130, angle: -6 },
    { top: 74, left: 63, angle: -10 },
    { top: 40, left: 110, angle: -12 },
    { top: 60, left: 197, angle: 12 },
    { top: 108, left: 98, angle: -13 },
    { top: 94, left: 126, angle: -25 },
    { top: 76, left: 216, angle: 23 },
    { top: 68, left: 35, angle: 6 },
  ],
  [
    { top: 62, left: 36, angle: 8 },
    { top: 103.74, left: 187, angle: -6 },
    { top: 4, left: 211, angle: 9 },
    { top: 38, left: 63, angle: -2 },
    { top: 18, left: 179, angle: 4 },
    { top: 56, left: 153, angle: -23 },
    { top: 65, left: 70, angle: -12 },
    { top: 35, left: 218, angle: 23 },
    { top: 82, left: 56, angle: -6 },
    { top: 83, left: 137, angle: -13 },
    { top: 36, left: 120, angle: -43 },
    { top: 28, left: 28, angle: -12 },
  ],
  [
    { top: 30, left: 66, angle: 6.36 },
    { top: 103.74, left: 143.46, angle: -0.66 },
    { top: 20, left: 219.3, angle: -8.53 },
    { top: 119.97, left: 152.8, angle: -7.53 },
    { top: 48, left: 78, angle: 0.81 },
    { top: 32.72, left: 213.3, angle: -8.69 },
    { top: 81, left: 178, angle: 21.9 },
    { top: 71.27, left: 108.27, angle: -0.01 },
    { top: 50, left: 227, angle: -12.85 },
    { top: 108, left: 129, angle: 5.3 },
    { top: 120, left: 179, angle: 7.83 },
    { top: 36, left: 91, angle: 6.88 },
  ],
  [
    { top: 5, left: 125, angle: 1.92 },
    { top: 90, left: 50, angle: -5 },
    { top: 75, left: 200, angle: -10 },
    { top: 27, left: 110, angle: -8 },
    { top: 72, left: 34, angle: 5 },
    { top: 90, left: 182, angle: -18 },
    { top: 18, left: 140, angle: 12 },
    { top: 104, left: 75, angle: 20 },
    { top: 85, left: 213, angle: 15 },
    { top: 55, left: 204, angle: 9 },
    { top: 23, left: 54, angle: -13 },
    { top: 83, left: 105, angle: 24 },
  ],
]

export const screenWidthBreakPointForFilterPanelInDesktop = 1000
export const screenWidthBreakPointForFilterPanelInMobile = 576
export const toolboxHideOnScrollDebounceTime = 666

export const ariaRoles = {
  BUTTON: "button",
  CHECKBOX: "checkbox",
  DIALOG: "dialog",
  GROUP: "group",
  LIST: "list",
  LIST_ITEM: "listitem",
  NAVIGATION: "navigation",
  RADIO: "radio",
}

export const htmlTagNames = {
  IMAGE_INPUT: "image-input",
}

export const safeLinkActions = {
  ADD_PANEL: "ADD_PANEL",
  BACK_ONE_LEVEL: "BACK_ONE_LEVEL",
  CHOOSE_VALUE: "CHOOSE_VALUE",
  CLOSE_DIALOG: "CLOSE_DIALOG",
  HEAP_OPEN: "HEAP_OPEN",
  HOME: "HOME",
  MORE_OPTIONS: "MORE_OPTIONS",
  REMOVE_ALL: "REMOVE_ALL",
  REMOVE_PANEL: "REMOVE_PANEL",
  SHOW_PRODUCTS: "SHOW_PRODUCTS",
  TEXT_SEARCH: "TEXT_SEARCH",
  SHOW_UPLOADS: "SHOW_UPLOADS",
  TOGGLE_ADD_FILTER: "TOGGLE_ADD_FILTER",
  TOGGLE_PANEL: "TOGGLE_PANEL",
}

export const suggestionsArray = {
  "women's shoes": {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-footwear_60.png",
    link: "/footwear/women/",
  },
  pumps: {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_highheel_60.png",
    link: "/footwear/women/pumps/",
  },
  sneakers: {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-shoe_sneaker_60.png",
    link: "/footwear/women/sneakers/",
  },
  ballerinas: {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_ballet_60.png",
    link: "/footwear/women/?shape=WyJmYS5jYXQuc2hvZS53LmJhbGxldF9zaG9lIl0=",
  },
}

export const emptyImage =
  "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

export const STORAGE = "gasefiStorage"

export const INFINITY = 9007199254740991
