import React from "react"
import classNames from "classnames"
import { Icon, icons, iconsColors } from "../../index"
import * as styles from "./filtribute.module.css"

export const ColorFiltribute = ({
  value,
  url,
  alt,
  isSelected,
  hasChildren,
  caption,
  count,
  multiselect,
  background,
}) => {
  const backgroundStyle = background ? { background: background } : {}
  return (
    <div
      data-testid="color-filtribute"
      className={classNames(styles.colorFiltribute, {
        [styles.disabled]: count === 0,
      })}
    >
      <div
        className={classNames(styles.colorSelectionIndicator, {
          [styles.selected]: isSelected,
        })}
      >
        <div
          className={classNames(styles.colorIndicator, {
            [styles.selected]: isSelected,
            [styles.hasChildren]: hasChildren,
          })}
          style={backgroundStyle}
        >
          {url && url !== "#" ? <img src={url} alt={alt} key={url} /> : <div />}
        </div>
      </div>
      {multiselect ? (
        <div
          className={classNames(styles.multiselect, {
            [styles.selected]: isSelected,
          })}
        >
          {isSelected && (
            <Icon icon={icons.check} size={14} color={iconsColors.WHITE} />
          )}
        </div>
      ) : null}
      <div className={styles.filtributeDetails}>
        <h4>{caption}</h4>
        <p>{count}</p>
      </div>
    </div>
  )
}
