import React from "react"
import { cTextInputContainer, cTextInput } from "./textInput.module.css"

export const TextInput = ({ value, onChange }) => {
  return (
    <div className={cTextInputContainer}>
      <input
        type="text"
        className={cTextInput}
        onChange={event => {
          onChange(event.currentTarget.value)
        }}
        value={value}
      />
    </div>
  )
}
