import { categoryToUrl, urlToPosition } from "./urlMappings"
import { nonPositionParams, panelKeys, positionsToRemove } from "./constants"

import { arrayExcludeStrings } from "./arrayUtils"

export const encodeObject = objectToEncode => {
  const objectString =
    typeof objectToEncode !== "string"
      ? JSON.stringify(objectToEncode)
      : objectToEncode

  var bitEncoded = null
  if (typeof btoa === "function") bitEncoded = btoa(objectString)
  else bitEncoded = Buffer.from(objectString).toString("base64")

  return encodeURI(bitEncoded)
}

export const decodeObject = objectToDecode => {
  var result = {}
  try {
    if (typeof atob === "function")
      result = JSON.parse(atob(decodeURIComponent(objectToDecode)))
    else
      result = JSON.parse(
        Buffer.from(decodeURIComponent(objectToDecode), "base64").toString()
      )
  } catch {
    return {}
  }
  return result
}

export const getKeyFromString = (string, separator = "=") => {
  return string.substr(0, string.indexOf(separator))
}

export const getValueFromString = (string, separator = "=") => {
  return string.substr(string.indexOf(separator) + 1)
}

const getValidPositionKeys = position => {
  return Object.keys(position).filter(
    keyFromPosition =>
      Object.keys(panelKeys).findIndex(key =>
        keyFromPosition.startsWith(panelKeys[key])
      ) >= 0
  )
}

export const serializePosition = position => {
  if (typeof position === "undefined") return null
  const positionKeys = getValidPositionKeys(position)
  const encodedElements = positionKeys.map(key => {
    const encoded = encodeObject(position[key])
    return key + "=" + encoded
  })
  return encodedElements.join("&")
}

export const serializeCoords = coords => {
  return serializePosition(coords)
}

export const deserializePosition = serializedPosition => {
  if (!serializedPosition) return ""
  const encodedElements = serializedPosition.split("&")
  const deserializedPosition = {}
  encodedElements.map(encodedElement => {
    deserializedPosition[getKeyFromString(encodedElement)] = decodeObject(
      getValueFromString(encodedElement)
    )
    return false
  })
  Object.keys(deserializedPosition).map(key => {
    if (
      Array.isArray(deserializedPosition[key]) &&
      deserializedPosition[key].length === 0
    )
      delete deserializedPosition[key]
    return false
  })
  return deserializedPosition
}

export const removeEmptyFromPosition = position => {
  var newPosition = {}
  const positionKeys = Object.keys(position)
  positionKeys.map(positionKey => {
    if (
      Array.isArray(position[positionKey]) &&
      position[positionKey].length > 0
    ) {
      if (
        !(
          Array.isArray(position[positionKey][0]) &&
          position[positionKey][0].length === 0
        )
      )
        newPosition[positionKey] = position[positionKey]
    }
    return false
  })
  return newPosition
}

export const removeKeysFromPosition = (position, keyArray) => {
  var result = JSON.parse(JSON.stringify(position))
  keyArray.map(keyToRemove => {
    if (typeof result[keyToRemove] !== "undefined") delete result[keyToRemove]
    return false
  })
  return result
}

const cleanupPosition = position => {
  var result = JSON.parse(JSON.stringify(position))
  Object.keys(position).map(key => {
    if (Array.isArray(result[key])) {
      result[key] = result[key].filter(
        item => typeof item === "string" || Array.isArray(item)
      )
    } else if (typeof result[key] !== "string") {
      delete result[key]
    }
    return false
  })

  return result
}

export const getNonPositionParamsFromSearchString = searchString => {
  var result = {}
  const params = new URLSearchParams(searchString)
  const nonPositionParamValues = Object.keys(nonPositionParams).map(
    key => nonPositionParams[key]
  )
  params.forEach((value, key) => {
    if (nonPositionParamValues.indexOf(key) >= 0) result[key] = value
  })
  return result
}

export const getNonPositionParamsFromLocation = location => {
  return getNonPositionParamsFromSearchString(location.search)
}

const removeNonPositionParams = params => {
  var newParams = {}
  const nonPositionParamValues = Object.keys(nonPositionParams).map(
    key => nonPositionParams[key]
  )
  params.forEach((value, key) => {
    if (nonPositionParamValues.indexOf(key) < 0) newParams[key] = value
  })
  const newSearch = Object.keys(newParams)
    .map(key => key + "=" + newParams[key])
    .join("&")
  return new URLSearchParams(newSearch)
}

export const getProductIdFromLocation = location => {
  const params = getNonPositionParamsFromLocation(location)
  return params[nonPositionParams.PRODUCT]
}

export const locationToPosition = location => {
  const [pathname, search] = pathnameAndSearchFromLocation(location)
  const positionFromPathname = urlToPosition[pathname]
  var newPosition = positionFromPathname
    ? JSON.parse(JSON.stringify(positionFromPathname))
    : {}
  const params = removeNonPositionParams(new URLSearchParams(search))
  params.forEach((value, key) => {
    if (typeof newPosition[key] !== "undefined")
      newPosition[key] = newPosition[key].concat(decodeObject(value))
    else newPosition[key] = decodeObject(value)
  })
  return cleanupPosition(newPosition)
}

export const pathnameAndSearchFromHref = href => {
  if (!href) return ["", ""]
  var [pathname, search] = href.split("?")
  var [protocol] = pathname.split("://")
  var [host] = pathname.replace(protocol + "://", "").split("/")
  const origin =
    typeof window !== "undefined"
      ? window.location.origin
      : protocol + "://" + host
  return [pathname.replace(origin, ""), search]
}

const pathnameAndSearchFromLocation = location => {
  var pathname = location.pathname
  var search = location.search
  if (pathname === "/") {
    return pathnameAndSearchFromHref(location.href)
  }
  return [pathname, search]
}

export const hrefToPosition = href => {
  if (!href) return {}
  const [pathname, search] = pathnameAndSearchFromHref(href)
  return locationToPosition({ pathname: pathname, search: search })
}

export const getDeltaKey = delta => {
  if (typeof delta === "undefined" || delta === null) return null
  const keys = Object.keys(delta)
  if (!Array.isArray(keys)) return null
  return keys[0]
}

/* returns position */

export const positionForDelta = (currentPosition = {}, delta) => {
  if (typeof delta === "undefined" || delta === null) return currentPosition
  const deltaKey = getDeltaKey(delta)
  var newPosition = JSON.parse(JSON.stringify(currentPosition))
  if (!Array.isArray(delta[deltaKey])) return currentPosition
  const keysWithPositionsToRemove = Object.keys(positionsToRemove)
  if (
    keysWithPositionsToRemove.indexOf(deltaKey) > -1 &&
    delta[deltaKey][0].name === positionsToRemove[deltaKey]
  ) {
    delete newPosition[deltaKey]
    return newPosition
  }
  newPosition[deltaKey] = delta[deltaKey].map(deltaItem => {
    if (Array.isArray(deltaItem)) return deltaItem.map(item => item.name)
    return deltaItem.name
  })
  return newPosition
}

/* return pathname */

export const pathnameFromShapeCoord = shapeCoord => {
  var shapeCrumbs = shapeCoord ? JSON.parse(JSON.stringify(shapeCoord)) : []
  var shapeMapping = categoryToUrl[JSON.stringify(shapeCrumbs)]
  var loop = typeof shapeMapping === "undefined"
  while (loop) {
    shapeCrumbs.pop()
    shapeMapping = categoryToUrl[JSON.stringify(shapeCrumbs)]
    if (typeof shapeMapping !== "undefined") loop = false
    if (shapeCrumbs.length < 1) loop = false
  }
  return [
    typeof shapeMapping !== "undefined" ? shapeMapping : "/",
    arrayExcludeStrings(shapeCoord, shapeCrumbs),
  ]
}

/* returns search */

export const searchFromParams = params => {
  var newParams = {}
  params.forEach((value, key) => {
    newParams[key] = value
  })
  const newSearch = Object.keys(newParams)
    .map(key => key + "=" + newParams[key])
    .join("&")
  return newSearch.length > 0 ? "?" + newSearch : ""
}

/* returns location */

export const locationFromPosition = (position, currentLocation) => {
  const [pathname, shapeCrumbs] = pathnameFromShapeCoord(position.shape)
  var newPosition = cleanupPosition(position)
  if (shapeCrumbs.length === 0) delete newPosition.shape
  else newPosition.shape = JSON.parse(JSON.stringify(shapeCrumbs))
  var result =
    typeof currentLocation !== "undefined"
      ? JSON.parse(JSON.stringify(currentLocation))
      : {}
  result.pathname = pathname
  result.search =
    Object.keys(newPosition).length > 0
      ? "?" + serializePosition(newPosition)
      : ""
  return result
}

export const locationSetSearch = (currentLocation = {}, searchString) => {
  var newLocation = JSON.parse(JSON.stringify(currentLocation))
  newLocation.search = searchString
  return newLocation
}

export const locationSetParam = (
  currentLocation = {},
  paramKey,
  paramValue
) => {
  var params = new URLSearchParams(
    currentLocation.search ? currentLocation.search : ""
  )
  params.set(paramKey, paramValue)
  return locationSetSearch(currentLocation, searchFromParams(params))
}

export const locationDeleteParam = (
  currentLocation = {},
  paramKey,
  paramValue
) => {
  var params = new URLSearchParams(
    currentLocation.search ? currentLocation.search : ""
  )
  params.delete(paramKey, paramValue)
  return locationSetSearch(currentLocation, searchFromParams(params))
}

/* returns Link */

export const linkFromLocation = location => {
  if (
    typeof location === "undefined" ||
    location === null ||
    typeof location !== "object"
  )
    return "/"
  var pathname =
    location.pathname && typeof location.pathname === "string"
      ? location.pathname
      : "/"
  var search =
    location.search && typeof location.search === "string"
      ? location.search
      : ""
  return pathname.concat(search)
}

export const linkForDeltaFromPosition = (currentPosition = {}, delta) =>
  linkFromLocation(
    locationFromPosition(positionForDelta(currentPosition, delta))
  )

export const linkSetParam = (currentLocation = {}, paramKey, paramValue) =>
  linkFromLocation(locationSetParam(currentLocation, paramKey, paramValue))

export const linkDeleteParam = (currentLocation = {}, paramKey) =>
  linkFromLocation(locationDeleteParam(currentLocation, paramKey))

/* returns dir */

export const dirFromPosition = (position, deploymentId) => {
  const host = `https://s3.eu-central-1.amazonaws.com/vestigio.tmp/dev/products/${deploymentId}/`
  var result = host
  var crumbs = []
  var coord = ""
  if (position.shape) {
    crumbs = JSON.parse(JSON.stringify(position.shape))
    coord = crumbs.pop()
    if (Array.isArray(coord)) {
      if (coord.length === 0) coord = crumbs.pop()
      else coord = coord.pop()
    }
    if (coord !== null && typeof coord !== "undefined")
      result = result + "shape/" + coord + "/"
  }
  if (position.color) {
    crumbs = JSON.parse(JSON.stringify(position.color))
    coord = crumbs.pop()
    if (Array.isArray(coord)) {
      if (coord.length === 0) coord = crumbs.pop()
      else coord = coord.pop()
    }
    if (coord !== null && typeof coord !== "undefined")
      result = result + "color/" + coord + "/"
  }
  if (position.pattern) {
    crumbs = JSON.parse(JSON.stringify(position.pattern))
    coord = crumbs.pop()
    if (Array.isArray(coord)) {
      if (coord.length === 0) coord = crumbs.pop()
      else coord = coord.pop()
    }
    if (coord !== null && typeof coord !== "undefined")
      result = result + "pattern/" + coord + "/"
  }
  return result
}

/* Legacy aliases */

export const getPathnameString = location => linkFromLocation(location)
export const addParamToSearch = (currentLocation, paramKey, paramValue) =>
  linkSetParam(currentLocation, paramKey, paramValue)
export const changeParamInSearch = (currentLocation, paramKey, paramValue) =>
  linkSetParam(currentLocation, paramKey, paramValue)
export const removeParamFromSearch = (currentLocation, paramKey) =>
  linkDeleteParam(currentLocation, paramKey)
export const getLocationWithParam = (currentLocation, paramKey, paramValue) =>
  locationSetParam(currentLocation, paramKey, paramValue)
export const positionToLocation = (currentPosition, currentLocation) =>
  locationFromPosition(currentPosition, currentLocation)
export const deltaUrlFromPosition = (currentPosition, delta) =>
  linkForDeltaFromPosition(currentPosition, delta)
export const getNewPosition = (currentPosition, delta) =>
  positionForDelta(currentPosition, delta)
