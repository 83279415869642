import React, { useState, useRef, useEffect, forwardRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Heap, ProductCard, ResultsHeader } from "./index"
import useNavigator from "../hooks/useNavigator"
import useDialog from "../hooks/useDialog"
import usePanel from "../hooks/usePanel"
import useProducts from "../hooks/useProducts"
import useLocale from "../hooks/useLocale"

import * as styles from "./results.module.css"

const initialBatch = 6
export const Results = forwardRef(
  ({ hideSearchResults, setHideSearchResults }, ref) => {
    const { locationToRender: location, position, isLivePage } = useNavigator()
    const { isPanelOpen: isOpenFilterPanel } = usePanel()
    const { isDialogOpen: isOpenDialog } = useDialog()
    const { heapView, loadingHeapView: loading } = useProducts({ location })
    const locale = useLocale()

    const heapsToDisplay = heapView.heaps
    const productsToDisplay = heapView.loadingMore
      ? heapView.products.concat({ id: "_dummy" })
      : heapView.products
    const idsRef = useRef()

    const [loaded, _setLoaded] = useState()
    const loadedRef = useRef()
    const setLoaded = index => {
      var currentLoaded =
        typeof loadedRef.current === "undefined" ? 0 : loadedRef.current
      loadedRef.current = Math.max(currentLoaded, index)
      _setLoaded(loadedRef.current)
    }

    useEffect(() => {
      const newIds = heapView.products.map(product => product.id)
      if (JSON.stringify(idsRef.current) !== JSON.stringify(newIds)) {
        const filtered = idsRef.current
          ? newIds.filter(
              newId => newId === idsRef.current[idsRef.current.indexOf(newId)]
            )
          : []
        loadedRef.current = filtered.length
        idsRef.current = newIds
        setLoaded(loadedRef.current)
      }
    }, [heapView.products])

    return (
      <div
        ref={ref}
        className={classNames(styles.searchResultsWrapper, {
          [styles.hideSearchResults]: hideSearchResults,
          [styles.filterPanelOpen]: isOpenFilterPanel || isOpenDialog,
        })}
      >
        <div
          className={classNames(styles.searchResults, {
            [styles.searchResultsWithOpenFilterPanel]: isOpenFilterPanel,
          })}
        >
          <ResultsHeader loading={loading} productCount={heapView.count} />
          <div className={styles.searchResultsGrid}>
            {heapsToDisplay.length > 0
              ? heapsToDisplay.map(heap => (
                  <Heap
                    key={heap.id}
                    position={position}
                    heap={heap}
                    locale={locale}
                  />
                ))
              : null}

            {productsToDisplay.length > 0
              ? productsToDisplay.map(product => (
                  <div className={styles.pcPair} key={product.id}>
                    <ProductCard
                      key={product.id}
                      product={product}
                      isLivePage={isLivePage}
                      location={location}
                      lastLoadedIndex={loaded}
                      setLoadedIndex={setLoaded}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    )
  }
)

Results.propTypes = {
  hideSearchResults: PropTypes.bool,
  setHideSearchResults: PropTypes.func,
}
