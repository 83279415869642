import React from "react"

export const CameraIcon = () => (
  <svg width={154} height={114} viewBox="0 0 154 114" role="img">
    <mask
      id="a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={154}
      height={114}
      fill="#000"
    >
      <path fill="#fff" d="M0 0H154V114H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102 2H52a5 5 0 00-5 5 5 5 0 01-5 5H12C6.477 12 2 16.477 2 22v80c0 5.523 4.477 10 10 10h130c5.523 0 10-4.477 10-10V22c0-5.523-4.477-10-10-10h-30a5 5 0 01-5-5 5 5 0 00-5-5z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102 2H52a5 5 0 00-5 5 5 5 0 01-5 5H12C6.477 12 2 16.477 2 22v80c0 5.523 4.477 10 10 10h130c5.523 0 10-4.477 10-10V22c0-5.523-4.477-10-10-10h-30a5 5 0 01-5-5 5 5 0 00-5-5z"
      fill="#fff"
    />
    <path
      d="M52 4h50V0H52v4zm-3 3a3 3 0 013-3V0a7 7 0 00-7 7h4zm-7 7a7 7 0 007-7h-4a3 3 0 01-3 3v4zm-30 0h30v-4H12v4zm-8 8a8 8 0 018-8v-4C5.373 10 0 15.373 0 22h4zm0 80V22H0v80h4zm8 8a8 8 0 01-8-8H0c0 6.627 5.373 12 12 12v-4zm130 0H12v4h130v-4zm8-8a8 8 0 01-8 8v4c6.627 0 12-5.373 12-12h-4zm0-80v80h4V22h-4zm-8-8a8 8 0 018 8h4c0-6.627-5.373-12-12-12v4zm-30 0h30v-4h-30v4zm-7-7a7 7 0 007 7v-4a3 3 0 01-3-3h-4zm-3-3a3 3 0 013 3h4a7 7 0 00-7-7v4z"
      fill="#CCC"
      mask="url(#a)"
    />
    <circle
      cx={77}
      cy={59.1992}
      r={36}
      stroke="#CCC"
      strokeWidth={2}
      fill="none"
    />
    <circle
      cx={77}
      cy={59.1992}
      r={29}
      stroke="#CCC"
      strokeWidth={2}
      fill="none"
    />
    <circle
      cx={17}
      cy={28}
      r={4}
      fill="#fff"
      stroke="#CCC"
      strokeWidth={2}
      fill="none"
    />
    <circle
      cx={77}
      cy={59.1992}
      r={11}
      stroke="#CCC"
      strokeWidth={2}
      fill="#FFF"
    />
  </svg>
)
