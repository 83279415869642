import React from "react"
import { nonPositionParams } from "../../utils/constants"
import { changeParamInSearch } from "../../utils/positionUtils"
import {
  ChangeProductButton,
  directions,
} from "../../components/buttons/changeProductButton"
import * as styles from "./navigationArrows.module.css"

export const NavigationArrows = ({
  location,
  previousProduct,
  nextProduct,
}) => {
  return (
    <div className={styles.navigationArrows}>
      <div className={styles.backArrow}>
        {previousProduct && (
          <ChangeProductButton
            to={changeParamInSearch(
              location,
              nonPositionParams.PRODUCT,
              previousProduct.id
            )}
            direction={directions.BACK}
          />
        )}
      </div>
      <div className={styles.forwardArrow}>
        {nextProduct && (
          <ChangeProductButton
            to={changeParamInSearch(
              location,
              nonPositionParams.PRODUCT,
              nextProduct.id
            )}
            direction={directions.FORWARD}
          />
        )}
      </div>
    </div>
  )
}
