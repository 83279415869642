export const smallMobile = 360
export const smallMobileUp = 361

export const largeMobile = 576
export const largeMobileUp = 577

export const smallTablet = 768
export const smallTabletUp = 769

export const largeTablet = 1024
export const largeTabletUp = 1025

export const desktop = 1366
export const desktopUp = 1367
