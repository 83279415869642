export const getFiltribute = (item, multiselect) => {
  return {
    id: item.rule.id,
    visualizationType:
      item.visualization && item.visualization.type
        ? item.visualization.type
        : "",
    imageUrl:
      item.visualization && item.visualization.url
        ? item.visualization.url
        : "#",
    text:
      item.visualization && item.visualization.type === "text"
        ? item.visualization.value
        : "",
    numerical:
      item.visualization && item.visualization.type === "numerical"
        ? item.visualization.value
        : -1,
    caption: item.caption,
    delta: item.delta,
    selected: item.selected,
    opens: item.opens_fp,
    multiselect: multiselect ? true : false,
    count: item.count,
  }
}
