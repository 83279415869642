import { useRef } from "react"
import { useLocation } from "@reach/router"
import { useSelector, useDispatch } from "react-redux"
import useFilterPanels from "./useFilterPanels"
import {
  locationToPosition,
  positionToLocation,
  historyWrapper,
} from "../utils"
import {
  getNonPositionParamsFromLocation,
  removeKeysFromPosition,
} from "../utils/positionUtils"
import { getPanelNames } from "../features/filters/utils/getPanelNames"
import { arrayExcludeStrings } from "../utils/arrayUtils"
import { getLocationWithParam } from "../utils/positionUtils"
import { saveHistory } from "../store"

const useNavigator = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const locationRef = useRef()
  locationRef.current = location
  const history = useSelector(store => store.history)
  const historyRef = useRef()
  historyRef.current = history
  const { filterPanelsByPosition } = useFilterPanels({ location })

  const isLivePage =
    typeof window === "object" && typeof window.location !== "undefined"
  const locationFromRedux =
    history && history.location ? history.location : location

  const getLocationToRender = () => {
    var result = locationFromRedux
    const nonPositionParams =
      getNonPositionParamsFromLocation(locationFromRedux)
    if (filterPanelsByPosition && filterPanelsByPosition.data) {
      const panelsInPosition = getPanelNames(
        locationToPosition(locationFromRedux)
      )
      const panelsInFilterPanels = getPanelNames(filterPanelsByPosition.data)
      if (panelsInFilterPanels.length > 0) {
        const panelsToRemoveFromPosition = arrayExcludeStrings(
          panelsInPosition,
          panelsInFilterPanels
        )
        const newPosition = removeKeysFromPosition(
          locationToPosition(locationFromRedux),
          panelsToRemoveFromPosition
        )
        result = positionToLocation(newPosition, result)
        Object.keys(nonPositionParams).map(paramKey => {
          result = getLocationWithParam(
            result,
            paramKey,
            nonPositionParams[paramKey]
          )
          return false
        })
      }
    }
    // --- MOCK BEGIN ----
    if (result && result.href) {
      const [server, search] = result.href.split(result.pathname)
      result.search = search
    }
    // --- MOCK END ------
    return result
  }

  const getDirection = (currentHistory, recordedHistory) => {
    const direction = { fwd: false, back: false }
    if (
      currentHistory &&
      currentHistory.state &&
      recordedHistory &&
      recordedHistory.state
    ) {
      const currentKey = currentHistory.state.key ? currentHistory.state.key : 0
      const lastKey = recordedHistory.state.key ? recordedHistory.state.key : 0
      direction.fwd = currentKey > lastKey
      direction.back = currentKey < lastKey
    }
    return direction
  }

  const saveState = () => {
    const direction = getDirection(historyWrapper, historyRef.current)
    if (direction.fwd || direction.back) {
      dispatch(saveHistory(locationRef.current, historyWrapper.state))
    }
    /*
    else if (locationRef.current.href !== historyRef.current.location.href) {
      dispatch(saveHistory(historyWrapper.location, historyWrapper.state))
    }
    */
  }

  const locationToRender = getLocationToRender()

  return {
    isLivePage,
    locationFromAddressBar: location,
    locationFromRedux,
    position: locationToPosition(locationToRender),
    location: locationToRender,
    locationToRender,
    saveState,
  }
}

export default useNavigator
