import React from "react"

export const BackIcon = props => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <circle cx={24} cy={24} r={15} fill="#7DB5C7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0024 31.4841C38.273 29.2814 39 26.7256 39 24C39 23.2791 38.9491 22.57 38.8508 21.8762C34.2544 23.7607 30.1604 22.8957 25.8306 21.8212C24.698 22.2419 23.3524 22.4908 22.1787 22.708C20.5862 23.0026 19.3099 23.2387 19.3099 23.7659C19.3099 23.9191 19.2808 24.1617 19.2428 24.4792C19.063 25.9803 18.6828 29.1553 20.225 32.4602C21.3639 34.9007 23.1613 35.8223 26.7839 35.8223C31.4766 35.8223 34.4648 33.6455 37.0024 31.4841Z"
        fill="#7595AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3097 28.5114C38.7582 27.0875 39 25.572 39 24C39 22.8114 38.8617 21.655 38.6004 20.5462C33.6962 22.8536 29.3944 21.9538 24.8306 20.8211C23.698 21.2418 22.3524 21.4908 21.1787 21.7079C19.5862 22.0025 18.3099 22.2387 18.3099 22.7659C18.3099 22.919 18.2808 23.1616 18.2428 23.4791C18.063 24.9802 17.6828 28.1553 19.225 31.4602C20.3639 33.9007 22.1613 34.8223 25.7839 34.8223C31.0142 34.8223 34.1271 32.1181 36.8588 29.7452C37.3514 29.3172 37.8317 28.9 38.3097 28.5114Z"
        fill="#648296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2594 19.3318L21.6274 27.1131C21.6274 27.1131 22.9729 18.8047 20.4453 14.8724C26.5195 17.1899 29.8471 14.6384 33.0538 12.1796C33.0843 12.1562 33.1148 12.1328 33.1453 12.1094C35.5074 13.9288 37.3121 16.4363 38.2594 19.3318Z"
        fill="#DE8036"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9138 25.6172C38.9708 25.0859 39 24.5464 39 24C39 21.5867 38.4301 19.3065 37.4175 17.2867C33.0301 18.3183 27.3825 16.4092 22.9171 14.8996C21.3711 14.377 19.9668 13.9023 18.8055 13.6141C16.6512 14.4143 17.1547 17.2508 17.4291 18.797C17.4947 19.1665 17.5472 19.4624 17.5472 19.6391C17.5472 19.7922 17.5181 20.0348 17.4801 20.3523C17.3003 21.8534 16.9201 25.0284 18.4623 28.3333C19.6012 30.7739 21.3986 31.6954 25.0212 31.6954C28.9575 31.6954 32.6604 29.3852 35.7259 27.4726C36.8874 26.748 37.9573 26.0804 38.9138 25.6172Z" 
        fill="#FF9846"
      />
      
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9138 25.6174C38.9708 25.0861 39 24.5465 39 24C39 21.5868 38.4301 19.3067 37.4176 17.2869C33.8502 18.1257 29.4499 17.0203 25.5292 15.7664C25.0968 20.1783 26.2226 24.3981 30.9998 26.5C32.22 27.0369 33.202 27.731 33.9806 28.5466C34.5821 28.1864 35.1647 27.8229 35.7259 27.4728C36.8874 26.7482 37.9573 26.0806 38.9138 25.6174Z" 
        fill="#FDB046"
      />
    </svg>
  )
}