import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import * as styles from "./button.module.css"

export const Button = ({
  backgroundColor,
  border,
  color,
  fontSize,
  fontWeight,
  outline,
  height,
  width,
  children,
  onClickHandler,
  theme,
  disabled,
  minimized,
  pressed,
  ...rest
}) => {
  const buttonStyle = theme
    ? null
    : {
        backgroundColor,
        color,
        border,
        fontSize,
        fontWeight,
        outline,
        height,
        width,
      }

  const buttonClassName = classNames(styles.buttonCommonStyles, {
    [styles[theme]]: theme,
    [styles.disabled]: disabled,
    [styles.minimized]: minimized,
    [styles.pressed]: pressed,
  })

  return (
    <button
      className={buttonClassName}
      style={buttonStyle}
      onClick={onClickHandler}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  onClickHandler: PropTypes.func,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  minimized: PropTypes.bool,
  pressed: PropTypes.bool,
}

Button.defaultProps = {
  disabled: false,
  minimized: false,
  pressed: false,
}
