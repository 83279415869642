import { useSelector } from "react-redux"
import { defaultLocale } from "../utils"

const useLocale = () => {
  return useSelector(store =>
    store.history.locale ? store.history.locale : defaultLocale
  )
}

export default useLocale
