import React from "react"

// import * as styles from "./basicComponent.module.css"

export const Spacer = ({ width, height }) => {
  var style = {}
  if (width) style.width = width
  if (height) style.height = height

  return <div data-testid="spacer" style={style} />
}
