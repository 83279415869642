import React from "react"
import PropTypes from "prop-types"
import { Icon, icons, SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"

export const CloseDialogButton = ({ to }) => (
  <SafeLink to={to} action={safeLinkActions.CLOSE_DIALOG}>
    <Icon icon={icons.close} />
  </SafeLink>
)

CloseDialogButton.propTypes = {
  to: PropTypes.string.isRequired,
}
