import React, { forwardRef } from "react"
import classNames from "classnames"
import { TextSearchButton } from "./index"
import { ariaRoles, suggestionsArray } from "../utils/constants"
import useLocale from "../hooks/useLocale"
import { labels } from "../utils/labelConstants"

import * as styles from "./dropdown.module.css"

export const Dropdown = forwardRef(({ searchText, landing = false }, ref) => {
  const locale = useLocale()
  const suggestions = Object.keys(suggestionsArray).filter(item =>
    item.includes(searchText)
  )

  const renderSuggestion = item => {
    const suggestionSplit = item.split(searchText)
    const length = suggestionSplit.length

    return suggestionSplit.map((phrase, index) => (
      <p key={phrase + index}>
        <span className={styles.searchTextHighlighted}>{phrase}</span>
        {index !== length - 1 ? <span>{searchText}</span> : null}
      </p>
    ))
  }

  return (
    <div
      ref={ref}
      role={ariaRoles.DIALOG}
      className={classNames(styles.dropdown, { [styles.landing]: landing })}
    >
      {suggestions.length > 0 ? (
        suggestions.map(item => (
          <div key={item} className={styles.suggestionLink}>
            <TextSearchButton pathname={suggestionsArray[item].link}>
              <img
                className={styles.suggestionImage}
                src={suggestionsArray[item].icon}
                alt={item}
              />
              {renderSuggestion(item)}
            </TextSearchButton>
          </div>
        ))
      ) : (
        <div className={styles.noSuggestions}>
          {labels[locale].SEARCH_QUERY_NO_SUGGESTIONS}
        </div>
      )}
    </div>
  )
})
