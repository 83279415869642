import { getFiltributes, getStackSize } from "./getFiltributes"
import { getPanelCaption } from "./getCaption"
import { getPanelName } from "./getPanelName"
import { getButtonImgExists, getButtonImg, getButtonImgs } from "./buttonImage"
import { getButton } from "./button"
import { getText, getTexts } from "./panelText"
import {
  getMultichoiceSwitch,
  getMultiselect,
  getSwitchDelta,
} from "./multichoice"
import { getParent } from "./getParent"
import { getSubPanels } from "./mergePanels"
import { getButtonBackground } from "./buttonBackground"

export const createPanelData = (data, panelKey) => {
  var result = {
    panelKey: panelKey,
    panelName: getPanelName(data, panelKey),
    parent: getParent(data, panelKey),
    multichoiceSwitch: getMultichoiceSwitch(data, panelKey),
    switchDelta: getSwitchDelta(data, panelKey) || {},
    multiselect: getMultiselect(data, panelKey) || false,
    filtributes: getFiltributes(data, panelKey),
  }
  result.buttonImgExists = getButtonImgExists(data, panelKey)
  result.button = getButton(data, panelKey)
  result.buttonImg = getButtonImg(data, panelKey)
  result.buttonImgs = getButtonImgs(data, panelKey)
  result.buttonBackground = getButtonBackground(data, panelKey)
  result.text = getText(data, panelKey)
  result.texts = getTexts(data, panelKey)
  result.caption = getPanelCaption(data, panelKey)
  result.stackSize = getStackSize(data, panelKey)
  const subPanels = getSubPanels(data, panelKey, createPanelData)
  if (subPanels) result = { ...result, ...subPanels }
  return result
}
