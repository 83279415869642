import React, { useState } from "react"
import PropTypes from "prop-types"
import { ariaRoles, selectors, lockedPanels } from "../../utils/constants"
import { useMobile } from "../../hooks"
import { locationToPosition } from "../../utils/helpers"
import {
  BrandSelector,
  CategorySelector,
  CategoryPreview,
  ElementSelector,
  ColorSelector,
  NewFilterSelector,
  PatternSelector,
  SizeSelector,
  VendorSelector,
  PriceSelector,
  MultiselectSwitch,
} from "./index.js"
import {
  SectionHeader,
  ChooseValueButton,
  CategoryUpButton,
  RemovePanelButton,
} from "../index.js"

import * as styles from "./selector.module.css"

export const Selector = ({ location, filterPanelObj }) => {
  const [pages, setPages] = useState({})
  const position = locationToPosition(location)
  const isMobile = useMobile()
  const renderRemoveButton = lockedPanels.indexOf(filterPanelObj.name) < 0
  const pageArray = pages[filterPanelObj.key] ? pages[filterPanelObj.key] : [0]

  const handlePageChange = pageArray => {
    const panelKey = filterPanelObj.key

    const newPages = JSON.parse(JSON.stringify(pages))
    newPages[panelKey] = pageArray
    setPages(newPages)
  }

  var previewInstance = null
  var selectorInstance = null
  switch (filterPanelObj.selector) {
    case selectors.NEW_FILTER:
      selectorInstance = <NewFilterSelector />
      break
    case selectors.CATEGORY:
      previewInstance = <CategoryPreview />
      selectorInstance = (
        <CategorySelector
          pageArray={pageArray}
          onPageChange={handlePageChange}
        />
      )
      break
    case selectors.ELEMENT:
      selectorInstance = <ElementSelector />
      break
    case selectors.COLOR:
      selectorInstance = <ColorSelector />
      break
    case selectors.PATTERN:
      selectorInstance = <PatternSelector />
      break
    case selectors.SIZE:
      selectorInstance = <SizeSelector />
      break
    case selectors.BRAND:
      selectorInstance = <VendorSelector />
      break
    case selectors.VENDOR:
      selectorInstance = <VendorSelector />
      break
    case selectors.PRICE:
      selectorInstance = <PriceSelector />
      break
    default:
      break
  }

  return (
    <div className={styles.selectorWrapper}>
      <div role={ariaRoles.GROUP} className={styles.selector}>
        {renderRemoveButton && isMobile && (
          <>
            <SectionHeader />
            <RemovePanelButton
              pathname={filterPanelObj.getRemoveUrl(location)}
              panelId={filterPanelObj.key}
            />
          </>
        )}
        {filterPanelObj.parent && filterPanelObj.parent.caption && (
          <>
            <SectionHeader />
            <CategoryUpButton
              pathname={filterPanelObj.parent.getLinkUrl(position)}
              parent={filterPanelObj.parent}
              position={position}
            />
          </>
        )}
        {previewInstance}
        {selectorInstance}
        {filterPanelObj.showMultichoiceSwitch === true && (
          <>
            <SectionHeader />
            <ChooseValueButton
              pathname={filterPanelObj.getSwitchDeltaUrl(position)}
              enabled
            >
              <MultiselectSwitch switchOn={filterPanelObj.isMultiselect} />
            </ChooseValueButton>
          </>
        )}
        <div style={{ height: "20px" }} />
      </div>
    </div>
  )
}

Selector.propTypes = {
  location: PropTypes.object.isRequired,
  filterPanelObj: PropTypes.object.isRequired,
}
