import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  FilterPanel,
  Header,
  Results,
  Toolbox,
  Dialog,
  Dropdown,
  TextBasedNavigation,
  UploadsObj,
  fetchPhotoSearchRequest,
  pushHistory,
  screenWidthBreakPointForFilterPanelInMobile,
  screenWidthBreakPointForFilterPanelInDesktop,
  dialogNames,
  useHasMounted,
  setIsMobile,
  Loader,
  historyWrapper,
  LoginScreen,
} from "./index"

import useNavigator from "../hooks/useNavigator"
import { serializePosition } from "../utils/positionUtils"
import useSorting from "../hooks/useSorting"
import useGrouping from "../hooks/useGrouping"
import useProducts from "../hooks/useProducts"
import usePanel from "../hooks/usePanel"
import useDialog from "../hooks/useDialog"

import { gasefiApp, loadingCover } from "./app.module.css"

const App = ({
  staticPathname,
  homeDir,
  dispatch,
  historyFromRedux,
  photoSearch,
}) => {
  const navigator = useNavigator()
  const locationToRender = navigator.locationToRender

  const appRef = useRef({})
  const toolboxRef = useRef()
  const filterPanelRef = useRef()
  const resultsRef = useRef()
  const dialogRef = useRef()
  const dropdownRef = useRef()
  const searchTextRef = useRef()
  const scrollTimoutRef = useRef(false)

  const { sortingIndex, setSortingIndex } = useSorting()
  const sortingIndexRef = useRef()
  sortingIndexRef.current = sortingIndex
  const { groupingIndex, setGroupingIndex } = useGrouping()
  const groupingIndexRef = useRef()
  groupingIndexRef.current = groupingIndex

  const [hasMounted] = useHasMounted()
  const [openDropdown, setOpenDropdown] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [uploads, _setUploads] = useState()
  const uploadsRef = useRef()
  const setUploads = uploadsObj => {
    uploadsRef.current = uploadsObj
    _setUploads(uploadsObj)
  }

  const { heapView, loadingHeapView, loadMoreProducts } = useProducts({
    location: navigator.location,
  })
  const heapViewRef = useRef()
  heapViewRef.current = heapView

  const { isPanelOpen } = usePanel()
  const { isDialogOpen, openDialog, closeDialog } = useDialog()

  historyWrapper.verifyState()
  navigator.saveState()

  if (navigator.isLivePage) {
    var isMobile = true
    if (window.innerWidth > screenWidthBreakPointForFilterPanelInMobile)
      isMobile = false
    if (isMobile !== historyFromRedux.isMobile) dispatch(setIsMobile(isMobile))
  }

  const position = navigator.position
  const serializedPosition = serializePosition(position)
  let timeoutId

  useEffect(() => {
    appRef.current.scrollTop = 0
  }, [serializedPosition])

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (
      appRef.current !== null &&
      typeof appRef.current.addEventListener === "function"
    )
      appRef.current.addEventListener("scroll", handleScroll)
    return () => {
      if (appRef.current !== null)
        appRef.current.removeEventListener("scroll", handleScroll)
    }
  }, [appRef.current])

  useEffect(() => {
    if (!photoSearch) {
      return false
    }
    setUploads(new UploadsObj(photoSearch))
  }, [photoSearch])

  const closeDropdown = () => {
    setSearchText("")
    setOpenDropdown(null)
  }

  const handleScroll = event => {
    if (!scrollTimoutRef.current) {
      scrollTimoutRef.current = true
      timeoutId = setTimeout(() => {
        if (appRef.current && resultsRef.current) {
          if (
            appRef.current.scrollTop >
            resultsRef.current.offsetHeight - appRef.current.offsetHeight * 3
          ) {
            loadMoreProducts()
          }
        }
        scrollTimoutRef.current = false
      }, 500)
    }
  }

  const handleClick = event => {
    if (dropdownRef.current) {
      if (!dropdownRef.current.contains(event.target)) {
        if (
          searchTextRef.current &&
          !searchTextRef.current.contains(event.target)
        )
          closeDropdown()
      }
    } else if (dialogRef.current) {
      if (!dialogRef.current.contains(event.target)) closeDialog()
    }
  }

  const handleSearchTextChange = newSearchText => {
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }
    if (newSearchText && !openDropdown) {
      setOpenDropdown("textSearch")
    } else if (!newSearchText && openDropdown) {
      closeDropdown()
    }
  }

  const isDropdownOpen = openDropdown !== null

  if (navigator.isLivePage) {
    document.body.style.overflow = (
      isMobile ? isDialogOpen || isPanelOpen || isDropdownOpen : isDialogOpen
    )
      ? "hidden"
      : "initial"
  }

  const authToken =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("gasefiToken")
      : null
  if (typeof localStorage !== "undefined" && !authToken)
    return (
      <div key={Date.now()}>
        <LoginScreen />
      </div>
    )

  return (
    <div
      className={gasefiApp}
      key={hasMounted ? "dynamic" : "static"}
      ref={appRef}
    >
      <Results ref={resultsRef} />
      {navigator.isLivePage && loadingHeapView && (
        <div className={loadingCover}>
          <Loader />
        </div>
      )}
      <Toolbox ref={toolboxRef} />
      {isPanelOpen ? (
        <FilterPanel ref={filterPanelRef} />
      ) : (
        <div style={{ display: "none" }} />
      )}
      <Header
        homeDir={homeDir}
        isDropdownOpen={isDropdownOpen}
        searchText={searchText}
        handleSearchTextChange={newSearchText => {
          handleSearchTextChange(newSearchText)
        }}
        ref={searchTextRef}
      />
      {isDialogOpen && openDialog === dialogNames.MORE_OPTIONS ? (
        <Dialog data={heapView} ref={dialogRef} />
      ) : (
        <div style={{ display: "none" }} />
      )}
      {openDialog && openDialog === dialogNames.UPLOADS && (
        <Dialog data={uploads} ref={dialogRef} />
      )}
      {openDropdown && <Dropdown searchText={searchText} ref={dropdownRef} />}
    </div>
  )
}

/*
      <TextBasedNavigation 
        location={locationToRender}
        filterPanels={filterPanels}
      />
*/

const mapStateToProps = store => {
  return (store, ownProps) => {
    return {
      historyFromRedux: store.history,
      photoSearch: store.photoSearch,
    }
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

export const AppRedux = connect(mapStateToProps, mapDispatchToProps)(App)

App.propTypes = {
  staticData: PropTypes.object,
  staticPathname: PropTypes.string,
}
