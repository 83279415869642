import React from "react"
import * as styles from "./indicators.module.css"
import { emptyImage } from "../../index"

export const ImageIndicator = ({ selection }) => {
  return (
    <div className={styles.indicatorImage}>
      <img
        src={selection.imageUrl ? selection.imageUrl : emptyImage}
        alt={selection.caption}
      />
    </div>
  )
}
