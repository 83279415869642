import { getFiltribute } from "./getFiltribute"
import { getCaption } from "./getCaption"
import { getCollections } from "./getCollections"

const getPanelItems = (data, panelKey) => {
  let result = []
  if (data && data[panelKey] && Array.isArray(data[panelKey].items)) {
    result = [...data[panelKey].items]
  }
  return result
}

const isMultiselect = (data, panelKey) => {
  let result = false
  if (
    data &&
    data._coord &&
    data._coord[panelKey] &&
    Array.isArray(data._coord[panelKey])
  ) {
    result = Array.isArray(
      data._coord[panelKey][data._coord[panelKey].length - 1]
    )
  }
  return result
}

const hasCollections = (data, panelKey) => {
  const panelItems = getPanelItems(data, panelKey)
  return (
    Array.isArray(panelItems) &&
    panelItems[0] &&
    Array.isArray(panelItems[0].sub_panels) &&
    Object.keys(data[panelKey]).length > 0
  )
}

export const getStackSize = (data, panelKey) => {
  let result = 1
  const panelItems = getPanelItems(data, panelKey)
  result = panelItems.filter(filtribute => filtribute.selected === true).length
  return result
}

export const getFiltributes = (data, panelKey) => {
  let filtributes = []
  if (hasCollections(data, panelKey)) {
    filtributes = getCollections(data[panelKey])
  } else {
    const panelItems = getPanelItems(data, panelKey)
    let multiselect = isMultiselect(data, panelKey)
    filtributes = panelItems.map(item => {
      let caption = item.caption
      if (caption == null) {
        caption = getCaption(item)
      }
      return getFiltribute({ ...item, caption }, multiselect)
    })
  }
  return filtributes
}
