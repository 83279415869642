import React, { useState } from "react"
import classNames from "classnames"
import {
  FilterIndicator,
  ImageIndicator,
  ColorIndicator,
  RemovePanelBubble,
  TogglePanelButton,
  FilterLabel,
  FilterIcon,
  lockedPanels,
  screenWidthBreakPointForFilterPanelInMobile,
  indicatorTypes,
  getPathnameString,
} from "../../index"

import * as styles from "./filterButton.module.css"

export const FilterButton = ({ location, openPanel, filterPanel }) => {
  const [showRemoveFilterBubble, setShowRemoveFilterBubble] = useState(false)

  const onMouseEnterHandler = () => {
    if (window.innerWidth > screenWidthBreakPointForFilterPanelInMobile)
      setShowRemoveFilterBubble(true)
  }

  const onMouseLeaveHandler = () => {
    setShowRemoveFilterBubble(false)
  }

  const renderRemoveBubble = lockedPanels.indexOf(filterPanel.key) < 0

  const getIndicator = selection => {
    if (!selection.buttonImageExists)
      return (
        <FilterIcon
          panelId={filterPanel.key}
          isSelected={filterPanel.isOpen(openPanel)}
        />
      )
    switch (selection.indicatorType) {
      case indicatorTypes.IMAGE:
        return <ImageIndicator selection={selection} />
      case indicatorTypes.COLOR:
        return <ColorIndicator selection={selection} />
        break
      default:
        break
    }
    return null
  }

  const panelKey = filterPanel && filterPanel.key ? filterPanel.key : null
  const panelName = filterPanel && filterPanel.name ? filterPanel.name : null
  const panelCaption =
    filterPanel && filterPanel.selection && filterPanel.selection.caption
      ? filterPanel.selection.caption
      : null
  const stackSize = filterPanel.stackSize ? filterPanel.stackSize : 1
  const isSelected =
    typeof filterPanel.isOpen === "function" && filterPanel.isOpen(openPanel)
  const imageUrl =
    filterPanel.selection && filterPanel.selection.imageUrl
      ? filterPanel.selection.imageUrl
      : null
  const button =
    filterPanel.selection && filterPanel.selection.button
      ? filterPanel.selection.button
      : null

  const filterIndicator = (
    <div className={styles.filterButton}>
      <FilterIndicator
        imageUrl={imageUrl}
        caption={panelCaption}
        panelName={panelKey}
        isSelected={isSelected}
        stackSize={stackSize}
        isIcon={!filterPanel.selection.buttonImageExists}
        button={button}
      />
      {openPanel !== null ? null : (
        <FilterLabel name={panelName} caption={panelCaption} hideOnMobile />
      )}
    </div>
  )

  const openPanelTab = (
    <>
      <div
        className={classNames(
          styles.filterDisplayBackground,
          styles.showFilterDisplayBackground
        )}
      />
      <div
        className={classNames(
          styles.filterDisplayLink,
          styles.showFilterDisplayLink
        )}
      >
        <svg
          style={{ position: "absolute", top: "-20px" }}
          role="img"
          width={"20px"}
          height={"100px"}
          viewBox="0 0 20 100"
        >
          <path
            fill="#fff"
            d="M0 20c11.046 0 20-8.954 20-20v100c0-11.046-8.954-20-20-20V20Z"
          />
        </svg>
      </div>
    </>
  )

  return (
    <div className={styles.filterPanelWrapper}>
      <div
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        <TogglePanelButton
          pathname={getPathnameString(location)}
          panelId={filterPanel.key}
        >
          {filterIndicator}
        </TogglePanelButton>
        {renderRemoveBubble && (
          <RemovePanelBubble
            pathname={filterPanel.getRemoveUrl(location)}
            panelId={filterPanel.key}
            isVisible={showRemoveFilterBubble}
          />
        )}
        {filterPanel.stackSize > 1 && (
          <div className={styles.stackIndicator}>{filterPanel.stackSize}</div>
        )}
      </div>
      {filterPanel.isOpen(openPanel) ? openPanelTab : null}
    </div>
  )
}
