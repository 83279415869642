import axios from "axios"
import { errors } from "./constants"
import { historyWrapper } from "../components/index"
import md5 from "js-md5"

export class axiosWrapper {
  constructor(authErrorCallback) {
    this.baseURL = "https://sandbox-api.vestigiotools.com/"
    this.setAxiosInstanceWithoutHeaders()
    this.authErrorCallback = authErrorCallback
  }

  setAxiosInstanceWithoutHeaders() {
    this.axiosInstance = axios.create({ baseURL: this.baseURL })
    this.axiosInstance.interceptors.response.use(
      resp => {
        return resp.data
      },
      err => {
        if (
          typeof err.response !== "undefined" &&
          err.response.status === 401
        ) {
          if (
            this.authErrorCallback &&
            typeof this.authErrorCallback === "function"
          ) {
            this.authErrorCallback()
          }
        } else throw err
      }
    )
  }

  setAxiosInstanceWithHeaders(headers) {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers: headers,
    })
    this.axiosInstance.interceptors.response.use(
      resp => {
        return resp.data
      },
      err => {
        if (
          typeof err.response !== "undefined" &&
          err.response.status === 401
        ) {
          if (
            this.authErrorCallback &&
            typeof this.authErrorCallback === "function"
          ) {
            this.authErrorCallback()
          }
        } else throw err
      }
    )
  }

  authenticate(username, password, failCallback = () => {}) {
    const body = {
      username: username,
      password_md5: md5(password),
    }
    this.send({
      url: "login",
      method: "post",
      body: body,
    }).then(response => {
      if (!response.success) {
        failCallback(response)
      }
      if (response.success) {
        localStorage.setItem("gasefiToken", response.token)
        this.addToken(response.token)
        window.location.reload()
        // historyWrapper.pushState({ openPanel: null, level: 0, key: String(Date.now()) },"")
      }
    })
  }

  addToken(authToken) {
    this.setAxiosInstanceWithHeaders({ Authorization: `bearer ${authToken}` })
  }

  send(config) {
    const axiosConfig = {}
    if (!config) return Promise.reject(new Error(errors.missingConfig))
    if (!config.url) return Promise.reject(new Error(errors.missingURL))
    if (!config.method) return Promise.reject(new Error(errors.missingMethod))
    try {
      if (window && window.gsfTesting && window.gsfTesting.baseUrl) {
        if (this.baseURL !== window.gsfTesting.baseUrl) this.baseURL = window.gsfTesting.baseUrl
      } 
    }
    catch {}
    axiosConfig.url = `${this.baseURL}${config.url}`
    axiosConfig.method = config.method
    if (config.body) axiosConfig.data = config.body
    axiosConfig.transformResponse = [
      response => {
        try {
          return JSON.parse(response)
        } catch (e) {
          return {}
        }
      },
    ]
    if (config.authenticated === false) {
      const axiosInstance = axios.create()
      axiosInstance.request(axiosConfig)
    }
    return this.axiosInstance.request(axiosConfig)
  }
}
