import React from "react"
import classNames from "classnames"
import { SafeLink, Icon, icons } from "../index"
import { safeLinkActions, ariaRoles } from "../../utils/constants"

import * as styles from "./removePanelBubble.module.css"

export const RemovePanelBubble = ({ pathname, panelId, isVisible }) => {
  return (
    <SafeLink
      to={pathname}
      action={safeLinkActions.REMOVE_PANEL}
      actionPayload={{ panelId }}
    >
      <div
        className={classNames(styles.removePanelBubble, {
          [styles.isHidden]: !isVisible,
        })}
        role={ariaRoles.BUTTON}
      >
        <Icon icon={icons.recycleBin} size={24} />
      </div>
    </SafeLink>
  )
}
