import { deltaUrlFromPosition } from "./positionUtils"

export class HeapObj {
  constructor(data) {
    this._id = data.id
    this._caption = data.caption
    this._buttonImageUrl = data.icon
    this._images = data.images
    this._count = data.count ? data.count : "0"
    this._heapSet = data.heapSet ? data.heapSet : []
    this._delta = {}
    if (data.delta) {
      const deltaKeys = Object.keys(data.delta)
      if (Array.isArray(deltaKeys)) {
        deltaKeys.map(deltaKey => {
          if (Array.isArray(data.delta[deltaKey]))
            this._delta[deltaKey] = data.delta[deltaKey].map(item => ({
              name: item,
            }))
          return false
        })
      }
    }
  }

  get id() {
    return this._id
  }

  get caption() {
    return this._caption
  }

  get icon() {
    return this._buttonImageUrl
  }

  get buttonImageUrl() {
    return this._buttonImageUrl
  }

  get images() {
    return this._images
  }

  get mutation() {
    return this._delta
  }

  get count() {
    return this._count
  }

  get heapSize() {
    var countNum = this._count
    countNum = countNum.replace("M", "000000")
    countNum = countNum.replace("K", "000")
    const heapSize = Math.max(7, Math.ceil(parseInt(countNum) / 55000))
    return heapSize
  }

  get heapSet() {
    return this._heapSet.slice(0, this.heapSize)
  }

  getLinkUrl = (currentPosition = {}) => {
    return deltaUrlFromPosition(currentPosition, this._delta)
  }
}
