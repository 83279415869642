import React from "react"

function VendorIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 36.935V20H11.005L11 22.156c-.002 1.522 0 3.043 0 14.779 0 1.14.895 2.065 2 2.065H19v-8.806c0-1.211.885-2.194 1.975-2.194h6.05c1.09 0 1.975.983 1.975 2.194V39h6c1.104 0 2-.925 2-2.065Z"
        fill="#2BBDE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11 24.556.001-2.4c0-.554.002-1.108.004-2.156H37v4.564a3.715 3.715 0 0 1-1.75.436c-2.071 0-3.75-1.69-3.75-3.773 0 2.084-1.68 3.773-3.75 3.773-2.07 0-3.75-1.69-3.75-3.773C24 23.311 22.323 25 20.252 25c-2.07 0-3.75-1.69-3.75-3.773-.003 2.07-1.696 3.773-3.749 3.773-.63 0-1.227-.16-1.752-.444Z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        d="M38.999 20.178c0 2.11-1.679 3.822-3.75 3.822-2.07 0-3.75-1.711-3.75-3.822 0 2.11-1.678 3.822-3.75 3.822C25.68 24 24 22.289 24 20.178 24 22.288 22.321 24 20.25 24c-2.07 0-3.75-1.711-3.75-3.822C16.498 22.275 14.805 24 12.75 24c-2.05 0-3.746-1.723-3.749-3.822 0-3.322-.009-3.118.023-3.27.03-.15-.184.38 3.069-7.273.164-.385.537-.635.949-.635h21.916c.412 0 .785.25.95.635 3.255 7.659 3.037 7.123 3.068 7.273.032.152.023-.056.023 3.27Z"
        fill="#2BBDE3"
      />
      <path
        d="M38.999 20.227c0 2.083-1.679 3.773-3.75 3.773-2.07 0-3.75-1.69-3.75-3.773C31.5 22.311 29.822 24 27.75 24 25.68 24 24 22.31 24 20.227 24 22.311 22.323 24 20.252 24c-2.07 0-3.75-1.69-3.75-3.773-.003 2.07-1.696 3.773-3.749 3.773-2.05 0-3.746-1.7-3.75-3.773-.005-3.296-.004-3.098.023-3.227h29.951c.032.15.023-.055.023 3.227Z"
        fill="#32DBDB"
      />
      <path
        d="M34.525 11.85c-6.533-.932-13.127.263-18.461 4.429-.6.468-1.332.721-2.084.721H9c.03-.151-.183.385 3.053-7.357A1.03 1.03 0 0 1 12.998 9h21.805c.41 0 .782.252.945.642l.143.343c.402.961-.364 2.008-1.366 1.865Z"
        fill="#5DEBC3"
      />
    </svg>
  )
}

export { VendorIcon }
