const filterPanels = {
  category: {
    type: "image",
    multiselect: false,
    options: [
      {
        value:
          "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-footwear.png",
        caption: "Footwear",
        id: "footwear_w",
      },
      {
        value:
          "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_highheel.png",
        caption: "Pumps",
        id: "footwear_w_pumps",
      },
      {
        value:
          "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_ballet.png",
        caption: "Ballerinas",
        id: "footwear_w_ballerinas",
      },
      {
        value:
          "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-shoe_sneaker.png",
        caption: "Sneakers",
        id: "footwear_w_sneakers",
      },
    ],
  },
  color: {
    type: "color",
    multiselect: false,
    options: [
      { value: "#FFFFFF", caption: "white", id: "white" },
      // { value: "#AAAAAA", caption: "grey", id: "grey" },
      { value: "#000000", caption: "black", id: "black" },
      // { value: "#DFC1A3", caption: "tan", id: "tan" },
      // { value: "#774717", caption: "brown", id: "brown" },
      { value: "#A6000A", caption: "red", id: "red" },
      // { value: "#801074", caption: "purple", id: "purple" },
      // { value: "#1272A3", caption: "blue", id: "blue" },
      // { value: "#156B44", caption: "green", id: "green" },
      // { value: "#E0B24C", caption: "yellow", id: "yellow" },
    ],
  },
  price: {
    type: "text",
    multiselect: true,
    options: [{ value: "A", caption: "A", id: "a" }],
  },
  brand: {
    type: "text",
    multiselect: true,
    options: [
      { value: "adidas", caption: "Adidas", id: "wfbradidas" },
      { value: "asos", caption: "Asos", id: "wfbrasos" },
      { value: "converse", caption: "Converse", id: "wfbrconverse" },
      { value: "newBallance", caption: "New Ballance", id: "wfbrnewballance" },
      { value: "nike", caption: "Nike", id: "wfbrnike" },
    ],
  },
  vendor: {
    type: "text",
    multiselect: true,
    options: [
      { value: "amazon", caption: "Amazon", id: "wfvdramazon" },
      { value: "asos", caption: "Asos", id: "wfvdrasos" },
      { value: "newChick", caption: "NewChick", id: "wfvdrnewchick" },
      { value: "shopStyle", caption: "ShopStyle", id: "wfvdrshopstyle" },
      { value: "zalando", caption: "Zalando", id: "wfvdrzalando" },
      { value: "zappos", caption: "Zappos", id: "wfvdrzappos" },
    ],
  },
  pattern: {
    type: "pattern",
    multiselect: false,
    options: [
      { value: "#EEEEEE", caption: "Cheetah", id: "anche1" },
      { value: "#DDDDDD", caption: "Leopard", id: "anleo1" },
      { value: "#CCCCCC", caption: "Giraffe", id: "angi1" },
    ],
  },
  size: {
    type: "text",
    multiselect: true,
    options: [
      { value: "33", caption: "33", id: "wsiz33" },
      { value: "34", caption: "34", id: "wsiz34" },
      { value: "35", caption: "35", id: "wsiz35" },
      { value: "36", caption: "36", id: "wsiz36" },
      { value: "37", caption: "37", id: "wsiz37" },
      { value: "38", caption: "38", id: "wsiz38" },
      { value: "39", caption: "39", id: "wsiz39" },
      { value: "40", caption: "40", id: "wsiz40" },
      { value: "41", caption: "41", id: "wsiz41" },
      { value: "42", caption: "42", id: "wsiz42" },
      { value: "43", caption: "43", id: "wsiz43" },
      { value: "44", caption: "44", id: "wsiz44" },
    ],
  },
}

export default filterPanels
