import React from "react"
import classNames from "classnames"
import useLocale from "../../hooks/useLocale"

import { labels, formatProductCount, ResultsDropdown } from "../index"

import * as styles from "./resultsHeader.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

export const ResultsHeader = ({ loading, productCount }) => {
  const locale = useLocale()

  return (
    <div className={styles.resultsHeader}>
      <div className={styles.dropdownGroup}>
        <ResultsDropdown sorting />
      </div>
      <p className={styles.productCount}>
        {loading ? (
          <>
            <span className={labelStyles.headerCount}>...</span>
            <span
              className={classNames(
                labelStyles.headerCountLabel,
                styles.headerCountLabel
              )}
            >
              {labels[locale].LOADING}
            </span>
          </>
        ) : (
          <>
            <span className={labelStyles.headerCount}>
              {formatProductCount(productCount) + " "}
            </span>
            <span
              className={classNames(
                labelStyles.headerCountLabel,
                styles.headerCountLabel
              )}
            >
              {productCount === 1
                ? labels[locale].PRODUCT_FOUND
                : labels[locale].PRODUCTS_FOUND}
            </span>
          </>
        )}
      </p>
    </div>
  )
}
