import React from "react"
import PropTypes from "prop-types"
import { SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"

export const TextSearchButton = ({ children, pathname }) => (
  <SafeLink to={pathname} action={safeLinkActions.TEXT_SEARCH}>
    {children}
  </SafeLink>
)

TextSearchButton.propTypes = {
  children: PropTypes.node,
  pathname: PropTypes.string,
}
