import React from "react"
import PropTypes from "prop-types"
import { SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"

import * as safeLinkStyles from "../safeLink.module.css"

export const ChooseValueButton = ({ children, pathname, enabled }) => (
  <>
    {enabled ? (
      <SafeLink
        to={pathname}
        action={safeLinkActions.CHOOSE_VALUE}
        className={safeLinkStyles.noUnderline}
      >
        {children}
      </SafeLink>
    ) : (
      <>{children}</>
    )}
  </>
)

ChooseValueButton.propTypes = {
  children: PropTypes.node,
  pathname: PropTypes.string,
}
