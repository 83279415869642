import React from "react"
import PropTypes from "prop-types"
import { Icon, icons, SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"
import { defaultLocale, labels } from "../../utils/labelConstants"
import classNames from "classnames"
import { useResponsiveView } from "../../hooks"
import { connect } from "react-redux"

import * as toolboxStyles from "../toolbox.module.css"
import * as styles from "./removeAllButton.module.css"

const RemoveAllButton = ({ pathname, locale }) => {
  const { isLargeMobile } = useResponsiveView()

  return (
    <SafeLink
      to={pathname}
      className={classNames(toolboxStyles.filterIcon, styles.clearAllButton)}
      action={safeLinkActions.REMOVE_ALL}
    >
      <Icon icon={icons.recycleBin} size={isLargeMobile ? 28 : 20} />
      <span className={styles.filterLabel}>{labels[locale].BTN_CLEAR}</span>
    </SafeLink>
  )
}

RemoveAllButton.propTypes = {
  pathname: PropTypes.string,
}

const mapStateToProps = store => ({
  locale: store.history.locale ? store.history.locale : defaultLocale,
})

export const RemoveAllButtonRedux = connect(mapStateToProps)(RemoveAllButton)
