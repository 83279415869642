// extracted by mini-css-extract-plugin
export var cMobile = "uploadPreview-module--cMobile--qrO0E";
export var fullSize = "uploadPreview-module--fullSize--Q4kKa";
export var imageContainer = "uploadPreview-module--imageContainer--0dtSe";
export var infoContainer = "uploadPreview-module--infoContainer--R9mmu";
export var loaderContainer = "uploadPreview-module--loaderContainer--4lJAk";
export var processing = "uploadPreview-module--processing--IyA43";
export var processingMessage = "uploadPreview-module--processingMessage--CVMXK";
export var processingResults = "uploadPreview-module--processingResults--yqcl+";
export var propsContainer = "uploadPreview-module--propsContainer--nwkva";
export var uploadPreview = "uploadPreview-module--uploadPreview--ngNvt";