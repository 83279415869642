import React from "react"
import classNames from "classnames"
import * as buttonStyles from "../../styles/buttonStyles.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"
import { connect } from "react-redux"
import { defaultLocale, labels } from "../../utils/labelConstants"

const MultiselectSwitch = ({ locale, switchOn = true }) => {
  return (
    <div className={classNames(buttonStyles.switchWithLabel)}>
      <div
        className={classNames(
          buttonStyles.basicSwitch,
          { [buttonStyles.on]: switchOn },
          { [buttonStyles.off]: !switchOn }
        )}
      >
        <div className={buttonStyles.switchIndicator} />
      </div>
      <p className={labelStyles.basicSwitch}>
        {switchOn
          ? labels[locale].MULTICHOICE_ON
          : labels[locale].MULTICHOICE_OFF}
      </p>
    </div>
  )
}

const mapStateToProps = store => ({
  locale: store.history.locale ? store.history.locale : defaultLocale,
})

export const MultiselectSwitchRedux =
  connect(mapStateToProps)(MultiselectSwitch)
