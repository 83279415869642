export const arrayOrderAtoZ = stringArray => {
  return stringArray.sort((firstEl, secondEl) => {
    if (firstEl > secondEl) return 1
    if (firstEl < secondEl) return -1
    return 0
  })
}

export const arrayIncludeStrings = (allStrings, stringsToInclude) => {
  if (!Array.isArray(allStrings) || !Array.isArray(stringsToInclude)) return []
  return allStrings.filter(
    string =>
      stringsToInclude.findIndex(stringInclude => string === stringInclude) > -1
  )
}

export const arrayIncludeStringsStartingWith = (
  allStrings,
  stringStartsToInclude
) => {
  if (!Array.isArray(allStrings) || !Array.isArray(stringStartsToInclude))
    return []
  return allStrings.filter(
    string =>
      stringStartsToInclude.findIndex(stringStartToInclude =>
        string.startsWith(stringStartToInclude)
      ) > -1
  )
}

export const arrayExcludeStrings = (allStrings, stringsToExclude) => {
  if (!Array.isArray(allStrings) || !Array.isArray(stringsToExclude)) return []
  return allStrings.filter(
    string =>
      stringsToExclude.findIndex(
        stringToExclude => string === stringToExclude
      ) === -1
  )
}

export const arrayExcludeStringsStartingWith = (
  allStrings,
  stringStartsToExclude
) => {
  if (!Array.isArray(allStrings) || !Array.isArray(stringStartsToExclude))
    return []
  return allStrings.filter(
    string =>
      stringStartsToExclude.findIndex(stringStartToExclude =>
        string.startsWith(stringStartToExclude)
      ) === -1
  )
}
