import React from "react"

function ShapeIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.784 27.616 6.07 32.374l.01.047a2.347 2.347 0 0 0 1.057 4.555l.005.023h17.16l.805-.268.777-1.221 5.941-8.653c.67-.725 1.817-1.857 2.666-2.125.1-.032.205-.063.313-.094.15-.032 1.507-.297 2.166.526.418.522.554.92.596 1.154l.014.233v.007l.001.001.664 10.44h1.877l1.387-13.617c.734-1.455 1.134-3.564 1.01-5.886-.22-4.118-2.01-7.396-4.045-7.494a1.494 1.494 0 0 0-.156-.002l-.043.002a2.747 2.747 0 0 0-1.79.846c-.63.647-.954 1.165-1.588 2.176-.294.469-.655 1.044-1.143 1.788-.444.675-1.06 1.834-1.787 3.2-1.795 3.377-4.263 8.018-6.458 9.736-3.084 2.414-5.296 2.95-7.172 2.682-1.877-.268-3.553-2.816-3.553-2.816Z"
        fill="#93C1CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.224 22.104c2.612-2.267 4.75-4.122 4.75-6.265 0-2.04-.187-4.046-.831-5.824a2.011 2.011 0 0 1 .33-.012c2.036.099 3.826 3.376 4.046 7.494.124 2.322-.276 4.43-1.01 5.886L40.122 37h-1.877l-.664-10.44h-.001v-.008l-.014-.233c-.042-.234-.178-.632-.597-1.154-.658-.823-2.015-.558-2.165-.526-.108.03-.213.062-.313.094-.849.268-1.996 1.4-2.666 2.125l-5.94 8.651-.778 1.223-.805.268h-7.41c2.381-.47 4.46-1.126 5.785-1.726 2.042-.924 3.079-2.685 4.309-4.776.974-1.656 2.07-3.518 3.883-5.333 1.121-1.123 2.278-2.127 3.355-3.061Z"
        fill="#71B5C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.828 22.655c-1.119-.135-2.312-.047-3.538.29-1.773.487-3.086 1.125-4.033 1.88.08-.037.159-.068.234-.092.1-.032.205-.063.313-.094.15-.032 1.508-.297 2.166.526.418.522.554.92.596 1.154l.015.233-.001.007.001.001.665 10.44h1.876l1.387-13.617c.115-.227.221-.47.319-.728Z"
        fill="#7695A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.29 22.944c1.226-.336 2.42-.424 3.538-.289a7.59 7.59 0 0 1-.319.728L40.122 37h-.944c.101-1.389.144-2.91.187-4.453.034-1.218.068-2.449.132-3.635.184-3.407-.748-4.965-2.157-5.677.3-.102.617-.2.95-.29Z"
        fill="#648296"
      />
    </svg>
  )
}

export { ShapeIcon }
