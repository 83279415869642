import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"
import { connect } from "react-redux"
import { Loader } from "../index"
import { defaultLocale, labels } from "../../utils/labelConstants"
import { formatProductCount } from "../../utils/helpers"

import * as buttonStyles from "./buttonStyle.module.css"
import * as safeLinkStyles from "../safeLink.module.css"

const CloseMobileDialogButton = ({
  to,
  label,
  count,
  loading,
  locale,
  deemphasized,
  dialog = false,
}) => {
  return (
    <SafeLink
      to={to}
      action={
        dialog ? safeLinkActions.CLOSE_DIALOG : safeLinkActions.SHOW_PRODUCTS
      }
      className={safeLinkStyles.noUnderline}
    >
      <div
        className={classNames(
          buttonStyles.buttonCommonStyles,
          { [buttonStyles.importantTheme]: !deemphasized },
          { [buttonStyles.standardTheme]: deemphasized },
          buttonStyles.overlayButton
        )}
      >
        {label
          ? label +
            (typeof count !== "undefined"
              ? ` (${loading ? "..." : formatProductCount(count)})`
              : "")
          : labels[locale].BTN_CLOSE}
      </div>
    </SafeLink>
  )
}

CloseMobileDialogButton.propTypes = {
  to: PropTypes.string.isRequired,
}

const mapStateToProps = store => ({
  locale: store.history.locale ? store.history.locale : defaultLocale,
})

export const CloseMobileDialogButtonRedux = connect(mapStateToProps)(
  CloseMobileDialogButton
)
