import React, { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { historyWrapper } from "../../utils/historyWrapper"
import { saveHistory } from "../../store/history/historyActions"
import {
  getLocationWithParam,
  getPathnameString,
} from "../../utils/positionUtils"
import { nonPositionParams } from "../../utils/constants"
import { connect } from "react-redux"

import * as styles from "./mobileGallery.module.css"
import { cTouchZone } from "./mobileGallery.module.css"

export const MobileGallery = ({
  children,
  location,
  previousProduct,
  nextProduct,
}) => {
  const dispatch = useDispatch()
  const initialVisibleSlide = 1
  const slideWidth = 310
  const initialDirection = 0

  var originalX = 0
  var lastX = 0
  var originalLeft = -1 * initialVisibleSlide * slideWidth
  var wait = false

  const [mounted, _setMounted] = useState(false)
  const mountedRef = useRef(mounted)
  const setMounted = data => {
    mountedRef.current = data
    _setMounted(data)
  }

  const [visibleSlide, _setVisibleSlide] = useState(initialVisibleSlide)
  const visibleSlideRef = useRef(visibleSlide)
  const setVisibleSlide = data => {
    visibleSlideRef.current = data
    _setVisibleSlide(data)
  }

  const [left, _setLeft] = useState(-1 * initialVisibleSlide * slideWidth)
  const leftRef = useRef(left)
  const setLeft = (data, oldData) => {
    leftRef.current = data
    _setLeft(data)
  }

  const [direction, _setDirection] = useState(initialDirection)
  const directionRef = useRef(direction)
  const setDirection = data => {
    directionRef.current = data
    _setDirection(data)
  }

  const [changeSlide, _setChangeSlide] = useState(false)
  const changeSlideRef = useRef(changeSlide)
  const setChangeSlide = data => {
    changeSlideRef.current = data
    _setChangeSlide(data)
  }

  const [previousProductId, _setPreviousProductId] = useState(
    previousProduct ? previousProduct.id : null
  )
  const previousProductIdRef = useRef(previousProductId)
  const setPreviousProductId = data => {
    previousProductIdRef.current = data
    _setPreviousProductId(data)
  }

  const [nextProductId, _setNextProductId] = useState(
    nextProduct ? nextProduct.id : null
  )
  const nextProductIdRef = useRef(nextProductId)
  const setNextProductId = data => {
    nextProductIdRef.current = data
    _setNextProductId(data)
  }

  useEffect(() => {
    if (myRef.current) {
      myRef.current.addEventListener(
        "touchstart",
        e => {
          handleTouchstart(e)
        },
        { passive: false }
      )
      myRef.current.addEventListener(
        "touchmove",
        e => {
          handleTouchmove(e)
        },
        { passive: false }
      )
      myRef.current.addEventListener(
        "touchend",
        e => {
          handleTouchend(e)
        },
        { passive: false }
      )
    }
    setMounted(true)
    return () => {
      if (myRef.current) {
        myRef.current.removeEventListener("touchstart", handleTouchstart)
        myRef.current.removeEventListener("touchmove", handleTouchmove)
        myRef.current.removeEventListener("touchend", handleTouchend)
      }
      setMounted(false)
    }
  }, [])

  useEffect(() => {
    setVisibleSlide(initialVisibleSlide)
    setLeft(-1 * initialVisibleSlide * slideWidth)
    setPreviousProductId(previousProduct ? previousProduct.id : null)
    setNextProductId(nextProduct ? nextProduct.id : null)
  }, [location])

  const myRef = React.createRef()

  var slides = children.map((item, index) => {
    return (
      <div key={index} className={styles.slide}>
        {item}
      </div>
    )
  })

  if (!previousProductId)
    slides.splice(0, 0, <div key={"emptyFirst"} className={styles.slide} />)
  if (!nextProductId)
    slides.splice(
      slides.length,
      0,
      <div key={"emptyLast"} className={styles.slide} />
    )

  const preventDefault = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleTouchstart = e => {
    preventDefault(e)
    originalX = e.touches[0].pageX
    originalLeft = leftRef.current
  }

  const handleTouchmove = e => {
    preventDefault(e)
    if (wait) return
    wait = true
    window.setTimeout(() => {
      wait = false
    }, 200)
    var currentX = e.touches[0].pageX - originalX
    var deltaX = lastX - currentX
    lastX = currentX

    if (mountedRef.current) {
      setLeft(
        originalLeft + Math.round(e.touches[0].pageX - originalX),
        originalLeft
      )
      setDirection(Math.sign(deltaX))
    }
  }

  const handleTouchend = e => {
    preventDefault(e)
    var directionFromOriginal = Math.sign(originalX - e.changedTouches[0].pageX)
    var lastDirection =
      Math.abs(lastX) > 5 ? directionRef.current : directionFromOriginal
    var finalDirection =
      directionFromOriginal !== lastDirection ? 0 : directionFromOriginal

    if (mountedRef.current) {
      var visibleSlide = visibleSlideRef.current

      var newSlide = slides[visibleSlide + finalDirection]
        ? visibleSlide + finalDirection
        : visibleSlide

      if (
        (newSlide < visibleSlide && previousProductIdRef.current) ||
        (newSlide > visibleSlide && nextProductIdRef.current)
      ) {
        const newLocation =
          newSlide < visibleSlide
            ? getLocationWithParam(
                location,
                nonPositionParams.PRODUCT,
                previousProductIdRef.current
              )
            : getLocationWithParam(
                location,
                nonPositionParams.PRODUCT,
                nextProductIdRef.current
              )
        const newUrl = getPathnameString(newLocation)
        historyWrapper.pushState(historyWrapper.state, "", newUrl)
        setTimeout(() => {
          setChangeSlide(false)
          dispatch(saveHistory(newLocation, historyWrapper.state))
        }, 300)
        setChangeSlide(true)
      } else {
        newSlide = visibleSlide
      }

      setVisibleSlide(newSlide)
      setLeft(newSlide * (-1 * slideWidth), originalLeft)
    }
  }

  return (
    <div data-testid="mobile-gallery" className={styles.galleryWindow}>
      <div
        className={classNames(styles.slideContainer, {
          [styles.active]: left !== originalLeft,
        })}
        style={{
          left: left,
          transition: changeSlide ? "left 0.3s" : "all 0s",
        }}
      >
        <div className={cTouchZone} ref={myRef} />
        {slides}
      </div>
    </div>
  )
}
