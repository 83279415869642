import React, { useState } from "react"
import { axiosWrapper, Logo, Logotype } from "./index"
import {
  cLoginScreen,
  cLoginPanel,
  cLoginLabel,
  cLoginButton,
} from "./loginScreen.module.css"
import { cTextInput } from "./selectors/textInput.module.css"

export const LoginScreen = () => {
  const [loginName, setLoginName] = useState("")
  const [loginPass, setLoginPass] = useState("")
  const [autenticating, setAuthenticating] = useState(false)
  const [authError, setAuthError] = useState(null)

  const failCallback = response => {
    setAuthenticating(false)
    setAuthError(response.message)
  }

  const handleLoginClick = () => {
    const axiosClient = new axiosWrapper()
    axiosClient.authenticate(loginName, loginPass, failCallback)
    setAuthenticating(true)
  }

  return (
    <div className={cLoginScreen}>
      <div className={cLoginPanel}>
        <div
          style={{
            height: "50px",
            marginBottom: "40px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Logo />
          <Logotype />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <label htmlFor="nameInput" className={cLoginLabel}>
              User Name
            </label>
            <input
              id="nameInput"
              type="text"
              value={loginName}
              onChange={e => {
                setLoginName(e.currentTarget.value)
              }}
              className={cTextInput}
              style={{ border: "1px solid var(--clr-grey-l1)" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <label htmlFor="passInput" className={cLoginLabel}>
              Password
            </label>
            <input
              id="passInput"
              type="password"
              value={loginPass}
              onChange={e => {
                setLoginPass(e.currentTarget.value)
              }}
              autoComplete="on"
              className={cTextInput}
              style={{ border: "1px solid var(--clr-grey-l1)" }}
            />
          </div>
          {!autenticating && authError && (
            <p
              style={{
                color: "red",
                textAlign: "center",
                fontSize: "var(--font-size-main)",
              }}
            >
              {authError}
            </p>
          )}
          {autenticating ? (
            <p
              style={{ textAlign: "center", fontSize: "var(--font-size-main)" }}
            >
              Authenticating...
            </p>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button onClick={handleLoginClick} className={cLoginButton}>
                Login
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
