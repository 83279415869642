import { useSelector, useDispatch } from "react-redux"
import { setSorting } from "../store/history/historyActions"

const useSorting = () => {
  const dispatch = useDispatch()
  const sortingIndex = useSelector(store =>
    store.history.sorting != null ? store.history.sorting : 0
  )

  const setSortingIndex = index => {
    dispatch(setSorting(index))
  }

  return { sortingIndex, setSortingIndex }
}

export default useSorting
