import {
  syntheticPanels,
  mustContain,
  mustNotContain,
  colors,
} from "../../../utils/constants"

export const getButton = (data, panelKey) => {
  let result
  if (panelKey === "color" && data && data[panelKey]) {
    const subPanelKeys = syntheticPanels[panelKey]
    result = {}
    subPanelKeys.map(subPanelKey => {
      if (data[panelKey][subPanelKey] == null) {
        return false
      }
      if (!Array.isArray(data[panelKey][subPanelKey].items)) {
        return false
      }
      data[panelKey][subPanelKey].items.map(subPanelItem => {
        if (subPanelItem.selected) {
          if (subPanelItem.rule && subPanelItem.rule.name) {
            if (subPanelItem.rule.name === mustContain[subPanelKey]) {
              if (result.mustContain) {
                result.mustContain = [
                  ...result.mustContain,
                  {
                    ...data[panelKey][subPanelKey],
                    color: colors[subPanelKey],
                  },
                ]
              } else {
                result.mustContain = [
                  {
                    ...data[panelKey][subPanelKey],
                    color: colors[subPanelKey],
                  },
                ]
              }
            }
            if (subPanelItem.rule.name === mustNotContain[subPanelKey]) {
              if (result.mustNotContain) {
                result.mustNotContain = [
                  ...result.mustNotContain,
                  {
                    ...data[panelKey][subPanelKey],
                    color: colors[subPanelKey],
                  },
                ]
              } else {
                result.mustNotContain = [
                  {
                    ...data[panelKey][subPanelKey],
                    color: colors[subPanelKey],
                  },
                ]
              }
            }
          }
        }
        return false
      })
      return false
    })
  }
  return result
}
