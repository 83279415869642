import React, { useState, useEffect } from "react"
import { STORAGE } from "../components/index"

export const useOpenCollections = (storageKey, defaultOpenCollections) => {
  const [openCollections, _setOpenCollections] = useState([])
  
  useEffect(() => {
    var newStorage = localStorage.getItem(STORAGE)
      ? JSON.parse(localStorage.getItem(STORAGE))
      : {}
    if (newStorage[storageKey]) _setOpenCollections(newStorage[storageKey])
    _setOpenCollections(defaultOpenCollections)
  }, [])
  
  const setOpenCollections = value => {
    var newStorage = localStorage.getItem(STORAGE)
      ? JSON.parse(localStorage.getItem(STORAGE))
      : {}
    newStorage[storageKey] = value
    localStorage.setItem(STORAGE, JSON.stringify(newStorage))
    _setOpenCollections(value)
  }
  
  const toggleCollection = collectionId => {
    let newOpenCollections = JSON.parse(JSON.stringify(openCollections))
    let index = openCollections.indexOf(collectionId)
    if (index < 0) newOpenCollections = [collectionId]
    else newOpenCollections.splice(index, 1)
    setOpenCollections(newOpenCollections)
  }
  
  return [openCollections, toggleCollection]
}