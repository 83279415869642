import { useMediaQuery } from "react-responsive"
import {
  desktop,
  desktopUp,
  largeMobile,
  largeMobileUp,
  largeTablet,
  largeTabletUp,
  smallMobile,
  smallMobileUp,
  smallTablet,
  smallTabletUp,
} from "../utils/responsiveness"

export const useResponsiveView = () => {
  const isSmallMobile = useMediaQuery({ maxWidth: smallMobile })
  const isSmallMobileUp = useMediaQuery({ maxWidth: smallMobileUp })

  const isLargeMobile = useMediaQuery({ maxWidth: largeMobile })
  const isLargeMobileUp = useMediaQuery({ maxWidth: largeMobileUp })

  const isSmallTablet = useMediaQuery({ maxWidth: smallTablet })
  const isSmallTabletUp = useMediaQuery({ maxWidth: smallTabletUp })

  const isLargeTablet = useMediaQuery({ maxWidth: largeTablet })
  const isLargeTabletUp = useMediaQuery({ maxWidth: largeTabletUp })

  const isDesktop = useMediaQuery({ maxWidth: desktop })
  const isDesktopUp = useMediaQuery({ maxWidth: desktopUp })

  return {
    isSmallMobile,
    isSmallMobileUp,
    isLargeMobile,
    isLargeMobileUp,
    isSmallTablet,
    isSmallTabletUp,
    isLargeTablet,
    isLargeTabletUp,
    isDesktop,
    isDesktopUp,
  }
}
