import { panelKeys, indicatorTypesByPanel } from "./constants"

export class SelectionObj {
  constructor(config) {
    this._caption = config.caption
    this._imageUrl = null
    this._subType = config.subType ? config.subType : null
    this._indicatorType = this.getIndicator(config)
    this._buttonImg = config.buttonImg
    this._button = config.button
    this._buttonImgs = config.buttonImgs ? config.buttonImgs : null
    this._buttonImgExists = config.buttonImgExists
  }

  getPanelNameFromConstants = data => {
    var panelName = null
    Object.keys(panelKeys).map(panelNameKey => {
      if (data.panelKey.startsWith(panelKeys[panelNameKey]))
        panelName = panelKeys[panelNameKey]
      return false
    })
    return panelName
  }

  getIndicator = data => {
    return indicatorTypesByPanel[this.getPanelNameFromConstants(data)]
  }

  get caption() {
    return this._caption
  }
  get indicatorType() {
    return this._indicatorType
  }
  get subType() {
    return this._subType
  }

  get imageUrl() {
    return this._buttonImg
  }
  get imageUrls() {
    return this._buttonImgs
  }
  get buttonImageUrl() {
    return this._buttonImg
  }
  get buttonImageExists() {
    return this._buttonImgExists
  }
  get button() {
    return this._button
  }
}
