import React from "react"
import classNames from "classnames"

import {
  ImageIndicator,
  ColorIndicator,
  PriceIndicator,
  SizeIndicator,
  StoreIndicator,
  BrandIndicator,
  FilterIcon,
  SelectingValueIcon,
  indicatorTypes,
  FilterStack,
  indicatorTypesByPanel,
  emptyImage,
  panelKeys,
} from "../../index"

import * as styles from "./filterIndicator.module.css"

export const FilterIndicator = ({
  imageUrl,
  caption,
  panelName,
  isSelected,
  stackSize,
  isIcon,
  button,
}) => {
  const isImageEmpty = image => {
    if (!image) return true
    if (image === emptyImage) return true
    return false
  }

  const getIndicator = (panelName, caption, imageUrl) => {
    if (indicatorTypesByPanel[panelName] === indicatorTypes.ICON)
      return <FilterIcon panelId={panelName} isSelected={isSelected} />
    else if (
      indicatorTypesByPanel[panelName] === indicatorTypes.IMAGE ||
      indicatorTypesByPanel[panelName] === indicatorTypes.COLOR
    ) {
      if (isImageEmpty(imageUrl))
        return (
          <SelectingValueIcon panelId={panelName} isSelected={isSelected} />
        )
    } else {
      if (!caption && isImageEmpty(imageUrl)) {
        return (
          <SelectingValueIcon panelId={panelName} isSelected={isSelected} />
        )
      }
    }

    switch (indicatorTypesByPanel[panelName]) {
      case indicatorTypes.IMAGE:
        return (
          <ImageIndicator
            selection={{ caption: caption, imageUrl: imageUrl }}
          />
        )
      case indicatorTypes.COLOR:
        return (
          <ColorIndicator
            selection={{ caption: caption, imageUrl: imageUrl, button: button }}
          />
        )
      case indicatorTypes.PRICE:
        return <PriceIndicator selection={{ caption: caption }} />
      case indicatorTypes.SIZE:
        return <SizeIndicator selection={{ caption: caption }} />
      case indicatorTypes.STORE:
        return <StoreIndicator selection={{ caption: caption }} />
      case indicatorTypes.BRAND:
        return <BrandIndicator selection={{ caption: caption }} />
      default:
        return (
          <ImageIndicator
            selection={{ caption: caption, imageUrl: imageUrl }}
          />
        )
    }
  }

  var filterType = "generic"
  switch (panelName) {
    case panelKeys.ADD_FILTER:
      filterType = "addFilter"
      break
    case panelKeys.SHAPE_FILTER:
      filterType = "shape"
      break
    default:
      if (!isIcon && panelName.startsWith(panelKeys.SHOE_ELEMENT)) {
        filterType = "shape"
      }
  }

  return (
    <>
      <FilterStack stackSize={stackSize} isSelected={isSelected} />
      <div
        className={classNames(styles.filter, styles[filterType], {
          [styles.selected]: isSelected,
        })}
      >
        {getIndicator(panelName, caption, imageUrl)}
      </div>
    </>
  )
}
