import { useState, useEffect } from "react"

export const useMobile = () => {
  const threshold = 577
  const getIsMobile = windowWidth => windowWidth < threshold
  const [isMobile, setIsMobile] = useState(getIsMobile(window.innerWidth))

  useEffect(() => {
    const handleResize = e => {
      setIsMobile(getIsMobile(window.innerWidth))
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return isMobile
}
