import React from "react"
import classNames from "classnames"

import * as styles from "./newSearchButton.module.css"
import * as buttonStyles from "../button.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

export const UploadCancelButton = ({ handleClick }) => {
  return (
    <div
      className={classNames(
        buttonStyles.buttonCommonStyles,
        buttonStyles.standardTheme
      )}
      onClick={handleClick}
    >
      Back
    </div>
  )
}
