export const mockUploads = {
  uploads: [
    {
      id: "upload0",
      uploaded: 1643294399786,
      unseen: true,
      finished: true,
      image_url:
        "https://s3.eu-central-1.amazonaws.com/vestigio.tmp/dev/uploads/c10760de709792bab5afe4f7b87522b0",
      coord: {
        shape: [
          {
            id: "60c087d31ace17b30a0ab5c9",
            name: "fa.cat.shoe",
          },
          {
            id: "60a7904e23958de030a9de74",
            name: "fa.cat.shoe.w",
          },
          {
            id: "5d96eed6a2dee64971822338",
            name: "fa.cat.w.pumps",
          },
        ],
        color: [
          {
            id: "6101233dc419e46fac1ee3fe",
            name: "fa.color.shoe.black",
          },
        ],
      },
      captions: {
        shape: "Pumps",
        color: "Black",
      },
      visualization: {
        shape: {
          type: "asset",
          id: "60f7cce3bd03e1e37c65d738",
          url: "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/60f7cce3bd03e1e37c65d738_120x120.png",
        },
        color: {
          type: "asset",
          id: "61962f7b03df2ed4c522b817",
          url: "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/61962f7b03df2ed4c522b817_120x120.png",
        },
      },
    },
  ],
}
/*
{
    "coord": {
        "shape": [
            {
                "id": "60c087d31ace17b30a0ab5c9",
                "name": "fa.cat.shoe"
            },
            {
                "id": "60a7904e23958de030a9de74",
                "name": "fa.cat.shoe.w"
            },
            {
                "id": "5d96eed6a2dee64971822338",
                "name": "fa.cat.w.pumps"
            }
        ],
        "color": [
            {
                "id": "6101233dc419e46fac1ee3fe",
                "name": "fa.color.shoe.black"
            }
        ]
    },
    "captions": {
        "shape": "Pumps",
        "color": "Black"
    },
    "visualization": {
        "shape": {
            "type": "asset",
            "id": "60f7cce3bd03e1e37c65d738",
            "url": "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/60f7cce3bd03e1e37c65d738_120x120.png"
        },
        "color": {
            "type": "asset",
            "id": "61962f7b03df2ed4c522b817",
            "url": "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/61962f7b03df2ed4c522b817_120x120.png"
        }
    },
    
}
*/

/*
  [
    {
      id: "upload0",
      uploaded: 1643294399786,
      unseen: true,
      finished: true,
      image: "https://www.ahume.co.uk/images/waterford-chelsea-boots-p3747-133535_image.jpg",
      detected_props: {
        shape: {
          coord: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.boots","fa.cat.w.boots.ankle","fa.cat.w.boots.ankle.chelsea"],
          caption: "Chelsea Boots",
          "visualization": {
            type: "asset",
            url:"https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/60f7c892bd03e1e37c65d714_120x120.png",
          },   
        },
        color: {
          coord: ["fa.color.shoe.browns","fa.color.shoe.brown"],
          caption: "Brown",
          visualization: {
            type: "asset",
            url: "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/61962fc403df2ed4c522b81d_120x120.png"
          }
        },
      }
    },
    {
      id: "upload1",
      uploaded: 1643027580382,
      unseen: true,
      finished: true,
      image: "https://d3d71ba2asa5oz.cloudfront.net/12026733/images/b-123blue36_531__2.jpg",
      detected_props: {
        shape: {
          coord: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.pumps", "fa.cat.w.pumps.ballet"],
          caption: "Ballet Shoes",
          "visualization": {
            type: "asset",
            url:"https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/60f7ce75bd03e1e37c65d741_120x120.png",
          },   
        },
        color: {
          coord: ["fa.color.shoe.blue"],
          caption: "Blue",
          visualization: {
            type: "asset",
            url: "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/61a8cb5f01bf415dc36c25ab_120x120.png"
          }
        },
      }
    }
  ]
*/

export const mockData = {
  pageIndex: 0,
  pageCount: 3,
  heaps: [
    {
      id: "heap1",
      caption: "Pumps",
      images: [
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/pumps_3.png",
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/pumps_1.png",
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/pumps_2.png",
      ],
      icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_highheel_60.png",
      mutation: {
        shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.w.pumps"],
      },
      count: "478K",
    },
    {
      id: "heap2",
      caption: "Ballerinas",
      images: [
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/ballerinas_3.png",
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/ballerinas_1.png",
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/ballerinas_2.png",
      ],
      icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_ballet_60.png",
      mutation: {
        shape: [
          "fa.cat.shoe",
          "fa.cat.shoe.w",
          "fa.cat.w.pumps",
          "fa.cat.w.pumps.ballet",
        ],
      },
      count: "358K",
    },
    {
      id: "heap3",
      caption: "Sneakers",
      images: [
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/sneakers_3.png",
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/sneakers_1.png",
        "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/heaps/sneakers_2.png",
      ],
      icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-shoe_sneaker_60.png",
      mutation: {
        shape: ["fa.cat.shoe", "fa.cat.shoe.w", "fa.cat.shoe.w.sneakers"],
      },
      count: "654K",
    },
  ],
  products: [
    {
      id: "product1x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/537a5f69dcd73594b772edb842201d5e.JPEG",
      brand: "DKNY",
      name: "Meghan's Spirit",
      price: 60,
      colorVariants: [
        {
          id: "beige_0",
          caption: "Beige",
          image:
            "https://d2ytevstx36swl.cloudfront.net/small/537a5f69dcd73594b772edb842201d5e.JPEG",
          visualization: {
            id: "61962f0ed7419dc8f083c695",
            type: "asset",
            url: "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/61962f0ed7419dc8f083c695_120x120.png",
          },
          selected: true,
        },
        {
          id: "burnt_umber_0",
          caption: "Burnt Umber",
          image:
            "https://d2ytevstx36swl.cloudfront.net/small/4a04e785df03dd3ccaa27df6afce01f9.JPEG",
          visualization: {
            id: "61a0e9a18849833e90ab05ab",
            type: "asset",
            url: "https://s3.eu-central-1.amazonaws.com/vestigio.assets.public/prod/61a0e9a18849833e90ab05ab_120x120.png",
          },
          selected: false,
        },
      ],
    },
    {
      id: "product2x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/944b96f2c5f34a0882a5fb4667b2da29.JPEG",
      brand: "P1 DKNY",
      name: "Chung's Original",
      price: 231,
    },
    {
      id: "product3x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/02ad182114ad1f25a7381c677db615ff.JPEG",
      brand: "P1 Kenneth Cole",
      name: "Hepburn's Special",
      price: 245,
    },
    {
      id: "product4x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/74f751aebd14ce1fb72847b0ad638024.JPEG",
      brand: "P1 Steve Madden",
      name: "Duchess’s Selection",
      price: 206,
    },
    {
      id: "product5x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/c4d544bc21953592efba23fea2c594a7.JPEG",
      brand: "P1 Christian Dior",
      name: "Monroe's Selection",
      price: 55,
    },
    {
      id: "product6x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/236d340d5779c917fa9609683f6534c4.JPEG",
      brand: "P1 Prada",
      name: "Meghan's Original",
      price: 42,
    },
    {
      id: "product7x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/540ad48ecc294f2be8a785f67c2b477b.JPEG",
      brand: "P1 DKNY",
      name: "90s Selection",
      price: 234,
    },
    {
      id: "product8x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/0e1b57c8b159feb52680f66f102fde06.JPEG",
      brand: "P1 Christian Dior",
      name: "Julia's Tribute",
      price: 276,
    },
    {
      id: "product9x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/b143667e5516b5746ab67d94fd83045d.JPEG",
      brand: "P1 Christian Louboutin",
      name: "Monroe's Tribute",
      price: 297,
    },
    {
      id: "product10x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/f31ff6903130d43774b8374d5170fa85.JPEG",
      brand: "P1 Tommy Hilfiger",
      name: "90s Retro",
      price: 57,
    },
    {
      id: "product11x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/c0976158348b543897f442e1e3529f18.JPEG",
      brand: "P1 Burberry",
      name: "90s Spring/Summer",
      price: 303,
    },
    {
      id: "product12x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/01c392a1e6d271c8f93fff05998f1333.JPEG",
      brand: "P1 Tory Burch",
      name: "Kate's Retro",
      price: 217,
    },
    {
      id: "product13x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/f0d8207e69efbecd3ed65f5237819bda.JPEG",
      brand: "P1 Lulu Guinness",
      name: "Meghan's Passion",
      price: 140,
    },
    {
      id: "product14x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/943386224b9431faaebbef5820f56384.JPEG",
      brand: "P1 Marc Jacobs",
      name: "Twiggy's Spirit",
      price: 99,
    },
    {
      id: "product15x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/938bd10dc41dc1c480e3ad1d340146ca.JPEG",
      brand: "P1 Stuart Weitzman",
      name: "90s Selection",
      price: 285,
    },
    {
      id: "product16x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/317dd57a7ff1e99e999d503c86dea8f7.JPEG",
      brand: "P1 Fendi",
      name: "Chung's Spring/Summer",
      price: 86,
    },
    {
      id: "product17x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/c43952b3ffa9095a4ec227bfcb40c3e7.JPEG",
      brand: "P1 DKNY",
      name: "Julia's Vintage",
      price: 125,
    },
    {
      id: "product18x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/b2f7b2b57e20bb496a23e7cfee3c2c11.JPEG",
      brand: "P1 Coach",
      name: "Hepburn's Vogue",
      price: 92,
    },
    {
      id: "product19x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/578e04e5a0cad202a2b77694d32c23b0.JPEG",
      brand: "P1 Fendi",
      name: "Duchess’s Original",
      price: 160,
    },
    {
      id: "product20x",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/01487d9460506f272acfbe5aba164653.JPEG",
      brand: "P1 Kate Spade",
      name: "Meghan's Passion",
      price: 261,
    },
  ],
}

export const mockData1 = {
  pageIndex: 1,
  pageCount: 3,
  products: [
    {
      id: "product1y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/21dfa11e52644473d01a940c5cb571ea.JPEG",
      brand: "P2 Christian Louboutin",
      name: "Naomi's Spirit Pumps",
      price: 260,
    },
    {
      id: "product2y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/1d93c9c514cd00c7193fd8648fe196e2.JPEG",
      brand: "P2 Gucci",
      name: "Meghan's Secret Pumps",
      price: 301,
    },
    {
      id: "product3y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/1e856e746f3b6b11089cfd221607826d.JPEG",
      brand: "P2 Steven by Steve Madden",
      name: "Meghan's Retro Pumps",
      price: 138,
    },
    {
      id: "product4y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/417fb9b06e7ff24f72f6929037693881.JPEG",
      brand: "P2 Giuseppe Zanotti",
      name: "Duchess’s Spring/Summer Pumps",
      price: 110,
    },
    {
      id: "product5y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/c053709d183db5d64f1c8724184c41a6.JPEG",
      brand: "P2 Gucci",
      name: "Julia's Spring/Summer Pumps",
      price: 314,
    },
    {
      id: "product6y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/014a77e5f64a9d98684f02d142087a5d.JPEG",
      brand: "P2 Burberry",
      name: "Julia's Spring/Summer Pumps",
      price: 243,
    },
    {
      id: "product7y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/d230326296b8efac3f37e050a944a7dc.JPEG",
      brand: "P2 Giuseppe Zanotti",
      name: "Naomi's Tribute Pumps",
      price: 301,
    },
    {
      id: "product8y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/1a6688bf0a1f684105892f304b5671e2.JPEG",
      brand: "P2 Christian Dior",
      name: "Hepburn's Secret Pumps",
      price: 128,
    },
    {
      id: "product9y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/6fd2a798af5e1a7f0dcb15e433f2a715.JPEG",
      brand: "P2 Tommy Hilfiger",
      name: "Meghan's Vintage Pumps",
      price: 301,
    },
    {
      id: "product10y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/4c9bbf64fe745884fc2915c68a58aa96.JPEG",
      brand: "P2 Steven by Steve Madden",
      name: "Chung's Spirit Pumps",
      price: 321,
    },
    {
      id: "product11y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/fb6fa2bab0ffdcee773d3bb133aee02e.JPEG",
      brand: "P2 Coach",
      name: "Twiggy's Spring/Summer Pumps",
      price: 73,
    },
    {
      id: "product12y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/ee425a2481461623c222661340a7799c.JPEG",
      brand: "P2 Christian Louboutin",
      name: "Duchess’s Spring/Summer Pumps",
      price: 109,
    },
    {
      id: "product13y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/6645d20bede71754064a3f6ebef3eb7e.JPEG",
      brand: "P2 Chanel",
      name: "Chung's Spring/Summer Pumps",
      price: 275,
    },
    {
      id: "product14y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/2d1ea987c9086a785f44d715b255f006.JPEG",
      brand: "P2 Manolo Blahnik",
      name: "Chung's Special Pumps",
      price: 131,
    },
    {
      id: "product15y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/8118a1cfe5988a5cf8c65ff2b5c64ea3.JPEG",
      brand: "P2 Yves Saint Laurent",
      name: "Chung's Original Pumps",
      price: 248,
    },
    {
      id: "product16y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/08f6d9b0f9b4311abf47b87208b822be.JPEG",
      brand: "P2 Chanel",
      name: "90s Vintage Pumps",
      price: 162,
    },
    {
      id: "product17y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/ad0d57024d815b6688f5ef24215c8b16.JPEG",
      brand: "P2 Bruno Magli",
      name: "Twiggy's Selection Pumps",
      price: 245,
    },
    {
      id: "product18y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/566a2f0d1c70a8eeb66c4d707e652b09.JPEG",
      brand: "P2 Tory Burch",
      name: "Duchess’s Vogue Pumps",
      price: 253,
    },
    {
      id: "product19y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/725771f884d507d1744e2cdf37c14064.JPEG",
      brand: "P2 Bruno Magli",
      name: "Hepburn's Retro Pumps",
      price: 123,
    },
    {
      id: "product20y",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/23e0473340ae95b5e3d18c9d51d836a9.JPEG",
      brand: "P2 Prada",
      name: "Duchess’s Spirit Pumps",
      price: 291,
    },
  ],
}

export const mockData2 = {
  pageIndex: 2,
  pageCount: 3,
  products: [
    {
      id: "product1a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/fbde28528e88991e154d3e81c9382f52.JPEG",
      brand: "P3 Balenciaga",
      name: "90s Sneakers",
      price: 305,
    },
    {
      id: "product2a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/5ce71529d3014bc7c39129122999ae0a.JPEG",
      brand: "P3 Timberland",
      name: "Casual Sneakers",
      price: 220,
    },
    {
      id: "product3a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/1ba20bbb796aef59a46ee8df3c8927b7.JPEG",
      brand: "P3 Nike",
      name: "Casual Retro Sneakers",
      price: 43,
    },
    {
      id: "product4a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/91726b5e5f5bbd3d39c661e75f67fbff.JPEG",
      brand: "P3 Vans",
      name: "Soccer Original Sneakers",
      price: 115,
    },
    {
      id: "product5a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/1ba20bbb796aef59a46ee8df3c8927b7.JPEG",
      brand: "P3 Adidas",
      name: "Street Retro Sneakers",
      price: 179,
    },
    {
      id: "product6a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/bda6481c7853151e71466dd014559aa1.JPEG",
      brand: "P3 Vans",
      name: "Casual Retro Sneakers",
      price: 107,
    },
    {
      id: "product7a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/6d40bd326db8f3b3f3ec2d44dba3c8a9.JPEG",
      brand: "P3 New Balance",
      name: "Casual Sneakers",
      price: 284,
    },
    {
      id: "product8a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/db13436aaac8acbb927fd4444e6b6f24.JPEG",
      brand: "P3 Asos",
      name: "Running Sneakers",
      price: 134,
    },
    {
      id: "product9a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/6f89dfc81805cc031659a549013cf83c.JPEG",
      brand: "P3 Asics",
      name: "Urban All Star Sneakers",
      price: 181,
    },
    {
      id: "product10a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/cfde595e02c23c7aec887c7f843de739.JPEG",
      brand: "P3 Asics",
      name: "Unisex All Star Sneakers",
      price: 228,
    },
    {
      id: "product11a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/32a89d492d185ef65663947db2e7241f.JPEG",
      brand: "P3 Nike",
      name: "Street Retro Sneakers",
      price: 142,
    },
    {
      id: "product12a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/426e60aa9a3df8fdcb8f14f678116a42.JPEG",
      brand: "P3 Puma",
      name: "Running Sneakers",
      price: 321,
    },
    {
      id: "product13a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/9a2ac30cdaa2f7b81bb20bdd0e7ec895.JPEG",
      brand: "Vans",
      name: "90s All Star Sneakers",
      price: 172,
    },
    {
      id: "product14a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/ef4b9b41c593f9f9855004db6b890891.JPEG",
      brand: "P3 Puma",
      name: "Soccer Vintage Sneakers",
      price: 183,
    },
    {
      id: "product15a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/5f1cdd58b378069f6fd03cbae5c40eff.JPEG",
      brand: "P3 Balenciaga",
      name: "Running Original Sneakers",
      price: 134,
    },
    {
      id: "product16a",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/ff7c24fac787f51a9b5743d50ec9512e.JPEG",
      brand: "P3 Asos",
      name: "Jordan Retro Sneakers",
      price: 319,
    },
  ],
}

export const flatMock = {
  pageIndex: 0,
  pageCount: 1,
  products: [
    {
      id: "pr1flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/01487d9460506f272acfbe5aba164653.JPEG",
      brand: "Kate Spade",
      name: "Meghan's Passion",
      price: 261,
    },
    {
      id: "pr2flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/21dfa11e52644473d01a940c5cb571ea.JPEG",
      brand: "Christian Louboutin",
      name: "Naomi's Spirit Pumps",
      price: 260,
    },
    {
      id: "pr3flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/1e856e746f3b6b11089cfd221607826d.JPEG",
      brand: "Steven by Steve Madden",
      name: "Meghan's Retro Pumps",
      price: 138,
    },
    {
      id: "pr4flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/c053709d183db5d64f1c8724184c41a6.JPEG",
      brand: "Gucci",
      name: "Julia's Spring/Summer Pumps",
      price: 314,
    },
    {
      id: "pr5flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/6d40bd326db8f3b3f3ec2d44dba3c8a9.JPEG",
      brand: "New Balance",
      name: "Casual Sneakers",
      price: 284,
    },
    {
      id: "pr6flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/db13436aaac8acbb927fd4444e6b6f24.JPEG",
      brand: "Asos",
      name: "Running Sneakers",
      price: 134,
    },
    {
      id: "pr7flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/6f89dfc81805cc031659a549013cf83c.JPEG",
      brand: "Asics",
      name: "Urban All Star Sneakers",
      price: 181,
    },
    {
      id: "pr8flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/1d93c9c514cd00c7193fd8648fe196e2.JPEG",
      brand: "Gucci",
      name: "Meghan's Secret Pumps",
      price: 301,
    },
    {
      id: "pr9flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/cfde595e02c23c7aec887c7f843de739.JPEG",
      brand: "Asics",
      name: "Unisex All Star Sneakers",
      price: 228,
    },
    {
      id: "pr10flat",
      image:
        "https://d2ytevstx36swl.cloudfront.net/small/417fb9b06e7ff24f72f6929037693881.JPEG",
      brand: "Giuseppe Zanotti",
      name: "Duchess’s Spring/Summer Pumps",
      price: 110,
    },
  ],
}
