import { photoSearchActionTypes } from "./photoSearchActions"

import { mockUploads } from "../../utils/_tempMocks"

export const initialState = {
  uploads: [],
}

export const photoSearchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case photoSearchActionTypes.PHOTO_SEARCH_FETCH_REQUEST:
      return {
        ...state,
        ...mockUploads,
        timestamp: Date.now(),
        error: null,
        isLoading: true,
      }

    case photoSearchActionTypes.PHOTO_SEARCH_FETCH_SUCCESS:
      return {
        ...state,
        timestamp: Date.now(),
        error: null,
        isLoading: false,
      }

    case photoSearchActionTypes.PHOTO_SEARCH_FETCH_FAILURE:
      return {
        ...state,
        timestamp: Date.now(),
        error: action.payload.error.message,
        isLoading: false,
      }

    case photoSearchActionTypes.PHOTO_SEARCH_POST_REQUEST:
      return {
        ...state,
        timestamp: Date.now(),
        error: null,
        isLoading: true,
      }

    case photoSearchActionTypes.PHOTO_SEARCH_POST_SUCCESS:
      var uploads = state.uploads
      const recentUpload = {
        ...action.payload,
        id: Date.now(),
        uploaded: Date.now(),
        unseen: true,
        finished: true,
      }
      if (Array.isArray(uploads)) {
        uploads = [recentUpload].concat(uploads)
      } else {
        uploads = [recentUpload]
      }

      return {
        ...state,
        uploads: uploads,
        timestamp: Date.now(),
        error: null,
        isLoading: false,
      }

    case photoSearchActionTypes.PHOTO_SEARCH_POST_FAILURE:
      return {
        ...state,
        timestamp: Date.now(),
        error: action.payload.error.message,
        isLoading: false,
      }

    default:
      return state
  }
}
