import React, { useState, useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { ariaRoles } from "../../utils/constants"
import { ColorFiltribute } from "./filtributes/colorFiltribute"
import { Collection } from "./collection"
import {
  useOpenCollections,
  ChooseValueButton,
  SectionHeader,
  Grid,
  AddPanelFiltribute,
  Icon,
  icons,
  STORAGE,
} from "../index.js"
import useNavigator from "../../hooks/useNavigator.js"
import useFilterPanels from "../../hooks/useFilterPanels.js"
import usePanel from "../../hooks/usePanel.js"
import * as selectorStyles from "./selector.module.css"
import * as styles from "./filtributes/filtribute.module.css"
import * as buttonStyles from "../../styles/buttonStyles.module.css"

export const PatternSelector = () => {
  const { location, position } = useNavigator()
  const { filterPanels: filterObj } = useFilterPanels({ location })
  const { openPanel } = usePanel()
  const filterPanelObj = filterObj.getPanel(openPanel)
  const defaultOpenCollections =
    filterPanelObj.filtributes &&
    filterPanelObj.filtributes[0] &&
    filterPanelObj.filtributes[0].id
      ? [filterPanelObj.filtributes[0].id]
      : []
  const [openCollections, toggleCollection] = useOpenCollections(
    filterPanelObj.key + ".Open",
    defaultOpenCollections
  )

  const getSelectionsForCollection = collection => {
    const selections = collection.items.map((selection, index) => {
      return (
        <ChooseValueButton
          key={index}
          pathname={selection.getLinkUrl(position)}
          enabled={selection.count > 0}
        >
          <ColorFiltribute
            key={index}
            url={selection.imageUrl}
            alt={selection.caption}
            value={selection.caption}
            isSelected={selection.selected}
            caption={selection.truncatedCaption}
            count={selection.count}
            hasChildren={selection.hasChildren}
            multiselect={filterPanelObj.isMultiselect}
          />
        </ChooseValueButton>
      )
    })
    return selections
  }

  const collections = filterPanelObj.filtributes.map((collection, index) => {
    return (
      <Collection
        collection={collection}
        openCollections={openCollections}
        handleCollectionToggle={toggleCollection}
      >
        <Grid>{getSelectionsForCollection(collection)}</Grid>
      </Collection>
    )
  })

  return (
    <>
      <SectionHeader />
      <div className={selectorStyles.sectionBody} role={ariaRoles.LIST}>
        {collections}
      </div>
    </>
  )
}

PatternSelector.propTypes = {
  position: PropTypes.object.isRequired,
  filterPanelObj: PropTypes.object.isRequired,
}
