import React from "react"
import PropTypes from "prop-types"
import { SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"

export const HeapOpenButton = ({ children, pathname }) => (
  <SafeLink to={pathname} action={safeLinkActions.HEAP_OPEN}>
    {children}
  </SafeLink>
)

HeapOpenButton.propTypes = {
  children: PropTypes.node,
  pathname: PropTypes.string,
}
