import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { ariaRoles } from "../../utils/constants.js"
import { ProductCardExpanded } from "./productCardExpanded.jsx"
import { DesktopGallery, MobileGallery } from "../../components/index.js"

import useMoreOptionsPanel from "./hooks/useMoreOptionsPanel.js"

import * as styles from "./moreOptionsPanel.module.css"

export const MoreOptionsPanel = ({
  location,
  position,
  setTitle,
  heapView,
  deploymentVersion,
}) => {
  const {
    product,
    nextProduct,
    previousProduct,
    productArray,
    detailsLoading,
    isTouchScreen,
  } = useMoreOptionsPanel({
    location,
    position,
    heapView,
    setTitle,
    deploymentVersion,
  })

  const products = productArray.map((product, index) => {
    return (
      <ProductCardExpanded
        key={index}
        product={product}
        position={position}
        detailsLoading={detailsLoading}
      />
    )
  })

  return (
    <div
      data-testid="more-options-panel"
      role={ariaRoles.GROUP}
      className={classNames(styles.moreOptionsPanel, {
        [styles.touchable]: isTouchScreen,
      })}
    >
      {!isTouchScreen && products && product && (
        <DesktopGallery
          location={location}
          previousProduct={previousProduct}
          nextProduct={nextProduct}
        >
          {products[previousProduct ? 1 : 0]}
        </DesktopGallery>
      )}
      {isTouchScreen && products && (
        <MobileGallery
          location={location}
          previousProduct={previousProduct}
          nextProduct={nextProduct}
        >
          {products}
        </MobileGallery>
      )}
    </div>
  )
}

MoreOptionsPanel.propTypes = {
  location: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  setTitle: PropTypes.func.isRequired,
}
