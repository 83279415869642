import { getValidState, removeOldItems } from "../utils/storeUtils"

export const loadStore = () => {
  try {
    const serializedState = localStorage.getItem("gasefiStore")

    if (serializedState === null) {
      return undefined
    }

    return getValidState(JSON.parse(serializedState))
  } catch (e) {
    return undefined
  }
}

export const saveStore = state => {
  try {
    var stateCopy = JSON.parse(JSON.stringify(state))
    delete stateCopy.photoSearch
    const serializedState = JSON.stringify(removeOldItems(stateCopy))
  } catch (e) {
    console.log({ e })
    localStorage.clear()
    // ignore write errors
  }
}
