import React from "react"
import PropTypes from "prop-types"
import { ariaRoles } from "../../utils/constants"
import {
  useOpenCollections,
  ChooseValueButton,
  SectionHeader,
  Grid,
} from "../index.js"
import { ColorFiltribute } from "./filtributes/colorFiltribute"
import { Collection } from "./collection"
import useNavigator from "../../hooks/useNavigator.js"
import useFilterPanels from "../../hooks/useFilterPanels.js"
import usePanel from "../../hooks/usePanel.js"
import * as selectorStyles from "./selector.module.css"

export const ElementSelector = () => {
  const { location, position } = useNavigator()
  const { filterPanels: filterObj } = useFilterPanels({ location })
  const { openPanel } = usePanel()
  const filterPanelObj = filterObj.getPanel(openPanel)
  var hasCollections = false
  if (
    Array.isArray(filterPanelObj.filtributes) &&
    filterPanelObj.filtributes[0]
  ) {
    hasCollections = filterPanelObj.filtributes[0].isCollection
  }

  const defaultOpenCollections =
    filterPanelObj.filtributes &&
    filterPanelObj.filtributes[0] &&
    filterPanelObj.filtributes[0].id
      ? [filterPanelObj.filtributes[0].id]
      : []
  const [openCollections, toggleCollection] = useOpenCollections(
    filterPanelObj.key + ".Open",
    defaultOpenCollections
  )

  const getSelectionsForCollection = collection => {
    const selections = collection.items.map((item, index) => {
      return (
        <ChooseValueButton
          key={item.id}
          pathname={item.getLinkUrl(position)}
          enabled={item.count > 0}
        >
          <ColorFiltribute
            url={item.imageUrl}
            alt={item.imageAlt}
            isSelected={item.selected}
            caption={item.caption}
            count={item.count}
            multiselect={item.isMultiselect}
          />
        </ChooseValueButton>
      )
    })
    return selections
  }

  const getCollections = () => {
    const collections = filterPanelObj.filtributes.map((collection, index) => {
      return (
        <Collection
          collection={collection}
          openCollections={openCollections}
          handleCollectionToggle={toggleCollection}
        >
          <Grid>{getSelectionsForCollection(collection)}</Grid>
        </Collection>
      )
    })
    return collections
  }

  const selectionList = filterPanelObj.filtributes.map(item => {
    return (
      <ChooseValueButton
        key={item.id}
        pathname={item.getLinkUrl(position)}
        enabled={item.count > 0}
      >
        <ColorFiltribute
          url={item.imageUrl}
          alt={item.imageAlt}
          isSelected={item.selected}
          caption={item.caption}
          count={item.count}
          multiselect={item.isMultiselect}
        />
      </ChooseValueButton>
    )
  })

  return (
    <>
      <SectionHeader />
      <div className={selectorStyles.sectionBodyCentered} role={ariaRoles.LIST}>
        {hasCollections ? (
          <>{getCollections()}</>
        ) : (
          <Grid itemWidth={86}>{selectionList}</Grid>
        )}
      </div>
    </>
  )
}

ElementSelector.propTypes = {
  position: PropTypes.object.isRequired,
  filterPanelObj: PropTypes.object.isRequired,
}
