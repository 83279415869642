import { panelKeys, selectorsByPanel } from "./constants"
import {
  locationToPosition,
  removeKeysFromPosition,
  positionToLocation,
  getPathnameString,
} from "./positionUtils"
import { SelectionObj } from "./selectionObj"
import { ParentObj } from "./parentObj"
import { FiltributeObj } from "./filtributeObj"
import { syntheticPanels } from "./constants"

export class FilterPanelObj {
  constructor(panelConfig) {
    this.panelData = panelConfig
    this._caption = panelConfig.caption
    this._isOpen = panelConfig.isOpen
    this._panelSelector = this.getSelector(panelConfig)
    this._panelKey = panelConfig.panelKey
    this._name = panelConfig.panelName
    this._parent = panelConfig.parent
    this._filtributes = panelConfig.filtributes
    this._multichoiceSwitch = panelConfig.multichoiceSwitch
    this._multiselect = panelConfig.multiselect
    this._stackSize = panelConfig.stackSize
      ? panelConfig.stackSize
      : this.getStackSize(panelConfig)

    if (!panelConfig.filtributes || panelConfig.filtributes.length === 0) {
      if (panelConfig.buttonImgs && Array.isArray(panelConfig.buttonImgs))
        this._stackSize = panelConfig.buttonImgs.length
      if (panelConfig.texts && Array.isArray(panelConfig.texts))
        this._stackSize = panelConfig.texts.length
    }
    this._switchDelta = panelConfig.switchDelta
  }

  getStackSize = data => {
    let result = 1
    if (data.panelKey === panelKeys.ADD_FILTER) return result
    result = data.filtributes.filter(
      filtribute => filtribute.selected === true
    ).length
    return result
  }

  getPanelNameFromConstants = data => {
    var panelName = null
    if (!data || !data.panelKey) return ""
    Object.keys(panelKeys).map(panelNameKey => {
      if (data.panelKey.startsWith(panelKeys[panelNameKey]))
        panelName = panelKeys[panelNameKey]
      return false
    })
    return panelName
  }

  getRemoveUrl = location => {
    const currentPosition = locationToPosition(location)
    var newPosition = removeKeysFromPosition(currentPosition, [this._panelKey])
    const syntheticPanelKeys = Object.keys(syntheticPanels)
    if (syntheticPanelKeys.indexOf(this._panelKey) > -1) {
      newPosition = removeKeysFromPosition(
        newPosition,
        syntheticPanels[this._panelKey]
      )
    }
    const newLocation = positionToLocation(newPosition, location)
    return getPathnameString(newLocation)
  }

  getSelector = data => {
    return selectorsByPanel[this.getPanelNameFromConstants(data)]
  }

  getFiltributePage = (cols = 1, rows = 1, pageNum = 0) => {
    const pageSize = cols * rows
    const filtributes = this.filtributes
    const pageCount = Math.ceil(filtributes.length / pageSize)
    const nonZeroFiltributes = filtributes.filter(
      filtribute => filtribute.count > 0
    )
    const zeroCountFiltributes = filtributes.filter(
      filtribute => filtribute.count === 0
    )
    const resultFiltributes = nonZeroFiltributes.concat(zeroCountFiltributes)
    const filtributesInPage = resultFiltributes.slice(
      pageNum * pageSize,
      pageNum * pageSize + pageSize
    )
    return [filtributesInPage, pageCount]
  }

  isOpen = openPanelKey => {
    return this._panelKey === openPanelKey
  }

  get selection() {
    return new SelectionObj(this.panelData)
  }

  get parent() {
    const parentConfig = {
      caption: this._parent ? this._parent.caption : "",
      delta: this._parent ? this._parent.delta : null,
    }
    return new ParentObj(parentConfig)
  }

  get filtributes() {
    if (!Array.isArray(this._filtributes)) return []
    return this._filtributes.map(filtribute => new FiltributeObj(filtribute))
  }

  get selector() {
    return this._panelSelector
  }

  get caption() {
    if (typeof this._caption === "undefined") return ""
    return this._caption
  }

  get name() {
    if (typeof this._name === "undefined") return ""
    return this._name
  }

  get key() {
    if (typeof this._panelKey === "undefined") return ""
    return this._panelKey
  }

  get showMultichoiceSwitch() {
    return this._multichoiceSwitch
  }

  get isMultiselect() {
    return this._multiselect
  }

  get stackSize() {
    return this._stackSize
  }

  getNewPosition = (currentPosition = {}) => {
    if (!Array.isArray(this._switchDelta)) return currentPosition
    var newPosition = JSON.parse(JSON.stringify(currentPosition))
    newPosition[this._panelKey] = this._switchDelta.map(deltaItem => {
      if (Array.isArray(deltaItem)) return deltaItem.map(item => item.name)
      return deltaItem.name
    })
    return newPosition
  }

  getSwitchDeltaUrl = (currentPosition = {}) => {
    const newLocation = positionToLocation(this.getNewPosition(currentPosition))
    return getPathnameString(newLocation)
  }
}
