// extracted by mini-css-extract-plugin
export var closeIcon = "textSearch-module--closeIcon--NZ-+d";
export var dropdown = "textSearch-module--dropdown--X1Q8m";
export var dropdownInOverlay = "textSearch-module--dropdownInOverlay--8S-rx";
export var inLanding = "textSearch-module--inLanding--W4gSP";
export var landing = "textSearch-module--landing--7hGRs";
export var noSuggestions = "textSearch-module--noSuggestions--XtBs-";
export var searchIcon = "textSearch-module--searchIcon--yVkCC";
export var searchIconInOverlay = "textSearch-module--searchIconInOverlay--Ssk3E";
export var searchInput = "textSearch-module--searchInput--iOlhZ";
export var searchInputInOverlay = "textSearch-module--searchInputInOverlay--WPxH7";
export var searchInputWrapperInOverlay = "textSearch-module--searchInputWrapperInOverlay--+10cl";
export var searchTextHighlighted = "textSearch-module--searchTextHighlighted--J8gxC";
export var suggestionImage = "textSearch-module--suggestionImage--yd0Uf";
export var suggestionLink = "textSearch-module--suggestionLink--bhhjk";
export var textSearchOverlay = "textSearch-module--textSearchOverlay--I6YUp";
export var textSearchWrapper = "textSearch-module--textSearchWrapper--KLbpL";