import React from "react"
import {
  panelsWithIcons,
  AddFilterIcon,
  ShapeIcon,
  BrandIcon,
  ColorIcon,
  PatternIcon,
  PriceIcon,
  SizeIcon,
  VendorIcon,
  ElementsIcon,
  HeelIcon,
  DecorationsIcon,
  HeelHeightIcon,
  SoleIcon,
  ToeIcon,
  BackIcon,
  FasteningIcon,
  EmptyElementIcon,
  EmptyIcon,
} from "../../index.js"

export const FilterIcon = ({ panelId, isSelected }) => {
  const props = {}
  var icon = <EmptyIcon />
  switch (panelId) {
    case panelsWithIcons.ADD_FILTER:
      icon = <AddFilterIcon isSelected={isSelected} />
      break
    case panelsWithIcons.SHOE_ELEMENT:
      icon = <ElementsIcon {...props} />
      break
    case panelsWithIcons.TOE_TYPE_FILTER:
      icon = <ToeIcon {...props} />
      break
    case panelsWithIcons.BACK_FILTER:
      icon = <BackIcon {...props} />
      break
    case panelsWithIcons.FASTENING_TYPE_FILTER:
      icon = <FasteningIcon {...props} />
      break
    case panelsWithIcons.DECORATIONS_TYPE_FILTER:
      icon = <DecorationsIcon {...props} />
      break
    case panelsWithIcons.HEEL_HEIGHT_FILTER:
      icon = <HeelHeightIcon {...props} />
      break
    case panelsWithIcons.HEEL_TYPE_FILTER:
      icon = <HeelIcon {...props} />
      break
    case panelsWithIcons.PLATFORM_FILTER:
      icon = <SoleIcon {...props} />
      break
    case panelsWithIcons.BRAND_FILTER:
      icon = <BrandIcon {...props} />
      break
    case panelsWithIcons.COLOR_FILTER:
      icon = <ColorIcon {...props} />
      break
    case panelsWithIcons.PATTERN_FILTER:
      icon = <PatternIcon {...props} />
      break
    case panelsWithIcons.PRICE_FILTER:
      icon = <PriceIcon {...props} />
      break
    case panelsWithIcons.SIZE_FILTER:
      icon = <SizeIcon {...props} />
      break
    case panelsWithIcons.VENDOR_FILTER:
      icon = <VendorIcon {...props} />
      break
    case panelsWithIcons.SHAPE_FILTER:
      icon = <ShapeIcon {...props} />
      break
    default:
      icon = <EmptyIcon />
      break
  }
  return <>{icon}</>
}
