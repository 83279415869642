// extracted by mini-css-extract-plugin
export var first = "multicolorFiltribute-module--first--p6D7o";
export var last = "multicolorFiltribute-module--last--ozCMS";
export var multicolorLabel = "multicolorFiltribute-module--multicolorLabel--s-Lox";
export var multicolorSlider = "multicolorFiltribute-module--multicolorSlider--jXG6m";
export var multicolorSliderThumb = "multicolorFiltribute-module--multicolorSliderThumb--n7Yv9";
export var multicolorSliderThumbActive = "multicolorFiltribute-module--multicolorSliderThumbActive--agiDO";
export var multicolorSliderTrack = "multicolorFiltribute-module--multicolorSliderTrack--VXoej";
export var multicolorSwitch = "multicolorFiltribute-module--multicolorSwitch--vd1FZ";
export var mustContain = "multicolorFiltribute-module--mustContain--SeI-K";
export var mustNotContain = "multicolorFiltribute-module--mustNotContain--WRvnM";
export var switchBar = "multicolorFiltribute-module--switchBar--ATyJG";
export var switchCount = "multicolorFiltribute-module--switchCount--GgOKW";
export var switchIndicator = "multicolorFiltribute-module--switchIndicator--V92-Y";