import { labels } from "../../../utils"
import { useSelector } from "react-redux"
import { defaultLocale } from "../../../utils"

const useVendorBox = (storeId, storeEntity) => {
  const locale = useSelector(store =>
    store.history.locale ? store.history.locale : defaultLocale
  )

  const getSizeString = storeEntity => {
    if (storeEntity && Array.isArray(storeEntity)) {
      return storeEntity
        .map(storeItem => storeItem.size)
        .sort()
        .join(", ")
    }
    return ""
  }

  const getLowestPrice = storeEntity => {
    if (storeEntity && Array.isArray(storeEntity)) {
      return storeEntity.map(storeItem => storeItem.price).sort()[0]
    }
    return -1
  }

  const getUrl = storeEntity => {
    if (storeEntity && Array.isArray(storeEntity)) {
      return storeEntity.sort((a, b) => a.price - b.price)[0].url
    }
    return ""
  }

  const sizeString = getSizeString(storeEntity)
  const lowestPrice = getLowestPrice(storeEntity)
  const url = getUrl(storeEntity)

  return {
    storeName: storeId,
    sizeString,
    price:
      lowestPrice > -1 ? `${labels[locale].FROM} €${lowestPrice / 100}` : "",
    url,
  }
}

export default useVendorBox
