// extracted by mini-css-extract-plugin
export var black = "icon-module--black--VEs6f";
export var blue = "icon-module--blue--LhOOu";
export var green = "icon-module--green--xVqX4";
export var grey = "icon-module--grey--avzUZ";
export var greyLight = "icon-module--greyLight--5NH0u";
export var main = "icon-module--main--4WdLA";
export var orange = "icon-module--orange--ie0Sl";
export var red = "icon-module--red--r-uD8";
export var rotate = "icon-module--rotate--3mqul";
export var rotation = "icon-module--rotation--Ka7Xz";
export var white = "icon-module--white--dKH3T";