import { ofType } from "redux-observable"
import {
  fetchFilterPanelsFailure,
  fetchFilterPanelsSuccess,
  filterPanelsActionTypes,
} from "./filterPanelsByPositionActions"
import {
  catchError,
  delay,
  map,
  retryWhen,
  scan,
  switchMap,
} from "rxjs/operators"
import { from, of } from "rxjs"
import { serializePosition, dirFromPosition } from "../../utils/positionUtils"
import { storeReducers, storeReducerVersions } from "../../utils/constants"

export const getFilterPanelsByPositionEpic = (
  action$,
  store$,
  { axiosClient }
) =>
  action$.pipe(
    ofType(filterPanelsActionTypes.FILTER_PANELS_FETCH_REQUEST),
    switchMap(action => {
      const requestPosition = serializePosition(action.payload.position)
      const dir = dirFromPosition(action.payload.position)
      const deploymentVersion = action.payload.deploymentVersion
      const captionsVersion = action.payload.captionsVersion

      return from(
        axiosClient.send({
          method: "post",
          url: action.payload.url,
          body: action.payload.body,
        })
      ).pipe(
        retryWhen(errors => {
          return errors.pipe(
            scan((acc, err) => {
              if (acc >= 3) {
                throw new Error("Network error")
              }

              return acc + 1
            }, 0),
            delay(250)
          )
        }),
        map(response =>
          fetchFilterPanelsSuccess({
            ...response,
            __position: requestPosition,
            __version:
              storeReducerVersions[storeReducers.FILTER_PANELS_BY_POSITION],
            __deploymentVersion: deploymentVersion,
            __captionsVersion: captionsVersion,
          })
        ),
        catchError(error => {
          console.error("Fetch timeout", { error })

          return of(
            fetchFilterPanelsFailure({
              __position: requestPosition,
              __version:
                storeReducerVersions[storeReducers.FILTER_PANELS_BY_POSITION],
              __deploymentVersion: deploymentVersion,
              __captionsVersion: captionsVersion,
              error,
            })
          )
        })
      )
    })
  )
