import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setTouchable } from "../store/history/historyActions"

const useIsTouchable = () => {
  const dispatch = useDispatch()
  const isTouchable = useSelector(store =>
    store.history.touchable != null ? store.history.touchable : true
  )
  const isTouchableRef = useRef()
  isTouchableRef.current = isTouchable
  const setIsTouchable = value => {
    dispatch(setTouchable(value))
  }

  const handleMouseOver = () => {
    if (isTouchableRef.current === true) setIsTouchable(false)
    document.removeEventListener("mouseover", handleMouseOver)
  }

  const handleTouch = () => {
    if (isTouchableRef.current === false) setIsTouchable(true)
    document.removeEventListener("mouseover", handleMouseOver)
    document.removeEventListener("touchstart", handleTouch)
  }

  useEffect(() => {
    document.addEventListener("touchstart", handleTouch)
    document.addEventListener("mouseover", handleMouseOver)
    return () => {
      document.removeEventListener("touchstart", handleTouch)
      document.removeEventListener("mouseover", handleMouseOver)
    }
  }, [])

  return isTouchable
}

export default useIsTouchable
