import React from "react"
import useVendorBox from "./hooks/useVendorBox"

import * as styles from "./vendorBox.module.css"

const VendorBox = ({ id, store }) => {
  const { storeName, sizeString, price, url } = useVendorBox(id, store)

  return (
    <div
      className={styles.vendorBox}
    >
      <div>
        <div className={styles.vendorName}>{storeName}</div>
        <div className={styles.sizeList}>{sizeString}</div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {price && url ? (
          <a href={url} target="_blank" className={styles.vendorLink}>
            {price}
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default VendorBox
