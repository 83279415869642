import { panelKeys } from "../../../utils"
import { panelCaptions } from "../../../utils"
import { selectorCollections } from "../../../utils/constants"
import { addFilterSelectionTypes } from "../../../utils/constants"

export const createAddFilterPanel = (availableFilterData, activeFilterData) => {
  var result = {
    panelKey: panelKeys.ADD_FILTER,
    panelName: panelCaptions[panelKeys.ADD_FILTER],
    caption: panelCaptions[panelKeys.ADD_FILTER],
    filtributes: [],
  }
  availableFilterData.map(item => {
    var collection = null
    Object.keys(selectorCollections).map(collectionKey => {
      if (item.panelKey.startsWith(collectionKey))
        collection = selectorCollections[collectionKey]
      return false
    })
    var newFiltribute = {
      id: collection === null ? item.panelKey : collection,
      caption: collection === null ? item.panelKey : collection,
      selectionType:
        collection === null
          ? addFilterSelectionTypes.SELECTION
          : addFilterSelectionTypes.COLLECTION,
      selected: false,
    }
    if (collection === null) result.filtributes.push(newFiltribute)
    else {
      var index = result.filtributes.findIndex(
        filtribute => filtribute.id === collection
      )
      if (index < 0) {
        newFiltribute.items = []
        index = result.filtributes.push(newFiltribute)
      }
      index = result.filtributes.findIndex(
        filtribute => filtribute.id === collection
      )
      var newItem = {
        id: item.panelKey,
        caption: item.caption
          ? item.caption
          : panelCaptions[item.panelKey]
          ? panelCaptions[item.panelKey]
          : item.panelKey,
        selectionType: addFilterSelectionTypes.SELECTION,
        selected: false,
      }
      result.filtributes[index].items.push(newItem)
    }
    return false
  })

  activeFilterData.map(item => {
    var newFiltribute = {
      id: item.panelKey,
      caption: item.panelName,
      details: item.caption ? item.caption : "",
      imageUrl: item.buttonImg ? item.buttonImg : "#",
      selectionType: addFilterSelectionTypes.SELECTION,
      selected: true,
    }

    result.filtributes.push(newFiltribute)
    return false
  })
  return result
}
