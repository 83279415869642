export const getPriceCaption = priceArray => {
  let result
  if (priceArray[0]) {
    result = `from $${priceArray[0]}`
  }
  if (priceArray[1]) {
    result = `up to $${priceArray[1]}`
  }
  if (priceArray[0] && priceArray[1]) {
    result = `$${priceArray[0]} - $${priceArray[1]}`
  }
  return result
}
