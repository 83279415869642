import React from "react"
import useLocale from "../../hooks/useLocale"
import classNames from "classnames"

import {
  labels,
  Loader,
  DetectedProp,
  CloseMobileDialogButton,
  locationFromPosition,
  getPathnameString,
  Image,
} from "../index"

import * as styles from "./uploadPreview.module.css"
import { cMobile } from "./uploadPreview.module.css"

export const UploadPreview = ({
  location,
  newPosition,
  items,
  selectedItem,
  selectedProps,
  handlePropSelection,
  fullsize,
  isMobile,
}) => {
  const locale = useLocale()
  let detectedProps = null

  if (items && items[selectedItem] && items[selectedItem].finished) {
    //var newPosition = JSON.parse(JSON.stringify(locationToPosition(location)))
    detectedProps = items[selectedItem].detectedProps.map(
      (detectedProp, index) => {
        //if (selectedProps && selectedProps[index]) newPosition[detectedProp.prop] = detectedProp.coord
        return (
          <DetectedProp
            key={index}
            prop={detectedProp}
            selected={selectedProps ? selectedProps[index] : false}
            handleClick={() => {
              handlePropSelection(index)
            }}
          />
        )
      }
    )
  }

  return (
    <div
      className={classNames(
        styles.uploadPreview,
        { [styles.fullsize]: fullsize },
        { [cMobile]: isMobile }
      )}
    >
      {items.length > 0 && (
        <>
          <div
            className={classNames(
              styles.imageContainer,
              { [styles.processing]: !items[selectedItem].finished },
              { [cMobile]: isMobile }
            )}
          >
            <Image
              src={items[selectedItem].productImage}
              alt="Uploaded photo"
              contain
              blurredBackground
            />
            {!items[selectedItem].finished && (
              <div className={styles.loaderContainer}>
                <Loader color={"white"} />
              </div>
            )}
          </div>
          <div
            className={classNames(styles.infoContainer, {
              [cMobile]: isMobile,
            })}
          >
            {items && items[selectedItem] && !items[selectedItem].finished && (
              <div className={styles.processingMessage}>
                <p>{labels[locale].UPLOAD_SERVER_IS_PROCESSING}</p>
                <p>{labels[locale].UPLOAD_THANK_YOU}</p>
              </div>
            )}
            {items && items[selectedItem] && items[selectedItem].finished ? (
              <div className={styles.processingResults}>
                <div className={styles.propsContainer}>
                  <h3>{labels[locale].UPLOAD_DETECTED_PROPERTIES}</h3>
                  {detectedProps}
                </div>
                {!isMobile ? (
                  <CloseMobileDialogButton
                    to={getPathnameString(
                      locationFromPosition(newPosition, location)
                    )}
                    label={labels[locale].UPLOAD_APPLY_FILTERS}
                  />
                ) : (
                  <div style={{ display: "none" }} />
                )}
              </div>
            ) : (
              <div style={{ display: "none" }} />
            )}
          </div>
        </>
      )}
    </div>
  )
}
