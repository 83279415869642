import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { iconsColors } from "./icon.utils"

import * as styles from "./icon.module.css"

export const Icon = ({ animation, color, icon, size, customClass }) => (
  <svg
    role="img"
    className={styles[animation]}
    width={size}
    height={size}
    viewBox="0 0 64 64"
  >
    <path
      className={classNames(styles[color], { [customClass]: customClass })}
      d={icon}
    />
  </svg>
)

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  animation: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

Icon.defaultProps = {
  animation: null,
  color: iconsColors.MAIN,
  size: 24,
}
