// extracted by mini-css-extract-plugin
export var categoryPreview = "categorySelector-module--categoryPreview--HnEAT";
export var categorySelection = "categorySelector-module--categorySelection--zXHmp";
export var doubleStackCard = "categorySelector-module--doubleStackCard--wnJdx";
export var fiveStackCard = "categorySelector-module--fiveStackCard--g4xT5";
export var fourStackCard = "categorySelector-module--fourStackCard--PSqSv";
export var selectionCard = "categorySelector-module--selectionCard--2yMN2";
export var selectionPreview = "categorySelector-module--selectionPreview--cHtq6";
export var shapeSelection = "categorySelector-module--shapeSelection--Lsmxr";
export var stackedCard = "categorySelector-module--stackedCard--yZ0HS";
export var trippleStackCard = "categorySelector-module--trippleStackCard--hGt9J";