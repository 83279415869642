import React from "react"
import PropTypes from "prop-types"
import { ariaRoles } from "../../utils/constants"
import { ColorFiltribute } from "./filtributes/colorFiltribute"
import { ChooseValueButton, SectionHeader, Grid, List } from "../index.js"
import { syntheticPanels } from "../../utils/constants"
import { MulticolorFiltribute } from "./filtributes/multicolorFiltribute"
import { multicolorOptions } from "../../utils/constants"
import useNavigator from "../../hooks/useNavigator.js"
import useFilterPanels from "../../hooks/useFilterPanels.js"
import usePanel from "../../hooks/usePanel.js"
import * as selectorStyles from "./selector.module.css"
import * as filtributeStyles from "./filtributes/filtribute.module.css"
import * as multricolorStyles from "./filtributes/multicolorFiltribute.module.css"

export const ColorSelector = () => {
  const { location, position } = useNavigator()
  const { filterPanels: filterObj } = useFilterPanels({ location })
  const { openPanel } = usePanel()
  const filterPanelObj = filterObj.getPanel(openPanel)
  let isMulticolor = false
  if (
    filterPanelObj &&
    filterPanelObj.selection &&
    filterPanelObj.selection.button &&
    filterPanelObj.panelData &&
    filterPanelObj.panelData.caption &&
    filterPanelObj.panelData.caption === "Multicolor"
  ) {
    isMulticolor = true
  }

  const getFiltributes = () => {
    const filtributes = filterPanelObj.filtributes.map((selection, index) => {
      return (
        <ChooseValueButton
          key={index}
          pathname={selection.getLinkUrl(position)}
          enabled={selection.count > 0}
        >
          <ColorFiltribute
            key={index}
            url={selection.imageUrl}
            alt={selection.caption}
            value={selection.caption}
            isSelected={selection.selected}
            caption={selection.truncatedCaption}
            count={selection.count}
            hasChildren={selection.hasChildren}
            multiselect={selection.isMultiselect}
          />
        </ChooseValueButton>
      )
    })

    return <Grid>{filtributes}</Grid>
  }

  const getMulticolorFiltributes = () => {
    const filtributes = syntheticPanels[filterPanelObj.key].map(subPanelKey => {
      if (!filterPanelObj.panelData[subPanelKey]) return null
      return (
        <MulticolorFiltribute
          key={subPanelKey}
          panelKey={subPanelKey}
          position={position}
          filterPanelObj={filterPanelObj}
        />
      )
    })
    return (
      <>
        <div
          style={{
            height: "20px",
            width: "100%",
            display: "flex",
          }}
        >
          <div className={filtributeStyles.filtributeDetails} />
          <div className={filtributeStyles.filtributeDetails} />
          <div className={multricolorStyles.multicolorLabel}>
            {multicolorOptions.MUST_CONTAIN}
          </div>
          <div className={multricolorStyles.multicolorLabel}>
            {multicolorOptions.IGNORE}
          </div>
          <div className={multricolorStyles.multicolorLabel}>
            {multicolorOptions.MUST_NOT_CONTAIN}
          </div>
        </div>
        <List style={{ marginTop: "0" }}>{filtributes}</List>
      </>
    )
  }

  return (
    <>
      <SectionHeader />
      <div className={selectorStyles.sectionBody} role={ariaRoles.LIST}>
        {isMulticolor ? getMulticolorFiltributes() : getFiltributes()}
      </div>
    </>
  )
}

ColorSelector.propTypes = {
  position: PropTypes.object.isRequired,
  filterPanelObj: PropTypes.object.isRequired,
}
