import React from "react"

export const FasteningIcon = props => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <circle cx={24} cy={24} r={15} fill="#648296" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.458 14.419c1.756 5.788 7.406 7.656 9.278 7.818 1.393.12 2.738 9.394 1.93 16.76C15.536 38.818 9 32.172 9 24a14.94 14.94 0 0 1 3.458-9.581Zm23.639.71A14.933 14.933 0 0 1 39 24c0 7.906-6.116 14.383-13.874 14.958-.799-7.358.544-16.6 1.935-16.721 1.793-.155 7.053-1.876 9.036-7.108Z"
        fill="#7695A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.844 17.193c2.316 2.87 5.811 4.57 7.242 4.694.418.036 2.443.288 3.33 1.528.283-.86.582-1.408.871-1.528l.332-.136c1.546-.628 4.813-1.956 7.032-4.92-.16-.325-.338-.572-.536-.721-1.2-.9-2.297-.627-3.957-.212-1.246.312-2.809.703-4.972.737-1.968.031-3.43-.332-4.622-.628-1.77-.44-2.946-.732-4.306.628a2.664 2.664 0 0 0-.414.558Z"
        fill="#7695A9"
      />
      <path
        d="M29.8 35.9a1.7 1.7 0 1 1-3.4 0 1.7 1.7 0 0 1 3.4 0ZM29.5 31.3a1.7 1.7 0 1 1-3.4 0 1.7 1.7 0 0 1 3.4 0ZM29.1 27.1a1.7 1.7 0 1 1-3.4 0 1.7 1.7 0 0 1 3.4 0ZM17.7 35.9a1.7 1.7 0 1 0 3.4 0 1.7 1.7 0 0 0-3.4 0ZM18 31.3a1.7 1.7 0 1 0 3.4 0 1.7 1.7 0 0 0-3.4 0ZM18.4 27.1a1.7 1.7 0 1 0 3.4 0 1.7 1.7 0 0 0-3.4 0Z"
        fill="#648296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.974 23.111c-.65.425-1.68.828-2.451.803-.298-.01-.882.048-1.663.124-2.27.222-6.202.607-9.589-.01.027.04-.038.02-.123-.008-.099-.03-.223-.07-.261-.032-.622.495-1.298.725-2.276.7 0-.08 0-.268-.05-.498-3.253 1.297-5.858 2.952-8 6.466-.541.89-1.294 1.794-2.147 2.817l-.002.002-.02.025c-.351-.428-.68-.876-.981-1.342.464-.623.924-1.263 1.39-1.993 1.873-2.93 4.752-5.19 8.606-7.053.145-.07.153-.318.026-.422a1.643 1.643 0 0 0-.21-.07c.362-.13.648-.208.863-.208.045.046.094.097.143.153l.033.037.174-.015.099.245c.272-.225.61-.439.974-.607 6.264-3.389 10.412-5.188 13.595-5.53.247.441.471.896.673 1.363-2.938.148-4.619 1.008-7.193 2.324-1.174.6-2.533 1.295-4.281 2.06 6.128.627 6.872.448 9.744-.244l.569-.136c.475-.113 1.096-.32 1.84-.568l.317-.105c.1.564.167 1.138.201 1.722ZM9.437 27.61c1.669-1.01 4.352-1.159 4.58-1.17 1.159-1.18 2.14-1.853 3.724-2.516-.442.142-.92.282-1.432.415-.632.163-1.302.312-1.985.464-1.787.398-3.659.815-5.14 1.555.067.424.152.841.253 1.253Z"
        fill="#DE8036"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 24.047V24c0-.298-.009-.595-.026-.889-.593.388-1.505.757-2.245.8.775.048 1.535.095 2.27.136Zm-28.652 6.177c1.677-1.617 3.685-3.785 3.685-3.785h-.016c1.159-1.18 2.14-1.853 3.724-2.516a36.404 36.404 0 0 0 2.562-.945h.002c.349-.14.654-.263.918-.358.073.02.143.042.21.07.127.104.119.352-.026.422-3.854 1.864-6.733 4.123-8.606 7.053-.466.73-.926 1.37-1.39 1.993-.4-.615-.755-1.261-1.063-1.934Zm2.152 3.407c.815-.958 1.533-1.816 2.058-2.64 2.204-3.465 4.833-5.07 8.1-6.302l-.047-.001c0-.171 0-.844-.49-1.364-4.176 1.833-7.291 4.107-9.314 7.141a28.03 28.03 0 0 1-1.32 1.81c.312.471.65.924 1.013 1.356ZM38.724 21.12c-4.483 2.174-7.002 2.487-13.61 1.444a5.61 5.61 0 0 1 .17 1.468c5.497 1.064 9.194 1.196 13.67-1.202-.044-.58-.122-1.15-.23-1.71ZM25.053 22.31c7.084.757 7.895.631 10.74-.052l-.17-.009c-4.169-.232-8.518-.474-10.637-.183.024.084.046.166.066.244Zm-3.317.101c.46-.61.894-.79 1.925-.875 1.205.56 1.318 1.271 1.4 2.626-.62.495-1.122.55-2.1.525-.188-1.08-.422-1.6-1.225-2.276Zm-1.4 3.676c2.785.38 4.324.418 7.002 0a1.05 1.05 0 0 1 0 2.101c-2.734.328-4.267.329-7.003 0a1.05 1.05 0 0 1 0-2.1Zm-.351 4.202c3.057.408 4.744.397 7.703 0a1.05 1.05 0 0 1 0 2.1c-3.008.413-4.695.445-7.703 0a1.05 1.05 0 0 1 0-2.1Zm-.35 4.552c3.28.422 5.122.404 8.403 0a1.05 1.05 0 1 1 0 2.1c-3.245.436-5.062.424-8.403 0a1.05 1.05 0 0 1 0-2.1Z"
        fill="#FDB046"
      />
    </svg>
  )
}

