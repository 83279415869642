// extracted by mini-css-extract-plugin
export var alertTheme = "button-module--alertTheme--JhZd1";
export var buttonCommonStyles = "button-module--buttonCommonStyles--kBUe0";
export var cancelTheme = "button-module--cancelTheme--Xql6u";
export var disabled = "button-module--disabled--S5EC9";
export var importantTheme = "button-module--importantTheme--01KkG";
export var minimized = "button-module--minimized--F4GWU";
export var mobileFooterButton = "button-module--mobileFooterButton--6tUMR";
export var pressed = "button-module--pressed--twmig";
export var standardTheme = "button-module--standardTheme--ogAll";
export var submitTheme = "button-module--submitTheme--wfMY6";
export var transparentTheme = "button-module--transparentTheme--PZK17";
export var turquoiseTheme = "button-module--turquoiseTheme--O-hLA";