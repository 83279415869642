import React from "react"
import { SelectingValueIcon } from "../../index"

export const SizeIndicator = ({ selection }) => {
  let selectedSize = null
  let fontSize = "18px"
  let overflow = false
  if (selection.caption && parseInt(selection.caption.split(",")))
    selectedSize = selection.caption

  if (selectedSize && selectedSize.split(",").length > 1) {
    fontSize = "14px"
    if (selectedSize.split(",").length > 2) {
      selectedSize = selectedSize.split(",").slice(0, 2).join(", ")
      overflow = true
    }
  }

  if (selectedSize !== null)
    return (
      <div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "var(--clr-grey-d1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            color: "#f6f6f6",
            fontSize: fontSize,
            lineHeight: "22px",
            fontWeight: "400",
          }}
        >
          {selectedSize}
        </div>
        {overflow ? (
          <div
            style={{
              color: "#f6f6f6",
              fontSize: fontSize,
              lineHeight: "2px",
            }}
          >
            ...
          </div>
        ) : null}
      </div>
    )

  return <SelectingValueIcon />
}
