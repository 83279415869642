import { getPathnameString } from "./helpers"
import { positionToLocation } from "./positionUtils"
import { getPositionWithoutSubPanels } from "../features/filters/utils/mergePanels"

export class ParentObj {
  constructor(config) {
    this._caption = config.caption
    this._delta = config.delta
  }

  get caption() {
    return this._caption
  }

  get name() {
    return this._caption
  }

  getNewPosition = (currentPosition = {}) => {
    if (this._delta === null) return currentPosition
    const deltaKey = Object.keys(this._delta)[0]
    var newPosition = { ...currentPosition }
    newPosition[deltaKey] = this._delta[deltaKey].map(
      deltaItem => deltaItem.name
    )
    return getPositionWithoutSubPanels(newPosition, deltaKey)
  }

  getLinkUrl = (currentPosition = {}) => {
    const newLocation = positionToLocation(this.getNewPosition(currentPosition))
    return getPathnameString(newLocation)
  }

  get hasDelta() {
    if (typeof this._delta === "undefined") return false
    if (this._delta === null) return false
    return true
  }
}
