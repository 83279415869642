import React from "react"
import classNames from "classnames"
import * as styles from "./loader.module.css"

export const Loader = ({
  xs,
  small,
  emphasized,
  important,
  warning,
  alert,
}) => {
  return (
    <div
      className={classNames(styles.loaderRing, {
        [styles.small]: small,
        [styles.xs]: xs,
        [styles.important]: important,
        [styles.emphasized]: emphasized,
        [styles.warning]: warning,
        [styles.alert]: alert,
      })}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
