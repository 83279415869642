// extracted by mini-css-extract-plugin
export var basicSwitch = "labelStyles-module--basicSwitch--NIXzP";
export var categoryUp = "labelStyles-module--categoryUp--ytDu+";
export var centered = "labelStyles-module--centered--Ks-n3";
export var dialogTitle = "labelStyles-module--dialogTitle--gTxN2";
export var headerCount = "labelStyles-module--headerCount--EjyPI";
export var headerCountLabel = "labelStyles-module--headerCountLabel--ZgIRL";
export var heapCaption = "labelStyles-module--heapCaption--OLmXw";
export var heapCount = "labelStyles-module--heapCount--IgkOQ";
export var main = "labelStyles-module--main--FicNg";
export var noWrap = "labelStyles-module--noWrap--ZY7BK";
export var panelLabel = "labelStyles-module--panelLabel--bUpeS";
export var productCaption = "labelStyles-module--productCaption--8oL11";
export var resultHeaderDropdown = "labelStyles-module--resultHeaderDropdown--zas2i";
export var sectionHeader = "labelStyles-module--sectionHeader--SaJ50";