import { deltaUrlFromPosition } from "./positionUtils"

const maxLength = 15

export class FiltributeObj {
  constructor(config) {
    this._id = config.id
    this._type = config.type
    this._caption = config.caption
    this._details = config.details
    this._count = config.count
    this._selected = config.selected
    this._delta = config.delta
    this._imageUrl = config.imageUrl
    this._imageAlt = config.caption
    this._text = config.text
    this._numerical = config.numerical
    this._selectionType = config.selectionType
    this._items = config.items
    this._hasChildren = config.opens !== null
    this._multiselect = config.multiselect ? true : false
  }

  get id() {
    return this._id
  }
  get type() {
    return this._type
  }
  get caption() {
    return this._caption
  }
  get details() {
    return this._details
  }
  get truncatedCaption() {
    if (!this._caption) return this._caption
    if (this._caption.slice(-1) !== ")") return this._caption
    if (this._caption.length <= maxLength) return this._caption
    const ending = this._caption.slice(this._caption.lastIndexOf("("))
    return (
      this._caption.slice(0, Math.max(0, maxLength - ending.length - 1)) +
      "..." +
      this._caption.slice(-1 * ending.length)
    )
  }
  get count() {
    return this._count
  }
  get selected() {
    return this._selected
  }
  get imageUrl() {
    return this._imageUrl
  }
  get imageAlt() {
    return this._imageAlt
  }
  get text() {
    return this._text
  }
  get numerical() {
    return this._numerical
  }
  get selectionType() {
    return this._selectionType
  }
  get items() {
    return this._items
  }
  get isCollection() {
    return this._selectionType === "collection"
  }
  getLinkUrl = (currentPosition = {}) => {
    return deltaUrlFromPosition(currentPosition, this._delta)
  }

  get hasDelta() {
    if (typeof this._delta === "undefined") return false
    if (this._delta === null) return false
    return true
  }

  get hasChildren() {
    return this._hasChildren
  }

  get isMultiselect() {
    return this._multiselect
  }
}
