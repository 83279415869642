import React from "react"
import classNames from "classnames"
import { MulticolorIndicator } from "./multicolorIndicator"
import * as styles from "./indicators.module.css"

export const indicatorPlacementOptions = {
  TOOLBOX: "toolbox",
  PRODUCT_CARD: "productCard",
  EXPANDED_CARD: "expandedCard",
  FILTER_PANEL: "filterPanel",
}

export const ColorIndicator = ({
  selection,
  indicatorPlacement = indicatorPlacementOptions.TOOLBOX,
}) => {
  var backgroundStyle = {
    width: "100%",
    height: "100%",
  }
  if (
    selection &&
    selection.button &&
    Object.keys(selection.button).length > 0
  ) {
    return <MulticolorIndicator selection={selection} />
  }

  if (Array.isArray(selection.colorCodes)) {
    if (selection.colorCodes.length === 1) {
      backgroundStyle.backgroundColor = selection.colorCodes[0]
    }
    if (selection.colorCodes.length > 1) {
      const numberOfColors = selection.colorCodes.length
      var backgroundString = "linear-gradient(90deg "
      selection.colorCodes.map((colorCode, colorCodeIndex) => {
        backgroundString =
          backgroundString +
          ", " +
          colorCode +
          " " +
          (100 / numberOfColors) * colorCodeIndex +
          "%"
        backgroundString =
          backgroundString +
          ", " +
          colorCode +
          " " +
          (100 / numberOfColors) * (colorCodeIndex + 1) +
          "%"
      })
      backgroundString = backgroundString + ")"
      backgroundStyle.background = backgroundString
    }
  } else if (selection.colorCode) {
    backgroundStyle.backgroundColor = selection.colorCode
  }

  return (
    <div
      className={classNames(
        styles.colorSelectionIndicator,
        styles[indicatorPlacement],
        { [styles.selected]: selection.isSelected }
      )}
    >
      <div
        className={classNames(
          styles.colorIndicator,
          styles[indicatorPlacement],
          { [styles.selected]: selection.isSelected }
        )}
      >
        {selection.imageUrl !== "#" ? (
          <img src={selection.imageUrl} alt={selection.caption} />
        ) : selection.colorCode !== null ? (
          <div style={backgroundStyle} />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}
