import { syntheticPanels, panelCaptions } from "../../../utils/constants"
import { getPanelKeys } from "./getPanelNames"
import { getOrderedPanels } from "./getOrderedPanels"
import { arrayExcludeStrings } from "../../../utils/arrayUtils"
import { FilterPanelObj } from "../../../utils/filterPanelObj"

const getSyntheticPanel = (data, targetName, panelsToMerge) => {
  if (!data || !targetName || !panelsToMerge) return {}
  if (!Array.isArray(panelsToMerge)) return {}
  let noError = true
  let result = {}
  if (data[targetName]) {
    result[targetName] = { ...data[targetName] }
  } else {
    result = {
      [targetName]: {
        id: targetName,
        name: targetName,
        caption: panelCaptions[targetName],
      },
    }
  }
  panelsToMerge.map(panelKey => {
    if (!data[panelKey]) noError = false
    result[targetName][panelKey] = data[panelKey]
    return false
  })
  return noError ? { ...result } : {}
}

export const getSyntheticPanels = data => {
  if (!data) return {}
  let result = {}
  const targetNames = Object.keys(syntheticPanels)
  targetNames.map(targetName => {
    result = {
      ...result,
      ...getSyntheticPanel(data, targetName, syntheticPanels[targetName]),
    }
  })
  return { ...result }
}

const getAllSubPanelKeys = () => {
  const syntheticPanelKeys = Object.keys(syntheticPanels)
  return syntheticPanelKeys.flatMap(
    syntheticPanelKey => syntheticPanels[syntheticPanelKey]
  )
}

const getSubpanelParentKey = subPanelKey => {
  let result
  const syntheticPanelKeys = Object.keys(syntheticPanels)
  syntheticPanelKeys.map(syntheticPanelKey => {
    if (syntheticPanels[syntheticPanelKey].indexOf(subPanelKey) > -1)
      result = syntheticPanelKey
    return false
  })
  return result
}

export const getPositionWithoutSubPanels = (position, syntheticPanelKey) => {
  if (!Array.isArray(syntheticPanels[syntheticPanelKey])) return position
  let newPosition = {}
  const positionKeys = Object.keys(position)
  positionKeys.map(positionKey => {
    if (syntheticPanels[syntheticPanelKey].indexOf(positionKey) < 0) {
      newPosition[positionKey] = position[positionKey]
    }
  })
  return newPosition
}

export const getAllPanelData = data => {
  if (!data) return {}
  let result = getSyntheticPanels(data)
  const panelKeysToRemove = getAllSubPanelKeys()
  const allKeys = Object.keys(data)
  allKeys.map(key => {
    if (panelKeysToRemove.indexOf(key) < 0) {
      if (result[key]) {
        result[key] = { ...data[key], ...result[key] }
      } else {
        result[key] = data[key]
      }
    }
    return false
  })
  return result
}

const replaceSubpanelsWithSyntheticKeys = data => {
  const subPanelKeys = getAllSubPanelKeys()
  let result = []
  data.map(key => {
    if (subPanelKeys.indexOf(key) > -1) {
      const syntheticKey = getSubpanelParentKey(key)
      if (result.indexOf(syntheticKey) < 0) result.push(syntheticKey)
    } else {
      result.push(key)
    }
    return false
  })
  return result
}

export const parseData = (data, coords) => {
  const panelKeysFromCoords = coords != null ? Object.keys(coords) : []
  const panelData = getOrderedPanels(getAllPanelData(data))
  const allPanelNames = getPanelKeys(panelData)
  const activePanelNames =
    replaceSubpanelsWithSyntheticKeys(panelKeysFromCoords)

  return [
    panelData,
    allPanelNames,
    activePanelNames,
    arrayExcludeStrings(allPanelNames, activePanelNames),
  ]
}

export const isSyntheticPanel = panelKey => {
  const syntheticPanelKeys = Object.keys(syntheticPanels)
  return (
    Array.isArray(syntheticPanelKeys) &&
    syntheticPanelKeys.indexOf(panelKey) > -1 &&
    syntheticPanels[panelKey] &&
    Array.isArray(syntheticPanels[panelKey])
  )
}

export const getSubPanelKeys = panelKey => {
  if (isSyntheticPanel(panelKey)) return syntheticPanels[panelKey]
  return []
}

export const getSubPanels = (data, panelKey, createPanelData) => {
  let result
  if (data && data[panelKey] && isSyntheticPanel(panelKey)) {
    result = {}
    getSubPanelKeys(panelKey).map(subPanelKey => {
      if (data[panelKey][subPanelKey]) {
        result[subPanelKey] = new FilterPanelObj(
          createPanelData({ ...data, ...data[panelKey] }, subPanelKey)
        )
      }
      return false
    })
  }
  return result
}
