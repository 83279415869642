import React, { forwardRef, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import PropTypes from "prop-types"
import useNavigator from "../hooks/useNavigator"
import useVersioning from "../hooks/useVersioning"
import useDialog from "../hooks/useDialog"
import useLocale from "../hooks/useLocale"
import {
  ariaRoles,
  CloseDialogButton,
  CloseMobileDialogButton,
  UploadButton,
  MoreOptionsPanel,
  PhotoUploadPanel,
  DialogTitle,
  dialogNames,
  nonPositionParams,
  getPathnameString,
  removeParamFromSearch,
  labels,
  locationFromPosition,
  Icon,
  icons,
  handleFiles,
  postPhotoSearchRequest,
  StartProcessingButton,
  locationToPosition,
} from "./index"

import * as styles from "./dialog.module.css"
import { cNoPadding, cDoubleFooter } from "./dialog.module.css"

export const Dialog = forwardRef(({ data }, ref) => {
  const { locationToRender: location } = useNavigator()
  const position = locationToPosition(location)
  const { deploymentVersion } = useVersioning()
  const { openDialog: dialogToDisplay } = useDialog()

  const locale = useLocale()
  const dispatch = useDispatch()

  var closeUrl = "/"
  var content = null
  var mainButton = null
  var deemphaziseCloseButton = false

  const [title, setTitle] = useState("")
  const [newPosition, setNewPosition] = useState(position)
  const [uploadedImage, _setUploadedImage] = useState()
  const [mobilePreview, setMobilePreview] = useState(false)

  const processingRef = useRef(false)

  const setUploadedImage = data => {
    // if (data) _setUploadedImage(data.split("base64,")[1])
    if (data) _setUploadedImage(data)
    else _setUploadedImage(null)
  }

  const handleStartProcessingClick = () => {
    if (uploadedImage && uploadedImage.split("base64,").length === 2) {
      const url = "upload_image"
      const body = {
        image_b64: uploadedImage.split("base64,")[1],
      }
      processingRef.current = true
      dispatch(postPhotoSearchRequest({ url: url, body: body }))
      setUploadedImage(null)
    }
  }

  switch (dialogToDisplay) {
    case dialogNames.MORE_OPTIONS:
      closeUrl = removeParamFromSearch(location, nonPositionParams.PRODUCT)
      content = (
        <MoreOptionsPanel
          location={location}
          position={position}
          setTitle={newTitle => {
            setTitle(newTitle)
          }}
          heapView={data}
          deploymentVersion={deploymentVersion}
        />
      )
      break
    case dialogNames.UPLOADS:
      deemphaziseCloseButton = true
      closeUrl = getPathnameString(location)
      if (uploadedImage) {
        mainButton = (
          <StartProcessingButton handleClick={handleStartProcessingClick} />
        )
      } else if (mobilePreview) {
        mainButton = (
          <CloseMobileDialogButton
            to={getPathnameString(locationFromPosition(newPosition, location))}
            label={labels[locale].UPLOAD_APPLY_FILTERS}
            dialog
          />
        )
      } else {
        mainButton = (
          <UploadButton
            footerButton={true}
            important={true}
            handleFileUpload={files => {
              handleFiles(files, setUploadedImage)
            }}
          >
            Start New Search
          </UploadButton>
        )
      }
      content = (
        <PhotoUploadPanel
          location={location}
          position={position}
          uploads={data}
          setTitle={newTitle => {
            setTitle(newTitle)
          }}
          setNewPosition={setNewPosition}
          mobilePreview={mobilePreview}
          setMobilePreview={setMobilePreview}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          handleStartProcessingClick={handleStartProcessingClick}
          processing={processingRef.current}
        />
      )
      break
    default:
      break
  }

  return (
    <div
      data-testid="dialog"
      role={ariaRoles.DIALOG}
      className={styles.dialogOverlay}
    >
      <div
        ref={ref}
        className={classNames(styles.dialog, { [cNoPadding]: mobilePreview })}
      >
        <div className={styles.mobileDialogHead}>
          {dialogToDisplay === dialogNames.UPLOADS && mobilePreview ? (
            <div
              className={styles.mobileHeaderButton}
              onClick={() => {
                setMobilePreview(false)
              }}
            >
              <Icon icon={icons.back} />
            </div>
          ) : (
            <div style={{ display: "none" }} />
          )}
          <h3>{title}</h3>
        </div>
        <div className={styles.dialogHead}>
          <DialogTitle>{title}</DialogTitle>
          <CloseDialogButton to={closeUrl} />
        </div>
        <div
          className={classNames(styles.dialogBody, {
            [cNoPadding]: mobilePreview,
          })}
        >
          {content}
        </div>
        <div
          className={classNames(styles.mobileDialogFooter, {
            [cDoubleFooter]: dialogToDisplay === dialogNames.UPLOADS,
          })}
        >
          {mainButton}
          <CloseMobileDialogButton
            deemphasized={deemphaziseCloseButton}
            to={closeUrl}
            dialog
          />
        </div>
      </div>
    </div>
  )
})

Dialog.propTypes = {
  location: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  dialogToDisplay: PropTypes.string.isRequired,
}
