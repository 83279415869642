import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Icon, icons, iconsColors } from "./index"

import * as styles from "./filterOption.module.css"

export const FilterOption = ({
  filterId,
  filterType,
  filterValue,
  isSelected,
  inSelector,
  greenSwitch,
  isCategoryFilter,
}) => {
  const filterClassName = inSelector
    ? classNames(styles.filterSelector, {
        [styles[filterId]]: !!styles[filterId],
      })
    : classNames(styles.filterIndicator)

  const createOptionPreview = (type, value, id, isSelected) => {
    switch (type) {
      case "addFilter":
        return (
          <Icon
            icon={icons.filter}
            color={greenSwitch ? iconsColors.GREEN : iconsColors.WHITE}
            size={36}
          />
        )
      case "color":
        return (
          <div
            className={classNames({
              [styles.optionColor]: !!filterId,
              [styles.whiteBorder]: isSelected,
            })}
            style={
              value !== "Any"
                ? { backgroundColor: value }
                : {
                    background:
                      "repeating-linear-gradient(  45deg,  #fff,  #fff 5px,  #eee 5px,  #eee 7px)",
                  }
            }
          />
        )
      case "image":
        return (
          <img
            src={value}
            alt={id}
            className={classNames(styles.optionImage, {
              [styles.optionImageInSelector]: inSelector && !isSelected,
              [styles.selectedCategoryOptionImage]:
                isCategoryFilter && isSelected,
            })}
          />
        )
      case "text":
        return <p className={styles.optionText}>{value}</p>
      default:
        return null
    }
  }

  return (
    <div
      className={classNames(filterClassName, styles[filterType], {
        [styles.selected]: isSelected && !isCategoryFilter,
      })}
    >
      {createOptionPreview(filterType, filterValue, filterId, isSelected)}

      <div
        className={classNames(styles.categorySelectionTitle, {
          [styles.showCategoryTitle]: isCategoryFilter,
        })}
      ></div>
    </div>
  )
}

FilterOption.propTypes = {
  filterId: PropTypes.string,
  filterType: PropTypes.string,
  filterValue: PropTypes.string,
  isSelected: PropTypes.bool,
  inSelector: PropTypes.bool,
  greenSwitch: PropTypes.bool,
  isCategoryFilter: PropTypes.bool,
}

FilterOption.defaultProps = {
  isSelected: false,
  inSelector: false,
  greenSwitch: false,
  isCategoryFilter: false,
}
