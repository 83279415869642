import React from "react"
import PropTypes from "prop-types"
import { SafeLink } from "../index"
import { safeLinkActions, panelKeys } from "../../utils/constants"

import * as styles from "./togglePanelButton.module.css"

export const TogglePanelButton = ({ children, panelId, pathname }) => (
  <SafeLink
    to={pathname}
    className={styles.filterWrapper}
    action={
      panelId === panelKeys.ADD_FILTER
        ? safeLinkActions.TOGGLE_ADD_FILTER
        : safeLinkActions.TOGGLE_PANEL
    }
    actionPayload={{ panelId }}
  >
    {children}
  </SafeLink>
)

TogglePanelButton.propTypes = {
  children: PropTypes.node,
  panelId: PropTypes.string,
  pathname: PropTypes.string,
}
