import { getFiltribute } from "./getFiltribute"
import { addFilterSelectionTypes } from "../../../utils/constants"
import { FiltributeObj } from "../../../utils/filtributeObj"

export const getCollections = data => {
  let collectionIds = []
  let collections = []
  if (!(data && data.items)) return collections
  data.items.map(item => {
    if (item.sub_panels) {
      item.sub_panels.map(subPanel => {
        let collectionIndex = collectionIds.indexOf(subPanel.id)
        if (collectionIndex < 0) {
          collectionIds.push(subPanel.id)
          collections.push({
            id: subPanel.id,
            caption: subPanel.caption,
            details: subPanel.name,
            selectionType: addFilterSelectionTypes.COLLECTION,
            items: [
              new FiltributeObj(
                getFiltribute({
                  ...item,
                  idx: subPanel.idx,
                  selectionType: addFilterSelectionTypes.SELECTION,
                })
              ),
            ],
          })
        } else {
          collections[collectionIndex].items.push(
            new FiltributeObj(
              getFiltribute({
                ...item,
                idx: subPanel.idx,
                selectionType: addFilterSelectionTypes.SELECTION,
              })
            )
          )
        }
        return false
      })
    }
    return false
  })
  return collections
}
