import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { ChooseValueButton, List, ListItem, TextInput } from "../index.js"

import { sortArray } from "../../utils/helpers"

import * as selectorStyles from "./selector.module.css"

export const BrandSelector = ({ position, filterPanelObj }) => {
  const [selectedIds, setSelectedIds] = useState(
    filterPanelObj.filtributes.filter(filtribute => filtribute.selected)
  )
  const [textFilterValue, setTextFilterValue] = useState("")

  const filteredFiltributes = filterPanelObj.filtributes.filter(filtribute => {
    if (filtribute.text) {
      return filtribute.text
        .toLowerCase()
        .includes(textFilterValue.toLowerCase())
    }
    return false
  })

  const handleClick = index => {
    const newSelectedId = filteredFiltributes[index].id
    var newSelectedIds = JSON.parse(JSON.stringify(selectedIds))
    const indexInSelectedIs = selectedIds.findIndex(
      selectedId => selectedId === newSelectedId
    )
    if (indexInSelectedIs > -1) newSelectedIds.splice(indexInSelectedIs, 1)
    else newSelectedIds.push(newSelectedId)
    setSelectedIds(newSelectedIds)
  }

  var activeSelectionList = filteredFiltributes.map((selection, index) => {
    if (selectedIds.indexOf(selection.id) > -1) {
      return (
        <div
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleClick(index)
          }}
        >
          <ListItem isSelected={true} caption={selection.text}></ListItem>
        </div>
      )
    } else return null
  })
  activeSelectionList = activeSelectionList.filter(item => item !== null)

  var selectionList = filteredFiltributes.map((selection, index) => {
    if (selectedIds.indexOf(selection.id) === -1) {
      return (
        <div
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleClick(index)
          }}
        >
          <ListItem isSelected={false} caption={selection.text}></ListItem>
        </div>
      )
    } else return null
  })
  selectionList = selectionList.filter(item => item !== null)

  return (
    <>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <TextInput value={textFilterValue} onChange={setTextFilterValue} />
      </div>
      {activeSelectionList.length > 0 ? (
        <List>{activeSelectionList}</List>
      ) : (
        <div style={{ display: "none" }} />
      )}
      <List>
        {selectionList.length > 0 ? (
          selectionList
        ) : (
          <div style={{ display: "none" }} />
        )}
      </List>
    </>
  )

  return <div></div>
}

BrandSelector.propTypes = {
  position: PropTypes.object.isRequired,
  filterPanelObj: PropTypes.object.isRequired,
}
