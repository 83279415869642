import React from "react"

export const SoleIcon = props => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <circle cx={24} cy={24} r={15} fill="#7DB5C7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2 21.544c.608-1.114 1.068-1.877 1.068-1.877s.07-2.33.88-3.182c.809-.85 3.07-2.576 4.957-2.321 1.064.143 1.976 1.815 2.825 3.37.656 1.203 1.274 2.337 1.895 2.64.411.2 1.416.01 2.754-.245 3.065-.582 7.875-1.496 11.28 1.209.649.16 1.186.42 1.578.797.977 1.199 1.115 1.938 1.132 2.172.947 2.291-2.253 5.567-6.36 7.88-3.083 1.735-6.917 2.046-10.883 2.003-.794-.008-1.472-.13-2.11-.244-1.503-.27-2.777-.498-4.785.898A14.954 14.954 0 0 1 9 24c0-.836.068-1.657.2-2.456Z"
        fill="#7595AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.415 13.371c.565-.189 1.143-.281 1.69-.207 1.064.143 1.976 1.815 2.825 3.37.656 1.203 1.274 2.337 1.895 2.64.411.2 1.416.01 2.754-.245 3.296-.626 8.61-1.636 12.024 1.883C38.3 24.623 18.737 28.31 9.686 28.5A14.994 14.994 0 0 1 9 24c0-4.153 1.688-7.912 4.415-10.629Z"
        fill="#648296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.082 25.576c2.045.295 3.574-.329 5.935-1.291 1.196-.488 2.604-1.062 4.4-1.648 5.652-1.843 13.602-4.032 16.02-1.702 1.202 1.474 1.134 2.253 1.134 2.253L9.845 28.975c-.38-1.082-.64-2.22-.763-3.4Z"
        fill="#FDB046"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62 28.28c1.634-.263 2.361-.646 3.448-1.218.809-.426 1.815-.956 3.541-1.618 6.07-2.33 15.519-5.153 18.108-3.992 4.526 2.03.754 6.572-4.508 9.534-3.083 1.736-6.917 2.047-10.883 2.004-.794-.008-1.472-.13-2.11-.244-1.49-.268-2.755-.495-4.735.863a14.965 14.965 0 0 1-2.862-5.33Z"
        fill="#DE8036"
      />
    </svg>
  )
}
