// extracted by mini-css-extract-plugin
export var bodyContainer = "landing-module--bodyContainer--C-AqN";
export var discoverContainer = "landing-module--discoverContainer--wewTb";
export var footerContainer = "landing-module--footerContainer--zLJlX";
export var gasefiLanding = "landing-module--gasefiLanding--W5FcI";
export var groupLabel = "landing-module--groupLabel--L92ja";
export var headContainer = "landing-module--headContainer--vTMrS";
export var hidden = "landing-module--hidden--JFtwx";
export var hiddenOnMobile = "landing-module--hiddenOnMobile--2E3xI";
export var landingLogotype = "landing-module--landingLogotype--LlxMJ";
export var logoContainer = "landing-module--logoContainer--f7jJV";
export var logotypeContainer = "landing-module--logotypeContainer--yOkOX";
export var openOnMobile = "landing-module--openOnMobile--kHIMH";
export var searchButtonContainer = "landing-module--searchButtonContainer--l8oaz";
export var searchContainer = "landing-module--searchContainer--wPKB0";
export var searchOptions = "landing-module--searchOptions--r2pXc";
export var sloganContainer = "landing-module--sloganContainer--YArPv";