import { useSelector } from "react-redux"

const useOrigin = () => {
  return useSelector(store =>
    store.history && store.history.state && store.history.state.origin
      ? store.history.state.origin
      : undefined
  )
}

export default useOrigin
