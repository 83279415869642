import React from "react"
export const HeelIcon = props => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <circle cx={24} cy={24} r={15} fill="#7DB5C7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.31 28.511c.448-1.424.69-2.94.69-4.511 0-8.284-6.716-15-15-15-.517 0-1.027.026-1.53.077.506 4.15-.96 7.11-2.162 9.533-.928 1.873-1.697 3.425-1.272 4.958-.497.508-.619.835-.619.835l-1.357 5.81a.411.411 0 0 0-.033.088.786.786 0 0 0-.021.146l-.006.024.005-.001c-.034 1.326 3.228 2.913 8.429 2.848 5.424-.068 9.593-1.288 9.593-1.288v-3.39l3.283-.129Zm-3.283 3.519-5.516-3.486 5.516 3.486Z"
        fill="#7595AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.618 17.703c-6.555 3.28-17.84 8.393-19.32 5.53-.907-1.755-.052-3.479 1.01-5.623 1.09-2.2 2.4-4.841 2.253-8.413C22.355 9.067 23.17 9 24 9c6.035 0 11.238 3.565 13.618 8.703Z"
        fill="#648296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.875 25.948C38.957 25.311 39 24.66 39 24c0-3-.88-5.794-2.397-8.137l-11.322 3.2c-6.949 1.899-7.864 4.34-7.864 4.34L16 29.47l22.875-3.523Z"
        fill="#FDB046"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.599 27.46C38.86 26.35 39 25.191 39 24c0-2.295-.516-4.47-1.437-6.415l-12.01 3.275 8.474 6.78 4.572-.18Zm-22.572 1.841c.331-1.356 9.525-3.627 9.525-3.627l8.475 5.356s-4.17 1.22-9.593 1.288c-5.424.068-8.738-1.66-8.407-3.017Z"
        fill="#DE8036"
      />
      <path
        d="m25.552 20.86 8.475 6.78v3.39l-8.475-5.356V20.86Z"
        fill="#FF9846"
      />
    </svg>
  )
}
