import React from "react"

export const HeelHeightIcon = props => {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <circle cx={24} cy={24} fill="#7DB5C7" r={15} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.911 36.067c-.227-.374-.466-.974-.63-1.932-.314-1.842-2.286-4.028-4.167-5.352l-.118 4.02-7.355 1.795s-3.88-.579-5.328-.926c-.567-.136-.903-.414-.81-.71l.694-13.711.065-.09c-.447-2.955-.341-5.261.44-7.658A14.93 14.93 0 0 1 24 9c8.284 0 15 6.716 15 15 0 4.947-2.395 9.335-6.089 12.067Z"
        fill="#7595AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.65 32.064 15.37 18.83c-.476-2.754-.51-4.938-.013-7.091A14.931 14.931 0 0 1 24 9c8.284 0 15 6.716 15 15a14.93 14.93 0 0 1-2.35 8.064Z"
        fill="#648296"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m37.726 30.06-4.17 5.044s-.869-.173-1.274-2.548c-.314-1.836-2.276-4.015-4.15-5.34l-.136 4.587-13.494.174.695-13.726c.927-1.332 2.317-1.564 4.749-1.564 2.432 0 5.618 1.564 8.398 3.301 4.156 3.223 6.453 5.35 9.724 9.228-.106.285-.22.566-.342.843Z"
        fill="#FDB046"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.457 35.644c-.239-.125-.853-.623-1.175-2.509-.315-1.842-2.287-4.028-4.168-5.352l.212-7.18c4.129 3.199 6.385 5.234 9.588 9.01a15.04 15.04 0 0 1-4.457 6.03Zm-14.67-4.71c4.866-.116 9.21.869 9.21.869l-7.356 1.795s-3.88-.579-5.328-.926c-1.448-.348-1.39-1.622 3.475-1.738Z"
        fill="#DE8036"
      />
      <path
        d="m20.5 16.8 2.165 3H21v8h1.665l-2.165 3-2.165-3H20v-8h-1.665l2.165-3Z"
        fill="#DE8036"
      />
    </svg>
  )
}