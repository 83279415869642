import React from "react"
import PropTypes from "prop-types"
import { Icon, icons, SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"

export const BackOneLevelButton = ({ pathname }) => {
  return (
    <SafeLink to={pathname} action={safeLinkActions.BACK_ONE_LEVEL}>
      <Icon icon={icons.back} />
    </SafeLink>
  )
}

BackOneLevelButton.propTypes = {
  pathname: PropTypes.string,
}
