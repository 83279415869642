import { storeReducers, storeReducerVersions, panelKeys } from "./constants"
// import { deserializePosition } from "./positionUtils"
// import { initialState as historyInitialState } from "../store/history/historyReducer"

export const isLatestHeapViewVersion = version => {
  if (typeof version === "undefined") return false
  if (version === null) return false
  const isLatest =
    version === storeReducerVersions[storeReducers.HEAP_VIEW_BY_POSITION]
  return isLatest
}

export const doDeploymentVersionsMatch = (currentVersion, requiredVersion) => {
  if (typeof currentVersion === "undefined") return false
  if (currentVersion === null) return false
  if (typeof requiredVersion === "undefined") return true
  if (requiredVersion === null) return true
  return currentVersion === requiredVersion
}

export const isLatestFilterPanelVersion = version => {
  if (typeof version === "undefined") return false
  if (version === null) return false
  return (
    version === storeReducerVersions[storeReducers.FILTER_PANELS_BY_POSITION]
  )
}

export const isTimestampFresh = timestamp => {
  const second = 1000
  const minute = 60 * second
  const hour = 60 * minute
  return Date.now() - timestamp < 24 * hour
}

export const isFresh = state => {
  if (state === null || typeof state === "undefined") return false
  return isTimestampFresh(state.timestamp)
}

export const hasErrors = state => {
  if (state === null || typeof state === "undefined") return false
  if (typeof state.error === "undefined") return false
  return state.error !== null
}

export const hasNoData = state => {
  if (state === null || typeof state === "undefined") return true
  if (state.data === null || typeof state.data === "undefined") return true
  var count = 0
  Object.keys(state.data).map(key => {
    if (!key.startsWith("_")) count = count + 1
    return false
  })
  return count === 0
}

export const hasNoShapePanel = state => {
  if (state === null || typeof state === "undefined") return true
  if (state.data === null || typeof state.data === "undefined") return true
  if (typeof state.data[panelKeys.SHAPE_FILTER] === "undefined") return true
  return state.data[panelKeys.SHAPE_FILTER] === null
}

export const shouldFetchFilterPanels = (
  state,
  deploymentVersion,
  captionsVersion
) => {
  if (typeof state === "undefined") {
    return true
  }
  if (!isFresh(state)) {
    return true
  }
  if (hasErrors(state)) {
    return true
  }
  if (hasNoData(state)) {
    return true
  }
  if (hasNoShapePanel(state)) {
    return true
  }
  if (!isLatestFilterPanelVersion(state.version)) {
    return true
  }
  if (!doDeploymentVersionsMatch(state.deploymentVersion, deploymentVersion)) {
    return true
  }
  if (!doDeploymentVersionsMatch(state.captionsVersion, captionsVersion)) {
    return true
  }
  return false
}

export const shouldFetchHeapView = (
  stateByPosition,
  deploymentVersion,
  position
) => {
  if (typeof stateByPosition === "undefined") {
    return true
  }
  if (!Array.isArray(position.shape)) {
    return false
  }
  if (!isLatestHeapViewVersion(stateByPosition.version)) {
    return true
  }
  if (!isFresh(stateByPosition)) {
    return true
  }
  if (
    !doDeploymentVersionsMatch(
      stateByPosition.deploymentVersion,
      deploymentVersion
    )
  ) {
    return true
  }
  return false
}

export const shouldRefetchHeapView = (stateByPosition, grouping, sorting) => {
  if (typeof stateByPosition[grouping] === "undefined") {
    return true
  }
  if (typeof stateByPosition[grouping][sorting] === "undefined") {
    return true
  }
  if (!isFresh(stateByPosition[grouping][sorting])) {
    return true
  }
  return false
}

const maxAgeToKeep = 1000 * 3600 * 24

const getMaxAge = (items, itemsToKeep) => {
  const currentTimestamp = Date.now()
  var ages = Object.keys(items).map(
    coord =>
      currentTimestamp - (items[coord].timestamp ? items[coord].timestamp : 0)
  )
  var maxAge =
    itemsToKeep < ages.length
      ? ages.sort((a, b) => {
          return a > b ? 1 : -1
        })[itemsToKeep - 1]
      : ages.sort((a, b) => {
          return a > b ? 1 : -1
        })[ages.length - 1]
  return Math.min(maxAge, maxAgeToKeep)
}

export const removeOldItems = (
  state,
  resultsToKeep = 3,
  filterPanelsToKeep = 25
) => {
  const currentTimestamp = Date.now()
  var newState = JSON.parse(JSON.stringify(state))

  newState[storeReducers.HEAP_VIEW_BY_POSITION] = {}
  var maxAge = getMaxAge(
    state[storeReducers.HEAP_VIEW_BY_POSITION],
    resultsToKeep
  )
  Object.keys(state[storeReducers.HEAP_VIEW_BY_POSITION]).map(coord => {
    const age =
      currentTimestamp -
      (state[storeReducers.HEAP_VIEW_BY_POSITION][coord].timestamp
        ? state[storeReducers.HEAP_VIEW_BY_POSITION][coord].timestamp
        : 0)
    if (age < maxAge)
      newState[storeReducers.HEAP_VIEW_BY_POSITION][coord] = JSON.parse(
        JSON.stringify(state[storeReducers.HEAP_VIEW_BY_POSITION][coord])
      )
    return false
  })

  newState[storeReducers.FILTER_PANELS_BY_POSITION] = {}
  maxAge = getMaxAge(
    state[storeReducers.FILTER_PANELS_BY_POSITION],
    filterPanelsToKeep
  )
  Object.keys(state[storeReducers.FILTER_PANELS_BY_POSITION]).map(coord => {
    const age =
      currentTimestamp -
      (state[storeReducers.FILTER_PANELS_BY_POSITION][coord].timestamp
        ? state[storeReducers.FILTER_PANELS_BY_POSITION][coord].timestamp
        : 0)
    if (age < maxAge)
      newState[storeReducers.FILTER_PANELS_BY_POSITION][coord] = JSON.parse(
        JSON.stringify(state[storeReducers.FILTER_PANELS_BY_POSITION][coord])
      )
    return false
  })
  return newState
}

export const getValidState = state => {
  /*
  const VERSION = 'version'
  const newState = JSON.parse(JSON.stringify(state))
  Object.keys(storeReducers).map(reducerKey => {
    if (typeof(storeReducerVersions[storeReducers[reducerKey]]) !== "undefined" && (
      typeof(newState[storeReducers[reducerKey]][VERSION]) === "undefined" ||
      newState[storeReducers[reducerKey]][VERSION] !== storeReducerVersions[storeReducers[reducerKey]]
    )) {
      newState[storeReducers[reducerKey]] = {}
      newState[storeReducers[reducerKey]][VERSION] = storeReducerVersions[storeReducers[reducerKey]]
      if (storeReducers[reducerKey] === storeReducers.HISTORY) {
        newState[storeReducers[reducerKey]] = historyInitialState
      }
    }
    if (storeReducers[reducerKey] === storeReducers.FILTER_PANELS_BY_POSITION) {
      const positions = Object.keys(newState[storeReducers[reducerKey]])
      const index = positions.indexOf(VERSION)
      if (index > -1) positions.splice(index, 1)
      positions.map(serializedPosition => {
        const position = deserializePosition(serializedPosition)
        if (Object.keys(position).length === 1 && Object.keys(position)[0] === "") {
          delete newState[storeReducers[reducerKey]][serializedPosition] 
        }
        else if (shouldFetchFilterPanels(newState[storeReducers[reducerKey]][serializedPosition])) delete newState[storeReducers[reducerKey]][serializedPosition]
        return false
      })
    }
    return false
    
  })
  return newState
  */
  return state
}
