import React from "react"

import { FilterIcon, SafeButton, presentations } from "../index"

export const AddPanelButton = ({ panelId, value, to }) => {
  return (
    <SafeButton
      to={to}
      presentation={presentations.ADD_PANEL}
      payload={{ panelId: panelId }}
    >
      <FilterIcon panelId={panelId} />
      <div>{value}</div>
    </SafeButton>
  )
}
