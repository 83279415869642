import { useRef, useEffect, useContext } from "react"
import { FilterObj } from "../utils"
import { useSelector, useDispatch } from "react-redux"
import { locationToPosition } from "../utils"
import { serializePosition } from "../utils/positionUtils"
import { syntheticPanels } from "../utils/constants"
import { fetchFilterPanelsRequest } from "../store"
import { shouldFetchFilterPanels } from "../utils"
import { panelKeys } from "../utils/constants"
import useVersioning from "./useVersioning"
import { StaticDataContext } from "../templates"

const useFilterPanels = ({ location }) => {
  const { captionsVersion, filterPanelVersion, deploymentVersion } =
    useVersioning()
  const staticData = useContext(StaticDataContext)
  const oldFilterPanelsRef = useRef() // Show old FilterPanels while loading new to avoid flickering
  const dispatch = useDispatch()
  const position = locationToPosition(location)
  const serializedPosition = serializePosition(position)
  const filterPanelsByPosition = useSelector(store =>
    store.filterPanelsByPosition &&
    store.filterPanelsByPosition[serializedPosition]
      ? store.filterPanelsByPosition[serializedPosition]
      : undefined
  )
  const openPanel = useSelector(store =>
    store.history && store.history.state && store.history.state.openPanel
      ? store.history.state.openPanel
      : null
  )

  let filterPanels
  if (filterPanelsByPosition && filterPanelsByPosition.data != null) {
    filterPanels = new FilterObj(filterPanelsByPosition.data)
    oldFilterPanelsRef.current = filterPanels
  } else {
    if (oldFilterPanelsRef.current) {
      filterPanels = oldFilterPanelsRef.current
      filterPanels.loading = true
    } else if (staticData && staticData.filterPanels) {
      filterPanels = new FilterObj(staticData.filterPanels)
      if (filterPanelsByPosition) {
        filterPanels.loading = filterPanelsByPosition.isLoading
      }
    }
  }

  const getEstimateFor = (position, openPanel) => {
    if (position == null) return []
    if (Object.keys(position).length < 1) return []
    var estimateCountFor = [Object.keys(position)[0]]
    var estimatePanelsFor = [Object.keys(position)[0]]
    if (openPanel) {
      const syntheticPanelKeys = Object.keys(syntheticPanels)
      if (syntheticPanelKeys.indexOf(openPanel) > -1) {
        if (openPanel === "color") {
          estimateCountFor = [...syntheticPanels[openPanel], openPanel]
          estimatePanelsFor = [...syntheticPanels[openPanel], openPanel]
        } else {
          estimateCountFor = syntheticPanels[openPanel]
          estimatePanelsFor = syntheticPanels[openPanel]
        }
      } else {
        estimateCountFor = [openPanel]
        estimatePanelsFor = [openPanel]
      }
    }
    return [estimatePanelsFor, estimateCountFor]
  }

  const estimateVisible = () => {
    if (openPanel) {
      if (
        filterPanelsByPosition &&
        filterPanelsByPosition.data &&
        filterPanelsByPosition.data[openPanel] &&
        Object.keys(filterPanelsByPosition.data[openPanel]).length > 0
      ) {
        if (
          !shouldFetchFilterPanels(
            filterPanelsByPosition,
            filterPanelVersion,
            captionsVersion
          )
        ) {
          return false
        }
      }
    } else {
      if (
        !shouldFetchFilterPanels(
          filterPanelsByPosition,
          filterPanelVersion,
          captionsVersion
        )
      ) {
        return false
      }
    }
    const [estimatePanelsFor, estimateCountFor] = getEstimateFor(
      position,
      openPanel
    )
    if (!estimatePanelsFor) return false
    const url = "filipa/tools/filter_panels/estimate_visible"
    const body = {
      coord: position,
      captions: captionsVersion,
      filter_panels: filterPanelVersion,
      locale: "en_GB.UTF-8",
      deployment_id: deploymentVersion,
      estimate_count_for: estimateCountFor,
      estimate_panels_for: estimatePanelsFor,
    }
    dispatch(
      fetchFilterPanelsRequest({
        url: url,
        body: body,
        position: position,
        deploymentVersion: filterPanelVersion,
        captionsVersion: captionsVersion,
      })
    )
  }

  useEffect(() => {
    estimateVisible()
  }, [serializedPosition])

  useEffect(() => {
    if (openPanel && openPanel !== panelKeys.ADD_FILTER) {
      estimateVisible()
    }
  }, [openPanel])

  useEffect(() => {
    if (parseInt(deploymentVersion) >= 0) {
      estimateVisible()
    }
  }, [deploymentVersion])

  return {
    filterPanels,
    filterPanelsByPosition,
    loadingFilterPanels: filterPanels.loading,
  }
}

export default useFilterPanels
