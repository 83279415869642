import { formatProductCount } from "../../../utils"

export const getParent = (data, panelKey) => {
  let result = {
    caption: "",
    delta: { [panelKey]: [] },
  }
  if (
    panelKey === "color" &&
    data &&
    data[panelKey] &&
    data[panelKey].current_choice &&
    data[panelKey].current_choice.caption === "Multicolor"
  ) {
    result.caption = "Return to Color"
  }
  if (data && data[panelKey] && data[panelKey].parent != null) {
    const parent = data[panelKey].parent
    result.caption = parent.caption ? parent.caption : parent.opens_fp
    if (parent.count) {
      result.caption =
        result.caption + " (" + formatProductCount(parent.count) + ")"
    }
    result.delta = parent.delta
    if (result.caption === "fp.shoe.color") result.caption = "Return to Color"
  }
  return result
}
