import { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getProductIdFromLocation } from "../../../utils/positionUtils"
import { fetchProductDetailsRequest } from "../../../store"
import { refetchHeapViewRequest } from "../../../store"
import useIsTouchable from "../../../hooks/useIsTouchable"

const useMoreOptionsPanel = ({
  location,
  position,
  heapView,
  setTitle,
  deploymentVersion,
}) => {
  const isTouchScreen = useIsTouchable()
  const currentProductId = getProductIdFromLocation(location)
  const productIdRef = useRef()
  const product = heapView.getProduct(currentProductId)
  const productDetails = useSelector(
    store => store.productDetails[currentProductId]
  )
  const detailsLoadingRef = useRef()
  const previousProduct = heapView.getPreviousProduct(currentProductId)
  const nextProduct = heapView.getNextProduct(currentProductId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (productDetails == null) {
      const url = `filipa/deployments/${deploymentVersion}/products/${currentProductId}`
      dispatch(
        fetchProductDetailsRequest({ url: url, productId: currentProductId })
      )
      detailsLoadingRef.current = true
    }
    /*
    if (!heapView.loadingMore && heapView.shouldLoadMore(currentProductId)) {

      dispatch(
        refetchHeapViewRequest({
          position: position,
          pageIndex: heapView.pageIndexToFetch,
          grouping: heapView.grouping,
          sorting: heapView.sorting,
        })
      )
    }
    */
  }, [currentProductId])

  useEffect(() => {
    if (product) {
      if (
        !productIdRef ||
        productIdRef.current === "undefined" ||
        productIdRef.current !== currentProductId
      ) {
        productIdRef.current = currentProductId
        setTitle(product.brand + " " + product.name)
      }
    }
  }, [product])

  /*
  useEffect(() => {
    if (product) {
      if (
        !productIdRef ||
        productIdRef.current === "undefined" ||
        productIdRef.current !== currentProductId
      ) {
        setDetailsAdded(false)
        productIdRef.current = currentProductId
        setTitle(product.brand + " " + product.name)
        const url = `filipa/deployments/${deploymentVersion}/products/${currentProductId}`
        dispatch(
          fetchProductDetailsRequest({ url: url, productId: currentProductId })
        )
        if (
          !heapView.loadingMore &&
          heapView.shouldLoadMore(currentProductId)
        ) {
          dispatch(
            refetchHeapViewRequest({
              position: position,
              pageIndex: heapView.pageIndexToFetch,
              grouping: heapView.grouping,
              sorting: heapView.sorting,
            })
          )
        }
      }
      if (productDetails) {
        product.addDetails(productDetails, setDetailsAdded(true))
      }
    }
  }, [product, productDetails])
*/

  var productArray = [product]
  if (previousProduct) productArray = [previousProduct].concat(productArray)
  if (nextProduct) productArray = productArray.concat(nextProduct)

  if (product != null && productDetails != null) {
    detailsLoadingRef.current = false
    product.addDetails(productDetails)
  }

  return {
    product,
    nextProduct,
    previousProduct,
    productArray: productArray || [],
    detailsLoading: productDetails ? productDetails.loading : true,
    isTouchScreen,
  }
}

export default useMoreOptionsPanel
