import React, { useState } from "react"
import classNames from "classnames"

import * as styles from "./filterStack.module.css"

export const FilterStack = ({ stackSize, isSelected }) => {
  return (
    <>
      {stackSize === 2 && (
        <div
          className={classNames(styles.doubleStackItem, {
            [styles.selected]: isSelected,
          })}
        />
      )}
      {stackSize === 3 && (
        <>
          <div
            className={classNames(styles.trippleStackItem, {
              [styles.selected]: isSelected,
            })}
          />
          <div
            className={classNames(styles.trippleStackItem, {
              [styles.selected]: isSelected,
            })}
          />
        </>
      )}
      {stackSize > 3 && (
        <>
          <div
            className={classNames(styles.multiStackItem, {
              [styles.selected]: isSelected,
            })}
          />
          <div
            className={classNames(styles.multiStackItem, {
              [styles.selected]: isSelected,
            })}
          />
          <div
            className={classNames(styles.multiStackItem, {
              [styles.selected]: isSelected,
            })}
          />
        </>
      )}
    </>
  )
}
