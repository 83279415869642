import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"
import { connect } from "react-redux"
import { defaultLocale, labels } from "../../utils/labelConstants"

import * as styles from "./moreOptionsButton.module.css"
import * as buttonStyles from "../../styles/buttonStyles.module.css"
import * as safeLinkStyles from "../safeLink.module.css"

const MoreOptionsButton = ({ pathname, hoverButton = false, locale }) => (
  <SafeLink
    to={pathname}
    className={classNames(
      { [styles.moreOptions]: !hoverButton },
      { [styles.moreOptionsHover]: hoverButton },
      { [safeLinkStyles.noUnderline]: hoverButton }
    )}
    action={safeLinkActions.MORE_OPTIONS}
  >
    {hoverButton ? (
      <div className={buttonStyles.roundedFloating}>
        {labels[locale].MORE_OPTIONS}
      </div>
    ) : (
      labels[locale].MORE_OPTIONS
    )}
  </SafeLink>
)

const mapStateToProps = store => ({
  locale: store.history.locale ? store.history.locale : defaultLocale,
})

export const MoreOptionsButtonRedux =
  connect(mapStateToProps)(MoreOptionsButton)

MoreOptionsButton.propTypes = {
  pathname: PropTypes.string,
}
