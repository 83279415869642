import React from "react"

function SizeIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <path d="M11.5 12.5 16 9l20 27-4 3-20.5-26.5Z" fill="#DE8036" />
      <path
        d="M40.27 33.683 18.718 6l-8.191 6.387 21.573 27.667 8.172-6.371Zm-4.928-1.901a1.188 1.188 0 1 1 1.462 1.872 1.188 1.188 0 0 1-1.462-1.872ZM12.838 12.577l1.96-1.528.745.956-1.96 1.528-.745-.956Zm1.975 2.534 2.714-2.116.746.956-2.714 2.116-.746-.956Zm1.895 2.43 1.96-1.528.746.956-1.96 1.528-.746-.956Zm1.976 2.534 2.714-2.116.744.954-2.714 2.116-.744-.954Zm1.827 2.343 1.96-1.529.744.954-1.96 1.529-.744-.954Zm1.975 2.533 2.714-2.116.744.955-2.714 2.116-.744-.955Zm1.895 2.43 1.96-1.528.746.956-1.96 1.529-.746-.957Zm1.976 2.534L29.07 27.8l.746.957-2.714 2.116-.745-.957Zm1.895 2.43 1.96-1.527.746.956-1.96 1.528-.746-.956Z"
        fill="#FACC55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m17.77 6.74-7.244 5.647 8.122 10.417c.402-.628.755-1.292 1.053-1.988l-.273.213-.744-.954 1.718-1.34c.138-.552.243-1.119.313-1.698A13.737 13.737 0 0 0 17.77 6.739Zm-2.972 4.309-1.96 1.528.745.956 1.96-1.528-.745-.956Zm2.729 1.946-2.714 2.116.746.956 2.714-2.116-.746-.956Zm1.141 3.018-1.96 1.528.746.956 1.96-1.528-.746-.956ZM35.213 27.187l5.058 6.496-4.62 3.601a13.715 13.715 0 0 1-1.088-7.26c.12-.986.34-1.934.65-2.837Zm.773 4.346a1.188 1.188 0 1 0 .174 2.37 1.188 1.188 0 0 0-.174-2.37Z"
        fill="#FDB046"
      />
      <path
        d="m7 14.598 1.605 5.097 1.09-.857 15.823 20.136-1.09.857L29 42.596 27.395 37.5l-1.09.857L10.482 18.22l1.09-.857L7 14.598Z"
        fill="#DE8036"
      />
    </svg>
  )
}

export { SizeIcon }
