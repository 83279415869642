import { getPanelData } from "./getPanelData"

const hasSwitchDelta = (data, panelKey) => {
  const panelData = getPanelData(data, panelKey)
  if (
    data &&
    panelKey === "color" &&
    data[panelKey] &&
    data[panelKey].current_choice &&
    data[panelKey].current_choice.caption === "Multicolor"
  ) {
    return false
  }
  if (
    panelData &&
    panelData.switch &&
    panelData.switch.delta &&
    panelData.switch.delta[panelKey]
  ) {
    return true
  }
  return false
}

export const getMultichoiceSwitch = (data, panelKey) => {
  return hasSwitchDelta(data, panelKey)
}

export const getMultiselect = (data, panelKey) => {
  const panelData = getPanelData(data, panelKey)
  if (hasSwitchDelta(data, panelKey)) {
    return !Array.isArray(
      panelData.switch.delta[panelKey][
        panelData.switch.delta[panelKey].length - 1
      ]
    )
  }
}

export const getSwitchDelta = (data, panelKey) => {
  let result
  if (hasSwitchDelta(data, panelKey)) {
    result = data[panelKey].switch.delta[panelKey]
  }
  return result
}
