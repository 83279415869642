import React from "react"
import PropTypes from "prop-types"

import { HeapOpenButton, labels } from "../index"

import * as styles from "./heap.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

export const Heap = ({ heap, position, locale }) => {
  let heapSet = heap.heapSet.slice(0).map((card, index) => {
    if (heap.images && heap.images[index]) card.image = heap.images[index]
    return card
  })

  return (
    <div className={styles.heapWrapper}>
      <HeapOpenButton pathname={heap.getLinkUrl(position)}>
        <div className={styles.heap}>
          <div className={styles.heapPreview}>
            {heapSet
              .slice(0)
              .reverse()
              .map((card, cardIndex) => (
                <div
                  key={cardIndex}
                  className={styles.heapCard}
                  style={{
                    top: card.top + "px",
                    left: card.left + "px",
                    transform: "rotate(" + card.angle + "deg)",
                  }}
                >
                  {card.image ? (
                    <img src={card.image} alt={heap.caption} />
                  ) : null}
                </div>
              ))}
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.heapIcon}>
              <img src={heap.icon} alt={heap.caption} />
            </div>
            <div>
              <h4 className={labelStyles.heapCaption}>{heap.caption}</h4>
              <p className={labelStyles.heapCount}>
                {"(" + heap.count + " " + labels[locale].PRODUCTS + ")"}
              </p>
            </div>
          </div>
        </div>
      </HeapOpenButton>
    </div>
  )
}

Heap.propTypes = {
  heap: PropTypes.object,
}
