import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { SafeLink } from "../index"
import { safeLinkActions } from "../../utils/constants"
import { useMobile } from "../../hooks"

import * as safeLinkStyles from "../safeLink.module.css"
import * as buttonStyles from "../../styles/buttonStyles.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

export const CategoryUpButton = ({ parent, position }) => {
  const isMobile = useMobile()

  return (
    <SafeLink
      to={parent.getLinkUrl(parent.getNewPosition(position))}
      action={safeLinkActions.CHOOSE_VALUE}
      className={safeLinkStyles.noUnderline}
    >
      <div
        className={classNames(buttonStyles.categoryUp, {
          [buttonStyles.mobile]: isMobile,
        })}
      >
        <h4 className={classNames(labelStyles.categoryUp)}>{parent.caption}</h4>
      </div>
    </SafeLink>
  )
}

CategoryUpButton.propTypes = {
  parent: PropTypes.object,
  position: PropTypes.object,
}
