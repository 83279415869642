import React from "react"
import classNames from "classnames"

import {
  cImage,
  cContained,
  cCovered,
  cHasBackground,
  cImgBackground,
} from "./basicComponent.module.css"

export const Image = ({ src, alt, cover, contain, blurredBackground }) => {
  return (
    <div className={cImage}>
      {blurredBackground && (
        <div
          className={cImgBackground}
          style={{ backgroundImage: `url(${src})` }}
        />
      )}
      <img
        className={classNames(
          { [cContained]: contain },
          { [cCovered]: cover },
          { [cHasBackground]: blurredBackground }
        )}
        src={src}
        alt={alt}
      />
    </div>
  )
}

/*
  <div 
    className={styles.imageBackground}
    style={{ backgroundImage: `url(${items[selectedItem].productImage})`}}
  />
  <img src={items[selectedItem].productImage} />
*/
