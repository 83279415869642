import React from "react"

import * as styles from "./basicComponent.module.css"

export const List = ({ children, ...rest }) => {
  return (
    <ul data-testid="list-component" className={styles.list} {...rest}>
      {children}
    </ul>
  )
}
