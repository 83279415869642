import React from "react"

function ColorIcon(props) {
  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      filter="drop-shadow(1px 0 1px rgb(0 0 0 / 0.2))"
      {...props}
    >
      <path
        d="M24 8C15.164 8 8 15.164 8 24s7.164 16 16 16a2.663 2.663 0 0 0 2.667-2.667c0-.693-.267-1.315-.694-1.795a2.67 2.67 0 0 1-.675-1.76 2.663 2.663 0 0 1 2.666-2.667h3.147c4.907 0 8.889-3.982 8.889-8.889C40 14.364 32.836 8 24 8Zm-9.778 16a2.663 2.663 0 0 1-2.666-2.667 2.663 2.663 0 0 1 2.666-2.666 2.663 2.663 0 0 1 2.667 2.666A2.663 2.663 0 0 1 14.222 24Zm5.334-7.111a2.663 2.663 0 0 1-2.667-2.667 2.663 2.663 0 0 1 2.667-2.666 2.663 2.663 0 0 1 2.666 2.666 2.663 2.663 0 0 1-2.666 2.667Zm8.888 0a2.663 2.663 0 0 1-2.666-2.667 2.663 2.663 0 0 1 2.666-2.666 2.663 2.663 0 0 1 2.667 2.666 2.663 2.663 0 0 1-2.667 2.667ZM33.778 24a2.663 2.663 0 0 1-2.667-2.667 2.663 2.663 0 0 1 2.667-2.666 2.663 2.663 0 0 1 2.666 2.666A2.663 2.663 0 0 1 33.778 24Z"
        fill="#01ABFF"
      />
      <path
        d="M39.836 20.18a7.263 7.263 0 0 0-1.05-.65c-1.307-.66-2.756-.898-4.267-.76a2.664 2.664 0 0 1 1.925 2.563A2.663 2.663 0 0 1 33.778 24a2.663 2.663 0 0 1-2.667-2.667c0-.808.358-1.531.923-2.02-4.27 1.454-8.683 5.545-11.58 11.276-1.384 2.735-2.262 5.527-2.654 8.165A15.947 15.947 0 0 0 24 40a2.663 2.663 0 0 0 2.667-2.667c0-.693-.267-1.315-.694-1.795a2.67 2.67 0 0 1-.675-1.76 2.663 2.663 0 0 1 2.666-2.667h3.147c4.907 0 8.889-3.982 8.889-8.889 0-.694-.056-1.376-.164-2.043Z"
        fill="#0083FF"
      />
      <path
        d="M20.517 22.603c6.77-4.924 14.559-6.196 19.092-3.519.092.36.167.726.227 1.095a7.263 7.263 0 0 0-1.05-.648c-1.307-.661-2.756-.899-4.267-.76a2.679 2.679 0 0 0-2.485.543c-4.27 1.453-8.683 5.544-11.58 11.275-1.384 2.735-2.262 5.527-2.654 8.165a16.048 16.048 0 0 1-6.162-4.597c1.21-3.993 4.33-8.246 8.879-11.554Z"
        fill="#01C3FF"
      />
    </svg>
  )
}

export { ColorIcon }
