import React from "react"
import { SelectingValueIcon } from "../../index"

export const PriceIndicator = ({ selection }) => {
  let firstPrice = null
  let secondPrice = null
  let moreLess = "-"
  const priceArray = selection.caption.split(" ")
  if (selection && selection.caption && selection.caption.includes("-")) {
    firstPrice = priceArray[0]
    secondPrice = priceArray[priceArray.length - 1]
  } else {
    if (parseInt(priceArray[0].slice(1))) {
      moreLess = priceArray.slice(1).join(" ")
      firstPrice = priceArray[0]
    }
    if (parseInt(priceArray[priceArray.length - 1].slice(1))) {
      moreLess = priceArray.slice(0, -1).join(" ")
      secondPrice = priceArray[priceArray.length - 1]
    }
  }

  if (firstPrice !== null && secondPrice !== null)
    return (
      <div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "var(--clr-grey-d1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            color: "#f6f6f6",
            fontSize: "12px",
            fontWeight: "400",
          }}
        >
          {firstPrice}
        </div>
        <div
          style={{
            color: "#f6f6f6",
            fontSize: "12px",
            lineHeight: "4px",
          }}
        >
          {moreLess}
        </div>
        <div
          style={{
            color: "#f6f6f6",
            fontSize: "12px",
            fontWeight: "400",
          }}
        >
          {secondPrice}
        </div>
      </div>
    )

  if (secondPrice !== null) {
    return (
      <div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "var(--clr-grey-d1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            color: "#f6f6f6",
            fontSize: "10px",
            lineHeight: "8px",
          }}
        >
          {moreLess}
        </div>
        <div
          style={{
            color: "#f6f6f6",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: "400",
          }}
        >
          {secondPrice}
        </div>
      </div>
    )
  }

  if (firstPrice !== null) {
    return (
      <div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "24px",
          backgroundColor: "var(--clr-grey-d1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            color: "#f6f6f6",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: "400",
          }}
        >
          {firstPrice}
        </div>
        <div
          style={{
            color: "#f6f6f6",
            fontSize: "10px",
            lineHeight: "8px",
          }}
        >
          {moreLess}
        </div>
      </div>
    )
  }

  return <SelectingValueIcon />
}
